// Librairies
import React from 'react';
import { Redirect, Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import Button from '@material-ui/core/Button';
// Components
import auth from '../Auth';
import Page from '../Page';
import CustomInput from '../CustomInput';
import './Login.scss';

class Login extends Page {
	state = {
		mail: { value: '', errorMessage: '' },
		password: { value: '', errorMessage: '' },
		redirectToReferrer: false
	};

	handleChange = event => {
		this.setState({ [event.target.name]: event.target.value });
	};

	handleSubmit = event => {
		event.preventDefault();
		if (!event.target.checkValidity()) {
			// form is invalid! so we do nothing
			return;
		}
		auth.authenticate(this.state.mail, this.state.password, () => {
			this.setState({ redirectToReferrer: true });
		});
	};

	handleInvalid = event => {
		this.setState({
			[event.target.name]: {
				errorMessage: event.target.validationMessage,
				value: event.target.value
			}
		});
	};

	render() {
		const { t } = this.props;
		let { from } = this.props.location.state || { from: { pathname: '/' } };

		if (this.state.redirectToReferrer) return <Redirect to={from} />;
		return (
			<div className="container login">
				<form onSubmit={this.handleSubmit} noValidate>
					<CustomInput
						required
						id="mail"
						label={t('login.mail')}
						placeholder={t('login.mail-placeholder')}
						type="email"
						onChange={this.handleChange}
						onInvalid={this.handleInvalid}
						state={this.state.mail}
						dataparse="lowerCase"
						autoComplete="username"
						center
					/>
					<CustomInput
						required
						id="password"
						label={t('login.password')}
						type="password"
						onChange={this.handleChange}
						onInvalid={this.handleInvalid}
						state={this.state.password}
						autoComplete="current-password"
						center
					/>
					<div className="row justify-content-center">
						<div className="col-auto mt-3">
							<Button variant="contained" color="secondary" type="submit">
								{t('menu.login')}
							</Button>
						</div>
						<div className="col-auto mt-3">
							<Button
								component={Link}
								to="/inscription"
								variant="contained"
								color="primary"
							>
								{t('login.creer-compte')}
							</Button>
						</div>
					</div>
				</form>
			</div>
		);
	}
}

export default withTranslation()(Login);
