// Libraries
import React from 'react';
import { withTranslation } from 'react-i18next';
import Page from '../Page';
import ModuleMaisonAccueil from '../ModuleMaisonAccueil/ModuleMaisonAccueil';
import { CONTACTS } from './Commun';

class CentreAccueilMadagascar extends Page {
	constructor(props) {
		super(props);
	}

	componentDidMount() {
		super.componentDidMount();
	}

	contenu() {
		return (
			<>
				<p className="mt-4 text-justify">
					Depuis 2009, le CEP (Centre d’Ecoute et de Partage) a ouvert ses
					portes au cœur de Tananarive dans le quartier de Talatamaty en
					collaboration avec l’association Timothée Accueil et Jeunesse et
					l’association RETIM.
				</p>
				<p className="mb-5 text-justify">
					Ce lieu a pour vocation l’accueil et l’assistance de personnes en
					difficulté morale et matérielle dans une perspective chrétienne. S’y
					retrouvent le plus souvent des jeunes, mais également des personnes
					qui éprouvent le besoin de partager et d’être écoutées.
				</p>
				<h3 className="contenu-h3">Réinsertion</h3>
				<p className="mt-4 mb-5 text-justify">
					L’équipe du CEP veille également à apporter une aide en vue de la
					réinsertion. Des projets sont établis avec l’association RETIM afin de
					mettre en place une activité économique susceptible de subvenir aux
					besoins élémentaires des familles. Des actions diverses de solidarité
					sont mises en œuvre : il peut s’agir d’un soutien financier pour des
					jeunes désirant poursuivre des études, ou encore de travaux visant à
					améliorer les conditions de vie précaires, voire d’un soutien apporté
					à une activité artisanale notamment par :
				</p>
				<ul className="liste">
					<li className="">
						La création, au CEP, d’un atelier de dessin permettant aux jeunes de
						vendre leurs œuvres
					</li>
					<li className="">
						La collaboration avec des artisans de Tananarive permettant la vente
						de leurs fabrications et des épices malgaches en France.
					</li>
				</ul>
			</>
		);
	}
	render() {
		const { t } = this.props;

		return (
			<>
				<ModuleMaisonAccueil
					titre="Maison d'accueil"
					sousTitre="Le Cep"
					contact={CONTACTS[4]}
					idGallerie="1426"
					contenu={this.contenu()}
				/>
			</>
		);
	}
}

export default withTranslation()(CentreAccueilMadagascar);
