import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import auth, { LogoutButton } from '../Auth';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import { withTranslation } from 'react-i18next';
import { useTranslation } from 'react-i18next';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
// import iconLock from '../../resources/icons/lock.png';

function Connexion() {
	const { t, i18n } = useTranslation();
	// Déclaration d'une nouvelle variable d'état, que l'on appellera “count”
	const [count, setCount] = useState(0);

	return (
		<div className="ml-md-auto col-auto">
			{auth.isAuthenticated() ? (
				<span className="connected">
					<AccountCircleIcon style={{ fontSize: '28px' }} className="m-1" />
					<span>
						{auth.getUser().prenom} {auth.getUser().nom}
					</span>
					<LogoutButton
						component={ExitToAppIcon}
						className="cursor-pointer m-1"
						style={{ fontSize: '28px' }}
					/>
				</span>
			) : (
				<span className="auth">
					{/* <Link to={'login'}>
						<img src={iconLock} alt="" className="mr-2" />
						<span>{t('menu.login')}</span>
					</Link> */}
				</span>
			)}
		</div>
	);
}
export default withTranslation()(Connexion);
