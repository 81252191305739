import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import LanguageDetector from 'i18next-browser-languagedetector';

import translationEN from './resources/locales/en/translations.json';
import translationFR from './resources/locales/fr/translations.json';
import translationKR from './resources/locales/kr/translations.json';
import translationES from './resources/locales/es/translations.json';
import translationBG from './resources/locales/bg/translations.json';
import translationDE from './resources/locales/de/translations.json';

const resources = {
	en: {
		translation: translationEN
	},
	fr: {
		translation: translationFR
	},
	kr: {
		translation: translationKR
	},
	es: {
		translation: translationES
	},
	bg: {
		translation: translationBG
	},
	de: {
		translation: translationDE
	}
};

i18n
	// detect user language
	// learn more: https://github.com/i18next/i18next-browser-languageDetector
	.use(LanguageDetector)
	// pass the i18n instance to react-i18next.
	.use(initReactI18next)
	// init i18next
	// for all options read: https://www.i18next.com/overview/configuration-options
	.init({
		resources,
		// fallbackLng: ['en', 'fr'],
		fallbackLng: 'fr',
		debug: false, // TODO For debug put true

		interpolation: {
			escapeValue: false // not needed for react as it escapes by default
		}
	});
export default i18n;
