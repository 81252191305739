import React, { Component } from 'react';

export class InputTextAccompagnant extends Component {
	componentDidMount() {
		const val = this.props.initVal;
		if (val !== undefined) {
			// Si une valeur existe déjà au chargement de l'objet on vérifie si les données entrées sont correctes
			this.check(val);
		}
	}

	state = {
		isValid: false,
		messageInvalid: 'Vous devez remplir ce champ',
		messageValid: 'Cela semble bon!'
	};

	handleChange = event => {
		this.check(event.target.value);
	};

	check = val => {
		val = val.trimStart();
		const mask = this.props.mask || '';
		let type = this.props.type;
		let isValid = false;
		if (mask == 'uppercase') {
			val = val.toUpperCase();
		}
		if (type == 'text' && mask != 'tel') {
			if (val.length > 1) {
				isValid = true;
				this.props.self.setState({ [this.props.nom + 'Valid']: true });
			} else {
				this.props.self.setState({ [this.props.nom + 'Valid']: false });
			}
		}

		if (type == 'date') {
			if (val.length > 7) {
				isValid = true;
				this.props.self.setState({ [this.props.nom + 'Valid']: true });
			} else {
				this.props.self.setState({ [this.props.nom + 'Valid']: false });
			}
		}
		this.props.self.setState(
			{
				[this.props.nomJson]: val,
				[this.props.nomJson + 'Valid']: isValid
			},
			() => {
				this.props.self.updateDatas();
			}
		);
	};

	isValidForClass = () => {
		if (this.props.required) {
			if (this.props.self.state[this.props.nom + 'Valid']) {
				return 'is-valid';
			} else {
				return 'is-invalid';
			}
		} else {
			return '';
		}
	};

	render() {
		return (
			<div className={'form-group col-md-' + this.props.numCol}>
				{this.props.self.props.numAccompagnant === 1 && (
					<label
						htmlFor={
							'prenomAccompagnant' + this.props.self.props.numAccompagnant
						}
						className="col-form-label pl-3 pr-3 mb-2 text-white bg-green text-uppercase"
					>
						{this.props.label}
					</label>
				)}
				<input
					type={this.props.type || 'text'}
					className={'form-control ' + this.isValidForClass()}
					id={'id' + this.props.nom}
					placeholder={
						(this.props.placeholder || this.props.label) +
						(this.props.required ? '*' : '')
					}
					onChange={this.handleChange}
					required={this.props.required || false}
					value={this.props.self.state[this.props.nomJson] || ''}
					min={this.props.min || ''}
					max={this.props.max || ''}
					onKeyPress={e => {
						if (window.event.keyCode === 13) {
							// Si on appuie sur la touche entrée on ignore
							e.preventDefault();
						}
					}}
				/>
				{!this.props.self[this.props.nom + 'Valid'] &&
					(this.props.required || false) && (
						<div className="invalid-feedback">{this.state.messageInvalid}</div>
					)}
			</div>
		);
	}
}
