import React from 'react';
import { withTranslation } from 'react-i18next';
import Page from '../Page';
import ModuleMaisonAccueil from '../ModuleMaisonAccueil/ModuleMaisonAccueil';
import { CONTACTS } from './Commun';

class Orphelinat extends Page {
	contenu() {
		return (
			<>
				<p className=" ">
					L’association AAEG (Aide Aux Enfants de Guinée) a été créée en 2005,
					par le pasteur Daniel Tolno, dans le but de venir en aide et
					d’annoncer l’Evangile aux enfants délaissés des rues de son pays, la
					Guinée Conakry. Ce travail commence dès 1994 pendant ses années
					d’études universitaires. C’est en prenant une partie de sa bourse
					universitaire qu’il soutient financièrement quelques enfants pour
					leurs frais de scolarité. Il en accueille aussi plusieurs au sein de
					sa famille.
				</p>
				<p className="">
					A la Faculté de théologie Libre d’Aix-en-Provence, il rencontre un
					étudiant qui lui propose de soutenir son projet. Le travail prenant de
					l’ampleur, la structure de l’AAEG est officialisée en 2005 et recevra
					la reconnaissance de l’Etat Guinéen. Lors d’un séjour en France,
					Daniel Tolno rencontre les responsables de l’association Timothée
					Accueil & Jeunesse. De la vocation et de l’orientation communes aux
					deux associations, naîtra une collaboration. En mars 2014, constatant
					la souffrance et l’ampleur des besoins – notamment à la suite de la
					fièvre hémorragique Ebola – Timothée Accueil & Jeunesse décide de
					soutenir le travail de l’AAEG en faisant appel au Réseau d’Entraide
					Timothée (RETIM). RETIM prendra en charge les frais de scolarité de 23
					enfants guinéens, inscrits dans des écoles privées chrétiennes. Bien
					que les frais de scolarité soient plus élevés que ceux des écoles
					publiques, l’enseignement y est toutefois de meilleure qualité. En
					2017, un orphelinat a vu le jour grâce au soutien du réseau de RETIM,
					partenaire de Timothée Accueil & Jeunesse. Une trentaine d’orphelins y
					sont accueillis. Une école permet d’enseigner les petites classes, les
					plus grands vont au collège le plus proche.
				</p>

				<h3 className="mt-5 contenu-h3">Accueil des enfants</h3>
				<p className="mb-5 mt-4">
					Ici, les enfants accueillis sont logés, nourris et soignés quand ils
					sont malades. En soirée, des veillées permettent de rassembler les
					enfants avec le personnel encadrant autour d’un moment de chants et
					une lecture de la Bible.
					<br />
					La Bible dit :<br />
					<div className="text-center">
						<i>
							« Oh ! Qu’il est agréable, qu’il est doux pour des frères de
							demeurer ensemble ! » (Ps 133)
						</i>
					</div>
				</p>
				<h3 className="contenu-h3">École Esaïe 58</h3>
				<p className="mt-4">
					Depuis la rentrée 2017, nous accueillons et scolarisons des enfants
					soutenus par l’AAEG. Une partie d’entre eux ont intégré les classes de
					primaire sur place, deux instituteurs à plein temps se sont répartis
					les différents niveaux.
				</p>
				<p className="mb-5">
					Les plus grands sont inscrits à une école privée située à 12km du
					centre d’accueil. Pour faire face aux besoins de salles de classes et
					de dortoirs, un agrandissement des locaux est entrepris dès la fin de
					la première année scolaire, de manière à mieux répartir les espaces de
					vie.
				</p>
				<h3 className="contenu-h3">Objectifs du centre Esaïe 58</h3>
				<ul className="liste mt-4 mb-5">
					<li className="liste">
						Accueillir les enfants et jeunes adultes en situation difficile
						(orphelins, enfants en conflit avec la loi).
					</li>
					<li className="liste">
						Accueillir des personnes en situation de détresse morale, physique
						ou matérielle.
					</li>
					<li className="liste">
						Répondre aux besoins vitaux de ces personnes laissées pour compte et
						préparer leur réinsertion sociale (alphabétisation, insertion
						socio-professionnelle).
					</li>
					<li className="liste">Offrir un cadre de vie stable et paisible.</li>
					<li>
						Tout ceci a pour but de faire connaître l’amour de Dieu et d’obéir à
						ce qui est écrit dans la Bible : « Renvoie libres ceux qu’on écrase
						(...), partage ton pain avec celui qui a faim et ramène à la maison
						les pauvres sans abri (...), ne te détourne pas de celui qui est ta
						(propre) chair. » (Es 58)
					</li>
				</ul>
				<h3 className="mt-4 contenu-h3">Projet "Greniers de Guinée"</h3>
				<p className="mt-4">
					Le but de ce projet est de trouver la nourriture nécessaire pour les
					enfants accueillis et de conduire le Centre vers une autosuffisance
					alimentaire. Nous envisageons d’étendre ce projet à l’apiculture et
					l’élevage de volailles. Depuis l’ouverture du centre, nous sommes
					parvenus à cultiver entre 2 et 5 hectares de riz et les récoltes se
					montrent encore prometteuses.
				</p>
				<p>
					<div className="mb-5 mt-5">
						<h3 className="contenu-h3 text-left">Daniel & Léontine Tolno</h3>
					</div>
				</p>
			</>
		);
	}

	render() {
		// const { t } = this.props;

		return (
			<>
				<ModuleMaisonAccueil
					titre="ORPHELINAT"
					sousTitre="Centre Esaïe 58"
					contact={CONTACTS[6]}
					idGallerie="1428"
					contenu={this.contenu()}
				/>
			</>
		);
	}
}

export default Orphelinat;
