import React from 'react';
import Page from '../Page';
import { ModuleBlocGaucheDroite, tools } from '@mitim/react-mitim';
import { DATA } from './Commun';

class PresentationAccueil extends Page {
	render() {
		return (
			<>
				<div className="container p-lg-5 p-2">
					<div className="text-center">
						<video
							controls
							autoplay="true"
							muted
							loop
							preload="autobuffer"
							style={{ marginLeft: 'auto', marginRight: 'auto', width: '100%' }}
						>
							<source
								src="https://www.dropbox.com/s/2z63jton8pdghuf/94e987e0f87d223afae8332e196d8ad559c5cde9.mp4?raw=1"
								type="video/mp4"
							/>
							Sorry, your browser doesn't support embedded videos.
						</video>
					</div>
					<div className="pl-3 pr-3 p-lg-0">
						{tools.titreSousTitre(
							'PRINCIPES DES MAISONS D’ACCUEIL',
							'Fonctionnement'
						)}
						<p>
							Ouvertes à tous, nos maisons accueillent à l’année toute personne
							en difficulté spirituelle, morale et matérielle qui en fait la
							demande, et pendant les vacances scolaires, des familles et des
							jeunes à l’occasion de camps bibliques. La vie sur place
							s’articule entre le temps des repas, des activités, des sorties ou
							encore des temps libres. Des réunions quotidiennes permettent de
							découvrir la Bible et l’expression de la foi par des cantiques
							d’assemblée. La liberté de conscience est respectée à chaque
							instant. Attentives aux besoins de chacun, nos équipes se rendent
							disponibles pour y répondre dans la mesure du possible.
						</p>
						<p>
							En dehors des périodes de camps, nous privilégions des durées de
							séjour courtes, adaptées aux besoins et régulièrement réévaluées,
							afin d’apporter l’aide nécessaire à une réinsertion
							socio-professionnelle rapide.
						</p>
						<p>
							Pour permettre au plus grand nombre de bénéficier de notre
							accueil, nous veillons à ce que la question financière ne soit pas
							un obstacle. Aucun tarif officiel n’est fixé, chacun participe aux
							frais de séjour selon ses moyens et selon un principe de liberté
							de conscience et de solidarité qui se trouve dans la Bible :{' '}
							<i>
								« il s’agit, non de vous exposer à la détresse pour soulager les
								autres, mais de suivre une règle d’égalité : dans la
								circonstance présente votre superflu pourvoira à leurs besoins,
								afin que leur superflu pourvoie pareillement aux vôtres, en
								sorte qu’il y ait égalité »
							</i>{' '}
							2 Corinthiens 8 :13
						</p>
					</div>
				</div>
				<div
					className="container-fluid no-gutters pt-0 pt-lg-5 bg-gris1"
					style={{
						minHeight: '80px',
						// backgroundColor: '#e9e9e9',
						paddingBottom: '70px'
					}}
				>
					{/* Anduze */}
					<ModuleBlocGaucheDroite data={DATA[0]} />
					<div className="pt-0 pt-lg-5"></div>
					{/* Le phare */}
					<ModuleBlocGaucheDroite data={DATA[3]} />

					{/* Riffray */}
					<ModuleBlocGaucheDroite data={DATA[2]} />

					<div className="pt-0 pt-lg-5"></div>
					{/* Fouday */}
					<ModuleBlocGaucheDroite data={DATA[1]} />
				</div>
				<div
					className="container-fluid no-gutters pt-0 pt-lg-5 bg-gris2"
					style={{
						minHeight: '80px',
						paddingBottom: '70px'
					}}
				>
					{/* Bulgarie */}
					<ModuleBlocGaucheDroite data={DATA[4]} />

					{/* Madagascar */}
					<ModuleBlocGaucheDroite data={DATA[5]} />

					{/* Guinee */}
					<ModuleBlocGaucheDroite data={DATA[6]} />
				</div>
			</>
		);
	}
}

export default PresentationAccueil;
