import iconeCarteFranceSud from '../../resources/icons/france_sud.svg';
import iconeCarteFranceEst from '../../resources/icons/france_est.svg';
import iconeCarteFranceOuest from '../../resources/icons/france_ouest.svg';
import iconeCarteBulgarie from '../../resources/icons/carte_bulgarie.svg';
import iconeCarteMada from '../../resources/icons/carte_mada.svg';
import iconeCarteGuinee from '../../resources/icons/carte_guinee.svg';

export const DATA = [
	{
		num: 0,
		textPosition: 'gauche',
		titreGris: 'Sud de la France',
		imgSrc: 'maison-anduze.jpg',
		iconSrc: iconeCarteFranceSud,
		titre: 'MISSION TIMOTHÉE',
		sousTitre: 'Anduze | Cévennes (30)',
		contenu:
			'La maison d’accueil d’Anduze, aux portes des Cévennes, est en fonctionnement tout au long de l’année. Sur place, une équipe dynamique reçoit pour des séjours d’une semaine renouvelable, ceux qui en expriment le besoin au travers d’une détresse morale, physique ou spirituelle.',
		boutons: [
			{
				nom: 'en savoir plus',
				lien: '/centre-accueil-anduze'
			}
		]
	},
	{
		num: 1,
		textPosition: 'droite',
		titreGris: 'Région Est',
		imgSrc: 'maison-fouday.jpg',
		iconSrc: iconeCarteFranceEst,
		titre: 'MAISON BERNARD',
		sousTitre: 'Fouday | Alsace (67)',
		contenu:
			'C’est dans un cadre verdoyant que la maison d’accueil de Fouday reçoit des personnes en difficulté ayant manifesté un besoin. Une jeune équipe accueille également, de manière ponctuelle, les amis de la région pour des camps bibliques.',
		boutons: [
			{
				nom: 'en savoir plus',
				lien: '/centre-accueil-fouday'
			}
		]
	},
	{
		num: 2,
		textPosition: 'gauche',
		titreGris: 'Région Ouest',
		imgSrc: 'maison-riffray.jpg',
		iconSrc: iconeCarteFranceOuest,
		titre: 'LE RIFFRAY',
		sousTitre: 'Chanteloup | Bretagne (35)',
		contenu:
			'Près des côtes bretonnes, dans un cadre paisible, le Riffray reçoit, sur le même modèle de fonctionnement que la maison d’ Anduze, des personnes en difficulté physique, morale ou spirituelle.',
		boutons: [
			{
				nom: 'en savoir plus',
				lien: '/centre-accueil-riffray'
			}
		]
	},
	{
		num: 3,
		textPosition: 'droite',
		titreGris: '',
		imgSrc: 'Pg-maison-acc-france-le-phare.jpg',
		iconSrc: iconeCarteFranceSud,
		titre: 'LE PHARE',
		sousTitre: 'Marseille (13)',
		contenu:
			'Fondé en 1946, ce foyer d’accueil situé au centre de Marseille, reçoit des étudiants, des missionnaires de passage mais aussi toute personne en difficulté sollicitant de l’aide. Un couple missionnaire en assure la direction dans une ambiance chaleureuse et familiale.',
		boutons: [
			{
				nom: 'en savoir plus',
				lien: '/centre-accueil-marseille'
			}
		]
	},
	{
		num: 4,
		textPosition: 'gauche',
		titreGris: 'Bulgarie',
		imgSrc: 'Pg-maison-acc-etranger-Bulgarie.jpg',
		iconSrc: iconeCarteBulgarie,
		titre: 'SLIVOVIC',
		sousTitre: 'Montana, Slivovic',
		contenu:
			'Située dans un petit village de campagne, la maison d’accueil de Slivovic permet de recevoir des personnes en situation de détresse. Des femmes sans ressources avec leurs enfants, des anciens détenus n’ayant plus de domicile, ni de relations sociales à leur sortie de prison.',
		boutons: [
			{
				nom: 'en savoir plus',
				lien: '/centre-accueil-bulgarie'
			}
		]
	},
	{
		num: 5,
		textPosition: 'droite',
		titreGris: 'Madagascar',
		imgSrc: 'Pg-maison-acc-etranger-mada.jpg',
		iconSrc: iconeCarteMada,
		titre: 'LE CEP',
		sousTitre: 'Antananarivo',
		contenu:
			"Dans le quartier de Talamaty, le Centre d'Écoute et de Partage (CEP) héberge quelques jeunes étudiants afin qu’ils poursuivent leur scolarité dans de bonnes conditions. Un couple malgache s’occupe de l’accueil et reçoit également les enfants du quartier tous les samedis.",
		boutons: [
			{
				nom: 'en savoir plus',
				lien: '/centre-accueil-madagascar'
			}
		]
	},
	{
		num: 6,
		textPosition: 'gauche',
		titreGris: 'Guinée Conakry',
		imgSrc: 'Pg-maison-acc-etranger-guinée.jpg',
		iconSrc: iconeCarteGuinee,
		titre: 'ESAÏE 58',
		sousTitre: 'Coyah',
		contenu:
			"À une cinquantaine de kilomètres de Conakry, le centre Esaïe 58 héberge une trentaine d’orphelins ainsi que des personnes en situation de détresse qui participent à la vie communautaire. L’équipe est constituée d’un couple pastoral, d'instituteurs et d'un couple d’agriculteurs.",
		boutons: [
			{
				nom: 'en savoir plus',
				lien: '/orphelinat'
			}
		]
	}
];
