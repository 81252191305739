import React from 'react';
import ReactDOM from 'react-dom';
import App from './components/App';

// import registerServiceWorker from './registerServiceWorker';
import { unregister as unregisterServiceWorker } from './registerServiceWorker';

import { I18nextProvider } from 'react-i18next';
import i18n from './i18n';

// import $ from 'jquery';
import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'font-awesome/css/font-awesome.min.css';

import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { MuiPickersUtilsProvider } from 'material-ui-pickers';
import MomentUtils from '@date-io/moment';
import moment from 'moment';
import 'moment/locale/fr';
moment.locale('fr'); //TODO Localizer les dates ?

const theme = createMuiTheme({
	shadows: Array(25).fill('none'),
	palette: {
		primary: { main: '#2d5467' },
		secondary: { main: '#009d96' }
	},
	typography: { useNextVariants: true }
});

ReactDOM.render(
	<I18nextProvider i18n={i18n}>
		<MuiThemeProvider theme={theme}>
			<MuiPickersUtilsProvider utils={MomentUtils} moment={moment}>
				<App />
			</MuiPickersUtilsProvider>
		</MuiThemeProvider>
	</I18nextProvider>,
	document.getElementById('root')
);
// registerServiceWorker();
unregisterServiceWorker();

// $(window).on('load', function() {
// 	$('.loader').fadeOut();
// 	$('.page-loader')
// 		.delay(350)
// 		.fadeOut('slow');
// });
