import React, { Component } from 'react';

export class Checkbox extends Component {
	componentDidMount() {}

	handleChange = event => {
		const test = this.props.self.handleChange(event);
		//this.check(event.target.value);
	};

	render() {
		return (
			<>
				<div className="form-check">
					<input
						onChange={this.handleChange}
						//value={this.props.self.state[this.props.nomJson] || ""}
						className="form-check-input"
						type="radio"
						name={this.props.nomGroupe}
						id={'id' + this.props.nom}
						//="programme"
						defaultChecked={this.props.defaultChecked}
						disabled={this.props.disabled || false}
					/>
					<label
						className="form-check-label text-white"
						htmlFor={'id' + this.props.nom}
					>
						{this.props.label}
						{(this.props.message || false) && (
							<span
								className="text-white pl-2 pr-2 ml-2"
								style={{ backgroundColor: 'rgb(218, 157, 76)' }}
							>
								<strong>{this.props.message}</strong>
							</span>
						)}
					</label>
				</div>
			</>
		);
	}
}
