import React from 'react';
import { withTranslation } from 'react-i18next';

import Menu from './Menu/Menu';

class MenuWrapper extends React.Component {
	render() {
		const { t } = this.props;
		return (
			<Menu
				items={[
					// { rank: 1, link: '/l-association', name: 'L\'ASSOCIATION' },
					{
						rank: 2,
						name: "maisons d'accueil",
						children: [
							{
								rank: 1,
								link: '/presentation-maisons-d-accueil',
								name: 'Présentation'
							},
							{ rank: 2, link: '/centre-accueil-anduze', name: 'Anduze (Sud)' },
							{
								rank: 3,
								link: '/centre-accueil-marseille',
								name: 'Marseille (Sud)'
							},
							{ rank: 4, link: '/centre-accueil-fouday', name: 'Fouday (Est)' },
							{
								rank: 5,
								link: '/centre-accueil-riffray',
								name: 'Riffray (Ouest)'
							},
							// { rank: 5, link: '/centre-accueil-guinee', name: 'Guinée Conakry' },
							{ rank: 6, link: '/centre-accueil-bulgarie', name: 'Bulgarie' },
							{
								rank: 7,
								link: '/centre-accueil-madagascar',
								name: 'Madagascar'
							},
							{
								rank: 8,
								link: '/orphelinat',
								name: 'Guinée Conakry'
							}
						]
					},
					{
						rank: 3,
						name: 'camps',
						children: [
							{
								rank: 1,
								link: '/principes-camps',
								name: 'Présentation et programme'
							},
							// {
							// 	rank: 2,
							// 	link: '/principes-camps/#programmeCamp',
							// 	name: 'Programmes'
							// },
							{
								rank: 3,
								link: '/inscription-camp/anduze',
								name: 'Inscriptions Anduze'
							},
							{
								rank: 4,
								link: '/inscription-camp/riffray',
								name: 'Inscriptions Riffray'
							},
							{
								rank: 5,
								link: '/inscription-camp/fouday',
								name: 'Inscriptions Fouday'
							}
						]
					},
					{
						rank: 4,
						name: 'colonies',
						children: [
							{
								rank: 1,
								link: '/principes-camps-ados-colos',
								name: 'Présentation et inscription'
							},
							// {
							// 	rank: 2,
							// 	link: '/programmes-camps-ados-colos',
							// 	name: 'Programmes'
							// },
							// {
							// 	rank: 3,
							// 	link: '/inscriptions-ados-colos',
							// 	name: 'Inscriptions'
							// },
							{
								rank: 4,
								link: '/inscription-ados-colos-equipe',
								name: "Faire partie de l'équipe"
							}
						]
					},
					// { rank: 4, link: '/contact', name: 'parole partagée' },
					{ rank: 5, link: '/handicap', name: 'handicap' },
					{ rank: 6, link: '/soutien-scolaire', name: 'soutien scolaire' },
					{ rank: 7, link: '/orphelinat', name: 'orphelinat' },
					{ rank: 8, link: '/contact', name: 'contact' }
					// {
					// 	rank: 5,
					// 	name: 'mission timothée',
					// 	link: 'https://www.missiontimothee.fr'
					// }
				]}
			/>
		);
	}
}

export default withTranslation()(MenuWrapper);
