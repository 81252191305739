import React from 'react';
import { withTranslation } from 'react-i18next';
import Page from '../Page';
import ModuleMaisonAccueil from '../ModuleMaisonAccueil/ModuleMaisonAccueil';
import { CONTACTS } from './Commun';

class CentreAccueilAnduze extends Page {
	constructor(props) {
		super(props);
	}

	componentDidMount() {
		super.componentDidMount();
	}

	contenu() {
		return (
			<>
				<p className="mt-4 text-justify">
					La maison d’Anduze a été créée en 1972. C’est de là que s’est lancé
					véritablement le travail d’accueil, autour de quelques familles. Un
					bail emphytéotique leur permet de s’installer sur une colline d’un
					hectare surplombant le Gardon, avec une vue imprenable sur la porte
					des Cévennes. L’endroit est idéal pour recevoir en toute quiétude des
					marginaux et des blessés du chemin, afin d'en prendre soin selon le
					principe de charité chrétienne. Les locaux s’agrandissent au fur et à
					mesure des besoins et des dons reçus.
				</p>
				{/* <h3 className="">LE FONCTIONNEMENT PENDANT ET HORS LES CAMPS</h3> */}
				<p className="mt-4 text-justify">
					Dès le départ, des camps sont aussi organisés pour les jeunes et les
					familles désirant se ressourcer dans une ambiance fraternelle. Peu à
					peu, toute une équipe de collaborateurs s’est constituée pour encadrer
					ce travail, chacun apportant les diverses compétences nécessaires.
					Aujourd’hui, une quinzaine de personnes sont salariées mais surtout,
					de nombreux bénévoles s’impliquent pour rendre cet accueil possible.
					Nous organisons dix camps dans l’année.
				</p>
				<p className="text-justify">
					Une collaboration de confiance s’est également établie depuis de
					longues années avec les services sociaux, les collectivités locales et
					les services de l’Etat (conventions avec les services pénitentiaires,
					etc.). Une association intermédiaire nous aide pour les questions
					d’insertion, ainsi que plusieurs entreprises privées qui sont devenues
					partenaires de notre travail. Au niveau médical, trois médecins et un
					dentiste implantés à proximité apportent leur concours. Des relations
					de confiance se sont aussi établies avec le service d’addictologie du
					Centre Hospitalier d’Alès.
				</p>
				<p className="mb-5 text-justify">
					La capacité d’accueil sur place est d’environ 100 lits. Les personnes
					accueillies sont hébergées soit en motel (petit duplex partagé), soit
					en chalet. Lors des camps, des familles membres de l’association
					proposent également des chambres chez elles, pour permettre la
					participation d’un plus grand nombre de personnes.
				</p>

				<div className="text-center pb-5 pt-5">
					{/* <div class="embed-responsive embed-responsive-16by9">
						<iframe
							class="embed-responsive-item"
							src={'https://www.youtube.com/embed/' + 'ybPnQh3KjOQ' + '?rel=0'}
							frameBorder={0}
							allowFullScreen
							modestbranding={1}
							controls={0}
							rel={0}
							info={0}
							allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
						/>
					</div> */}

					<div className="text-center">
						<video
							controls
							autoplay="true"
							muted
							style={{ marginLeft: 'auto', marginRight: 'auto', width: '100%' }}
						>
							<source
								src="https://www.dropbox.com/s/xdducg8z5ob864p/eee136d120911f5ad7fcb45caff49e319b36d023.mov?raw=1"
								type="video/mp4"
							/>
							Sorry, your browser doesn't support embedded videos.
						</video>
					</div>
				</div>
			</>
		);
	}
	render() {
		const { t } = this.props;

		return (
			<>
				<ModuleMaisonAccueil
					titre="Timothée Accueil et Jeunesse"
					sousTitre="Anduze"
					contact={CONTACTS[1]}
					idGallerie="1422"
					contenu={this.contenu()}
				/>
			</>
		);
	}
}

export default withTranslation()(CentreAccueilAnduze);
