import React from 'react';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import Page from './Page';
// import ModuleBlocImagesCroise from './ModuleBlocImagesCroise/ModuleBlocImagesCroise';
import { ModuleBlocImagesCroise } from '@mitim/react-mitim';

class Handicap extends Page {
	constructor(props) {
		super(props);
	}

	render() {
		const { t } = this.props;
		return (
			<div className="container-fluid no-gutters">
				<ModuleBlocImagesCroise
					data={{
						textPosition: 'droite',
						imgSrc: 'Page-handicap-partenaires2.jpg',
						titreImg: '',
						titre: 'UN ACCUEIL PERSONNALISÉ',
						sousTitre: '',
						contenu: (
							<>
								<p className="pt-3">
									L’association Timothée Accueil et Jeunesse développe un
									accueil personnalisé au service des personnes en situation de
									handicap et de leur famille. Une équipe de bénévoles, appuyée
									par un comité pluri-professionnel (médecin, orthophoniste,
									instituteur spécialisé...), examine chaque demande, en vue de
									favoriser l’accueil lors des camps de familles et de faciliter
									les liens avec les associations partenaires locales.
								</p>
								{/* <ul className="liste" style={{}}>
									<li className="">
										Pour tout renseignement complémentaire, contactez-nous au
										(coordonnées).
									</li>
								</ul> */}
							</>
						)
					}}
				/>

				<ModuleBlocImagesCroise
					data={{
						textPosition: 'gauche',
						imgSrc: 'Mission-automne.jpg',
						titreImg: 'Maison d’Anduze',
						titre: 'DES SÉJOURS ADAPTÉS',
						sousTitre: '',
						contenu: (
							<>
								<ul className="liste pt-3" style={{}}>
									<li className="">Séjours de répit</li>
								</ul>
								<p>
									Notre service d’accueil temporaire permet d’organiser des
									temps de répit pour les personnes touchées par le handicap et
									leur famille, tout au long des camps organisés sur nos
									différents sites. Ces instants favorisent des moments
									d’échanges privilégiés, permettant de se reposer, tantôt
									ensemble, tantôt séparément. Ils sont l’occasion de chercher
									le sens de nos vies au contact de la Parole de Dieu, dans le
									respect de la liberté de conscience de chacun.
								</p>

								<ul className="liste pt-2" style={{}}>
									<li className="">Séjours prolongés</li>
								</ul>

								<p>
									Les séjours peuvent se prolonger dans la durée, selon les
									besoins identifiés. Il est possible par exemple de bénéficier
									d’un accueil au semestre ou à l’année, dans le cadre des
									activités proposées par nos associations partenaires.
								</p>
								{/* <p>
									Pour plus de renseignements vous pouvez télécharger le pdf
									ci-joint
								</p> */}
							</>
						)
					}}
				/>
				<ModuleBlocImagesCroise
					data={{
						textPosition: 'droite',
						imgSrc: '1-Vache-handicap.jpg',
						titreImg: 'La ferme de Pelissou',
						titre: 'ASSOCIATIONS PARTENAIRES',
						sousTitre: '',
						contenu: (
							<>
								<ul className="liste pt-3" style={{}}>
									<li className="">Les Ateliers de Pélissou</li>
								</ul>
								<p>
									Les Ateliers de Pélissou organisent des activités journalières
									en collaboration avec une ferme pédagogique non loin d’Anduze.
									En participant aux diverses activités agricoles et en
									apprenant à soigner les animaux aux côtés de l’équipe
									d’encadrement, les jeunes présents sur la ferme découvrent le
									travail en équipe, ils nouent des relations amicales et
									développent des valeurs telles que la patience, la
									bienveillance et le don de soi.
								</p>

								<ul className="liste pt-2" style={{}}>
									<li className="">Team Etud’</li>
								</ul>

								<p>
									Une scolarisation adaptée peut également être mise en place
									grâce à l’association TeamEtud’. Voir rubrique « soutien
									scolaire »
								</p>
								<div className="text-center">
									<a
										href="https://fr-fr.facebook.com/fermedepelissou"
										target="_blank"
									>
										<img
											// src={require(`../resources/icons/Ateliers_pelissou.svg`)}
											src={require(`../resources/icons/Logo-ferme-pelissou.svg`)}
											className="img-fluid mt-3"
											style={{ width: '230px' }}
										/>
									</a>
									<a href="http://teametud.fr" target="_blank">
										<img
											className="img-fluid mt-3"
											style={{ width: '130px' }}
											src={require(`../resources/icons/Logo_teametud_1.svg`)}
										></img>
									</a>
								</div>
							</>
						)
					}}
				/>
			</div>
		);
	}
}

export default withTranslation()(Handicap);
