import React, { Component } from 'react';

export class CompositionSejour extends Component {
	state = {
		val: ''
	};

	componentDidMount() {}

	handleChange = event => {
		let target = event.target;
		let value = target.value;
		let id = target.id;

		this.check(id, value);
	};

	check(id, value) {
		if (id === 'typeSejour' && value === 'Couple avec enfant(s)') {
			this.addEnfant();
			this.props.self.setState(
				{
					avecEnfants: true,
					conjoint: true,
					mmeReferente: false,
					mlle: false,
					typeSejour: value
				},
				() => {
					this.props.self.checkDatas();
				}
			);
		}
		if (id === 'typeSejour' && value === 'Couple sans enfants') {
			this.props.self.setState(
				{
					avecEnfants: false,
					conjoint: true,
					mmeReferente: false,
					mlle: false,
					typeSejour: value,
					listeEnfants: []
				},
				() => {
					this.props.self.checkDatas();
				}
			);
		}
		if (id === 'typeSejour' && value === 'Mme avec enfant(s)') {
			this.addEnfant();
			this.props.self.setState(
				{
					avecEnfants: true,
					conjoint: false,
					mmeReferente: true,
					mlle: false,
					typeSejour: value
				},
				() => {
					this.props.self.checkDatas();
				}
			);
		}
		if (id === 'typeSejour' && value === 'M. avec enfant(s)') {
			this.addEnfant();
			this.props.self.setState(
				{
					avecEnfants: true,
					conjoint: false,
					mmeReferente: false,
					mlle: false,
					typeSejour: value
				},
				() => {
					this.props.self.checkDatas();
				}
			);
		}
		if (id === 'typeSejour' && value === 'M.') {
			this.props.self.setState(
				{
					avecEnfants: false,
					conjoint: false,
					mmeReferente: false,
					mlle: false,
					typeSejour: value,
					listeEnfants: []
				},
				() => {
					this.props.self.checkDatas();
				}
			);
		}
		if (id === 'typeSejour' && value === 'Mme') {
			this.props.self.setState(
				{
					avecEnfants: false,
					conjoint: false,
					mmeReferente: true,
					mlle: false,
					typeSejour: value,
					listeEnfants: []
				},
				() => {
					this.props.self.checkDatas();
				}
			);
		}
		if (id === 'typeSejour' && value === 'Mlle') {
			this.props.self.setState(
				{
					avecEnfants: false,
					conjoint: false,
					mmeReferente: true,
					mlle: true,
					typeSejour: value,
					listeEnfants: []
				},
				() => {
					this.props.self.checkDatas();
				}
			);
		}
	}

	addEnfant() {
		if (this.props.self.state.listeEnfants.length === 0) {
			// Au moment du chargement on ajoute un enfant par défaut
			this.props.self.addEnfant();
		}
	}

	render() {
		return (
			<>
				<label
					htmlFor="inputTitre"
					className="col-form-label pl-3 pr-3 mb-2 text-white bg-green"
				>
					COMPOSITION DU SÉJOUR
				</label>
				<select
					onChange={this.handleChange}
					className="form-control"
					name="typeSejour"
					id="typeSejour"
					value={this.props.self.state.typeSejour}
				>
					<option defaultValue>Couple avec enfant(s)</option>
					<option>Couple sans enfants</option>
					<option>Mme avec enfant(s)</option>
					<option>M. avec enfant(s)</option>
					<option>M.</option>
					<option>Mme</option>
					<option>Mlle</option>
				</select>
			</>
		);
	}
}
