// Libraries
import React from 'react';
import { withTranslation } from 'react-i18next';
// Component
import Page from '../Page';
import ModuleMaisonAccueil from '../ModuleMaisonAccueil/ModuleMaisonAccueil';
import { CONTACTS } from './Commun';

class CentreAccueilRiffray extends Page {
	componentDidMount() {
		super.componentDidMount();
	}

	contenu() {
		return (
			<>
				<p className="mt-4 text-justify">
					En 2007, un travail d’accueil commence au Riffray, dans une propriété
					anciennement maison familiale rurale. De fil en aiguille, autour d’un
					pasteur, d’un intendant et d’un cuisinier, une équipe assez nombreuse
					de bénévoles s’est constituée pour vivre au quotidien avec les
					personnes reçues (repas, réunions, activités pratiques). Aujourd’hui,
					nous recevons en moyenne tout au long de l’année entre dix et quinze
					personnes. Des logements ont été aménagés pour répondre aux
					différentes formes de besoins (dortoirs, studios, petits
					appartements). Notre modèle d’accueil correspond à ce qui se pratique
					dans la maison d’accueil à Anduze. Dans les faits, nous recevons
					actuellement une majorité de jeunes (moins de trente ans) étudiants,
					apprentis ou jeunes travailleurs.
				</p>
				{/* <h3 className="">LE FONCTIONNEMENT PENDANT ET HORS LES CAMPS</h3> */}
				<p className="mb-5 text-justify">
					Dans le cadre de sa vocation, la maison du Riffray assure aussi
					l’intendance lors des rassemblements des églises de la région ainsi
					que des groupes de jeunes. Avec le soutien des chrétiens des alentours
					(logement), nous organisons trois à quatre camps de familles par an.
					Nous envisageons notre travail comme une œuvre d’amour et aspirons à
					ce que la maison soit ouverte à toute personne dans le besoin.
				</p>
				<h3 className="contenu-h3 mb-5">L'équipe du Riffray</h3>
			</>
		);
	}
	render() {
		const { t } = this.props;

		return (
			<>
				<ModuleMaisonAccueil
					titre="Maison d'accueil"
					sousTitre="Le Riffray"
					contact={CONTACTS[5]}
					idGallerie="1424"
					contenu={this.contenu()}
				/>
			</>
		);
	}
}
export default withTranslation()(CentreAccueilRiffray);
