import React from 'react';
import { withTranslation } from 'react-i18next';
import { relative } from 'path';
import { Link } from 'react-router-dom';

class SlideTemplate1 extends React.Component {
	render() {
		const data = this.props.slide;
		const { t } = this.props;

		return (
			<>
				<div className="col-lg-2 no-gutters">
					<div className="d-none d-lg-block">
						<img
							src={require(`../../resources/icons/icone_oeuvreprotestante.svg`)}
							className="img-fluid mt-5"
							style={{ height: '100px' }}
						/>
					</div>
				</div>

				<div className="align-self-center col-12 col-lg-8">
					<div className="text-center mb-5 pt-5">
						<div className="text-center pt-5" style={{}}>
							<Link src="/">
								<img
									src={require(`../../resources/icons/TAJ_BLANC_SANSOMBRE.svg`)}
									className="img-fluid"
									style={{
										maxHeight: '150px',
										transform:
											'translate(0%, 50%)' /* décalage de 50% de sa propre taille */
									}}
								/>
							</Link>
						</div>
					</div>
				</div>

				<div
					className="col-lg-2 no-gutters d-none d-lg-block text-right"
					style={{}}
				>
					{/* <div style={{ position: relative, height: '100%', float:'right' }}> */}

					<div style={{ position: 'relative' }} className="mr-5">
						<div style={{ position: 'absolute', bottom: '-470px' }}>
							<a href="http://www.fep.asso.fr" target="_blank">
								<img
									src={require(`../../resources/icons/fep_blanc.png`)}
									className="img-fluid mt-3"
									style={{ width: '180px' }}
								/>
							</a>
							<a href="https://www.missiontimothee.fr" target="_blank">
								<img
									className="img-fluid mt-3"
									style={{ width: '180px' }}
									src={require(`../../resources/icons/logo_missiontimothee.svg`)}
								></img>
							</a>
						</div>
					</div>
					{/* </div> */}
				</div>
				{/* <div className="col-lg-1"></div> */}
			</>
		);
	}
}
export default withTranslation()(SlideTemplate1);
