import React from 'react';
import ToggleButton from './ToggleButton';
import Dropdown from './Dropdown';
import { Link, NavLink } from 'react-router-dom';
import Backdrop from './Backdrop';
import SvgIcon from '@material-ui/core/SvgIcon';
import './Menu.scss';

class Menu extends React.Component {
	state = {
		dropdownOpen: false,
		openedMenu: null
	};

	dropdownToggleClickHandler = () => {
		this.state.openedMenu && this.state.openedMenu.close();
		this.setState(prevState => ({
			dropdownOpen: !prevState.dropdownOpen,
			openedMenu: false
		}));
	};

	closeDropdownHandler = () => {
		this.state.openedMenu && this.state.openedMenu.close();
		this.setState({ dropdownOpen: false, openedMenu: false });
	};

	openMenu = menu => {
		if (menu != this.state.openedMenu) {
			this.state.openedMenu && this.state.openedMenu.close();
			this.setState({ openedMenu: menu });
		}
	};

	maison() {
		return (
			<img
				className=""
				style={{ height: '30px' }}
				src={require(`../../resources/icons/icone_home.svg`)}
			/>
		);
	}

	logoMissionTimothee() {
		return (
			<>
				{/* *** Mode mobile *** */}
				<span className="d-block d-xl-none">
					<SvgIcon
						style={{ width: '30px', height: '30px' }}
						width="300px"
						height="300px"
						viewBox="0 0 9080 9030"
						preserveAspectRatio="xMidYMid meet"
					>
						{/* <g id="layer101" fill="#ffffff" stroke="none"> */}
						<g
							id="layer101"
							fill="#b11022"
							stroke="none"
							preserveAspectRatio="xMidYMid meet"
							viewBox="0 0 9080 9030"
							height="300px"
							width="300px"
						>
							<path d="M4190 9023 c-25 -2 -108 -10 -185 -19 -1606 -175 -3014 -1224 -3654 -2721 -203 -477 -330 -1029 -348 -1510 l-6 -173 36 -15 c86 -36 310 -90 449 -110 l47 -6 6 43 c62 456 70 505 139 817 142 642 421 1210 845 1716 101 121 343 362 466 464 400 334 844 581 1330 741 612 200 1253 246 1910 134 132 -22 362 -79 510 -125 198 -63 371 -134 585 -241 666 -333 1229 -853 1613 -1493 30 -49 68 -100 85 -111 63 -43 197 -40 477 12 77 14 142 28 145 30 6 6 -69 156 -142 284 -506 885 -1282 1561 -2244 1954 -426 174 -896 284 -1389 326 -109 9 -567 11 -675 3z" />
							<path d="M5925 6390 c-84 -14 -147 -36 -194 -70 -79 -57 -98 -116 -75 -228 8 -40 12 -77 9 -82 -8 -13 -54 -13 -200 1 -104 10 -125 10 -156 -3 -54 -23 -92 -78 -97 -143 -5 -56 -5 -55 62 -180 l38 -70 -27 -3 c-15 -2 -65 4 -111 13 -99 20 -133 14 -176 -28 -99 -100 -89 -270 22 -371 83 -74 168 -100 391 -116 162 -12 196 -20 228 -54 28 -30 21 -60 -19 -81 -38 -20 -173 -20 -281 1 -245 45 -407 102 -604 211 -69 38 -123 38 -164 -1 -64 -62 -82 -156 -43 -233 41 -80 173 -178 384 -284 110 -55 198 -76 406 -94 215 -19 479 -59 529 -80 l36 -15 -28 -30 c-16 -16 -78 -54 -139 -84 -346 -171 -476 -313 -410 -452 34 -71 81 -70 235 6 l106 52 143 9 c162 10 207 17 410 64 80 19 190 41 244 50 210 34 292 82 546 320 228 213 338 286 486 321 95 22 270 20 414 -5 132 -23 642 -150 865 -216 145 -42 306 -85 321 -85 12 0 -4 445 -20 590 -37 318 -106 624 -206 920 -32 96 -66 193 -75 214 l-16 39 -105 -6 c-57 -4 -247 -23 -422 -43 -227 -26 -340 -34 -399 -30 -102 6 -222 45 -372 120 -292 147 -354 161 -676 157 -367 -6 -376 -6 -595 1 -117 4 -231 3 -265 -2z" />
							<path d="M3665 5335 c-130 -43 -410 -165 -460 -200 -27 -19 -75 -63 -106 -97 -63 -71 -197 -188 -278 -243 -42 -27 -63 -35 -89 -33 -20 2 -37 9 -40 17 -11 34 227 211 358 266 69 28 92 55 97 112 5 60 -18 102 -73 129 -31 15 -61 19 -145 19 -101 0 -108 -1 -180 -37 -41 -20 -124 -76 -184 -125 -157 -125 -164 -130 -330 -228 -505 -296 -552 -328 -764 -520 -158 -142 -218 -187 -316 -235 -194 -97 -358 -93 -891 20 -129 28 -241 50 -250 50 -17 0 -16 -21 12 -260 46 -372 151 -784 286 -1110 l44 -105 179 3 c229 4 368 16 580 52 247 42 300 44 545 21 172 -16 237 -18 325 -10 61 5 119 11 130 13 11 3 90 10 175 16 85 6 182 17 215 25 33 7 147 50 254 95 145 62 237 93 369 127 97 25 184 49 194 54 9 5 83 20 165 34 201 34 275 57 307 96 33 39 33 57 -2 105 -52 72 -193 126 -362 140 -99 8 -114 17 -80 46 38 32 190 101 340 153 293 103 572 281 769 491 89 95 119 147 128 221 7 64 -15 109 -64 130 -63 26 -149 -5 -255 -91 -150 -122 -331 -222 -659 -365 -233 -101 -310 -120 -353 -88 -38 28 20 107 127 175 34 21 125 79 202 127 77 49 173 113 212 144 40 31 126 97 190 146 144 111 176 152 181 238 4 56 1 66 -22 94 -15 17 -47 41 -72 53 -70 34 -124 25 -221 -37 -43 -28 -121 -72 -173 -98 -147 -73 -216 -117 -339 -215 -107 -86 -175 -128 -231 -145 -22 -6 -19 -1 20 40 25 26 88 81 140 123 52 42 143 122 202 177 84 80 129 113 215 160 59 33 127 77 150 98 73 67 81 147 20 204 -64 61 -86 64 -192 28z" />
							<path d="M8480 4285 c0 -42 -36 -334 -55 -443 -56 -325 -132 -574 -251 -823 -114 -242 -136 -310 -138 -429 -1 -80 2 -101 21 -136 70 -131 250 -189 379 -122 114 59 234 230 329 467 132 330 276 962 297 1304 l3 49 -75 24 c-105 34 -246 69 -375 93 -116 22 -135 24 -135 16z" />
							<path d="M595 2469 c-55 -6 -101 -12 -103 -14 -8 -8 157 -287 259 -440 735 -1093 1903 -1811 3229 -1985 112 -15 243 -24 410 -27 225 -5 252 -4 327 15 206 52 306 154 290 294 -11 91 -68 182 -141 227 -67 42 -141 52 -408 55 -263 4 -445 22 -668 66 -778 156 -1455 511 -2014 1059 -179 176 -334 356 -467 544 -101 142 -129 167 -209 192 -67 21 -352 29 -505 14z" />
						</g>
					</SvgIcon>
				</span>
				{/* ****************************************** */}

				{/* *** Mode grand ecran *** */}
				{/* <div className="d-none d-xl-block">
					<div style={{}}>
						<img
							className=""
							style={{ height: '35px' }}
							src={require(`../../resources/icons/logo_missiontimothee.svg`)}
						></img>
					</div>
				</div> */}
				{/* ************************************************ */}
			</>
		);
	}

	render() {
		let toolbarItems = this.props.items.map(item => (
			<li
				key={item.rank}
				className={
					(item.children ? 'submenu' : '') +
					' menuitem align-items-center text-menu'
				}
			>
				{item.link && // Lien sans sous menu
					(item.link.startsWith('https://') ? (
						<a href={item.link}>{item.name}</a>
					) : (
						<Link className="text-menu" to={item.link}>
							{item.name}
						</Link>
					))}
				{item.children && ( // lien avec sous menu
					<>
						{item.name}
						<ul className="flex-column align-items-begin">
							<li className="arrow-down" />
							{item.children.map(child => (
								<li key={child.rank}>
									{child.link.startsWith('https://') ? (
										<a className="text-menu" href={child.link}>
											{child.name}
										</a>
									) : (
										<NavLink className="text-menu" to={child.link}>
											{child.name}
										</NavLink>
									)}
								</li>
							))}
						</ul>
					</>
				)}
			</li>
		));
		return (
			<>
				<div className="container-fluid d-flex toolbar fixed-top flex-row align-items-center">
					<div className="toolbar__logo">
						<Link to="/" onClick={this.closeDropdownHandler}>
							{/* *** maison *** */}
							{this.maison()}
							{/* ************************** */}
						</Link>
					</div>
					<div className="toolbar__items d-none d-xl-flex">
						<ul className="d-flex flex-row align-items-center">
							{toolbarItems}
						</ul>
					</div>

					<div className="ml-auto" />

					<a
						href="https://www.missiontimothee.fr/"
						alt="Site de la Mission Timothée"
					>
						{/* *** logo mission timothee *** */}
						{this.logoMissionTimothee()}
						{/* ********************* */}
					</a>
					<div className="ml-3" />
					<ToggleButton click={this.dropdownToggleClickHandler} />
				</div>

				<Dropdown
					items={this.props.items}
					open={this.state.dropdownOpen}
					click={this.closeDropdownHandler}
					openMenu={this.openMenu}
				/>
				{this.state.dropdownOpen && (
					<Backdrop click={this.closeDropdownHandler} />
				)}
			</>
		);
	}
}

export default Menu;
