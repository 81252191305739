import React from 'react';
import Cookies from 'universal-cookie';
import { Redirect, withRouter } from 'react-router-dom';
import HeaderAndRoute from './HeaderAndRoute';

const cookies = new Cookies();
const AUTHORITIES = 'authorities';
const SSID = 'ssid';
const USER = 'user';
const TOKEN = 'token';

export const ROLE_ADMIN = 'ROLE_ADMIN';
export const ROLE_CANDIDAT = 'ROLE_CANDIDAT';
export const ROLE_REUNION_ENFANT = 'ROLE_REUNION_ENFANT';

const auth = {
	authenticate(email, password, callback) {
		// TODO make request to timedia
		cookies.set(SSID, 'TEST');
		cookies.set(USER, { nom: 'MORON', prenom: 'Yohan', email });
		cookies.set(AUTHORITIES, [ROLE_ADMIN, ROLE_CANDIDAT]);
		cookies.set(TOKEN, 'AUTH');
		setTimeout(callback, 100); // fake async
	},

	signout(callback) {
		cookies.remove(SSID);
		cookies.remove(USER);
		cookies.set(AUTHORITIES, ['ROLE_PUBLIC']);
		cookies.set(TOKEN, 'PUBLIC');
		if (callback && typeof callback === 'function') {
			callback();
		}
	},

	getUser() {
		return cookies.get(USER);
	},

	isAuthenticated() {
		if (
			cookies.get(SSID) &&
			cookies.get(AUTHORITIES) &&
			cookies.get(TOKEN) &&
			cookies.get(USER)
		) {
			return true;
		} else {
			return false;
		}
	},

	userHaveAuthorities(authorities) {
		return (
			this.isAuthenticated() &&
			(authorities.some(a => cookies.get(AUTHORITIES).includes(a)) ||
				cookies.get(AUTHORITIES).includes(ROLE_ADMIN))
		);
	}
};

export default auth;

export const LogoutButton = withRouter(
	({ component: Component, history, match, location, ...rest }) =>
		auth.isAuthenticated() && (
			<Component
				{...rest}
				onClick={() => {
					auth.signout(() => history.push('/'));
				}}
			/>
		)
);

export const PrivateRoute = ({
	component: Component,
	authorities,
	...rest
}) => (
	<HeaderAndRoute
		{...rest}
		render={props =>
			auth.userHaveAuthorities(authorities) ? (
				<Component {...props} />
			) : (
				<Redirect
					to={{
						pathname: '/login',
						state: { from: props.location }
					}}
				/>
			)
		}
	/>
);

export const LoginRoute = ({ component: Component, ...rest }) => (
	<HeaderAndRoute
		{...rest}
		render={props =>
			!auth.isAuthenticated() ? (
				<Component {...props} />
			) : (
				<Redirect
					to={{
						pathname: '/',
						state: { from: props.location }
					}}
				/>
			)
		}
	/>
);
