// Libraries
import React from 'react';
import { withTranslation } from 'react-i18next';
// Component
import Page from '../Page';
import CustomInput, { HelperTextWithIcon, DateMask } from '../CustomInput';
import { titleCase } from '../../utils/functions';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
// import { sendInscription, getCamps } from '../../api/api';
import { api } from '@mitim/react-mitim';
import Moment from 'moment';
import { tools } from '@mitim/react-mitim';
import './InscriptionCamp.scss';
import { CompositionSejour } from './CompositionSejour';
import { InputText } from './InputText';
import { Accompagnant } from './Accompagnant';
import { Checkbox } from './Checkbox';
import { TextArea } from './TextArea';
import { isFunctionTypeNode } from 'typescript';
import { ContactSupportOutlined } from '@material-ui/icons';

class InscriptionCamp extends Page {
	constructor(props) {
		super(props);
		this.handleShow = this.handleShow.bind(this);
		this.handleClose = this.handleClose.bind(this);
	}

	state = {
		lieu: '', // Lieux de la maison d'accueil (anduze, riffray ou Fouday) => 'and' ou 'rif' ou 'foud'

		// datas page 1 --------------------------------------------------------------------------------------------------------------------------
		choixTypeCamp: 'campProgramme', // choix du type de camp
		//**************************** */
		//VOUS PARTICIPEZ À UN CAMP DU PROGRAMME
		choixDuCampDuProgramme: '', // id du camp choisi
		//

		// VOUS PARTICIPEZ À UN CAMP SPÉCIAL
		choixCampSpecial: '', // camp de travail ou week miss ou sem formation
		sejourHorsProgrammeDateDebut: '',
		sejourHorsProgrammeDateFin: '',
		//
		jourArrive: '',
		heureArrive: '17:00',
		jourDepart: '',
		heureDepart: '09:00',

		//MOYEN DE TRANSPORT
		moyenTransport: 'voiture',
		disposeVoiturePendantSejour: '',

		// fin page 1 -----------------------------------------------------------------------------------------------------------------------------

		// datas page 2 --------------------------------------------------------------------------------------------------------------------------
		// *** composition Sejour
		conjoint: true,
		mmeReferente: false,
		mlle: false,
		avecEnfants: true,
		typeSejour: 'Couple avec enfant(s)',
		// ***
		// fin page 2 -----------------------------------------------------------------------------------------------------------------------------

		// datas page 3 --------------------------------------------------------------------------------------------------------------------------
		//*** information personne  */
		nom: '',
		prenom: '',
		dateNaissance: '',
		lieuNaissance: '',
		email: '',
		adressePostale: '',
		ville: '',
		pays: '',
		codePostal: '',
		telFixe: '',
		telMobile: '',
		//
		nomJeuneFille: '',
		// fin page 3 -----------------------------------------------------------------------------------------------------------------------------

		// datas page 4 --------------------------------------------------------------------------------------------------------------------------
		nomConjoint: '',
		prenomConjoint: '',
		dateNaissanceConjoint: '',
		lieuNaissanceConjoint: '',
		emailConjoint: '',
		telMobileConjoint: '',
		// fin page 4 -----------------------------------------------------------------------------------------------------------------------------

		// datas page 5 --------------------------------------------------------------------------------------------------------------------------
		// **** enfants et adultes supplémentaires
		listeEnfants: [],
		listeAdultes: [],
		// ***************************************
		// fin page 5 -----------------------------------------------------------------------------------------------------------------------------

		// datas page 6 --------------------------------------------------------------------------------------------------------------------------
		// */
		laMissionDoitMeLoger: true,
		precisionLieuLogementExterieur: '',
		// */
		jaccepteLogerSousTente: true,
		japporteUneTente: false,
		accepteLogerChezHabitant: true,
		raisonPasChezHabitant: '',
		desireCouchage: true,
		//
		commentaire: '',
		jaccepteCGU: false,
		// fin page 6 -----------------------------------------------------------------------------------------------------------------------------

		//-----------------------------------------------------------------------------------------------------------------------------------------
		// VALIDATIONS

		// page 1 ____________________________________________________
		sejourHorsProgrammeDateDebutValid: false,
		sejourHorsProgrammeDateFinValid: false,
		//
		jourArriveValid: false,
		heureArriveValid: false,
		jourDepartValid: false,
		heureDepartValid: false,
		// FIN PAGE 1 ____________________________________________________________

		// page 3 ____________________________________________________
		nomValid: false,
		nomJeuneFilleValid: false,
		prenomValid: false,
		dateNaissanceValid: false,
		lieuNaissanceValid: false,
		emailValid: false,
		adressePostaleValid: false,
		villeValid: false,
		paysValid: false,
		codePostalValid: false,
		telMobileValid: false,
		// FIN PAGE 3 ____________________________________________________________

		// page 4 ____________________________________________________
		nomConjointValid: false,
		prenomConjointValid: false,
		// nom de jeune fille deja declare dans page 2
		dateNaissanceConjointValid: false,
		lieuNaissanceConjointValid: false,
		telMobileConjointValid: false,
		emailConjointValid: false,
		// FIN PAGE 4 ____________________________________________________________

		// FIN PAGE 6 ____________________________________________________________
		precisionLieuLogementExterieurValid: false,
		raisonPasChezHabitantValid: false,
		// PAGE 6 ____________________________________________________________

		//-----------------------------------------------------------------------------------------------------------------------------------------
		//-----------------------------------------------------------------------------------------------------------------------------------------

		// FIN DATAS -------------------------------------------------------------------------------------------------------

		//$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$
		//STATES TECHNIQUES
		camp: {}, // camp selectionné
		camps: [],
		show: false,
		messageRetour: (
			<>
				Votre demande d'inscription a bien été envoyée
				<br />
			</>
		),
		success: true,
		showCovid: false, // Affichage au nom de la boîte de dialogue covid
		//
		couleurFondBloc: 'bg-green3', // Définit la couleur de fond du bloc de la page
		pageCourante: 1, // Page courante visible
		pageSuivanteVisible: false,
		boutonEnvoyerVisible: false,
		envoiEnCours: false,
		inscriptionJson: {},
		debug: false // afficher les console log de debug
		//$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$
	};
	// FIN STATES
	//******************************************************************************************************************** */

	componentDidMount() {
		super.componentDidMount();
		this.setState({ lieu: this.props.lieu });
		this.loadCamp();
		if (this.state.listeEnfants.length === 0) {
			// Au moment du chargement on ajoute un enfant par défaut
			this.addEnfant();
		}

		window.addEventListener('popstate', e => {
			// si on clique sur le bouton précédent du navigateur
			this.props.history.go(1); // on reste sur la meme page
		});

		localStorage.removeItem('inscription'); // On supprime les données enregistrées TODO corriger le probleme
		//******************************************************************* */
		// Pour recharger les données enregistrées
		// const inscription = localStorage.getItem('inscription');
		// if (inscription !== null) {
		// 	const inscrptionObj = JSON.parse(inscription);
		// 	this.loadInscrptionFromSessionStorage(inscrptionObj);
		// }
		//******************************************************************* */
	}

	//******************************************************************************************************************** */

	loadInscrptionFromSessionStorage = inscription => {
		console.log(inscription);
		this.setState({
			// datas page 1 --------------------------------------------------------------------------------------------------------------------------
			//choixTypeCamp: inscription.choixTypeCamp, // choix du type de camp
			//**************************** */
			//VOUS PARTICIPEZ À UN CAMP DU PROGRAMME,
			//choixDuCampDuProgramme: inscription.choixDuCampDuProgramme, // id du camp choisi
			//VOUS PARTICIPEZ À UN CAMP SPÉCIAL,
			//choixCampSpecial: inscription.choixCampSpecial, // camp de travail ou week miss ou sem formation
			//sejourHorsProgrammeDateDebut: inscription.sejourHorsProgrammeDateDebut,
			//sejourHorsProgrammeDateFin: inscription.sejourHorsProgrammeDateFin,

			//jourArrive: inscription.jourArrive,
			//heureArrive: inscription.heureArrive,
			//jourDepart: inscription.jourDepart,
			//heureDepart: inscription.heureDepart,
			//MOYEN DE TRANSPORT
			//moyenTransport: inscription.moyenTransport,
			//disposeVoiturePendantSejour: inscription.disposeVoiturePendantSejour,
			// fin page 1 -----------------------------------------------------------------------------------------------------------------------------

			// datas page 2 --------------------------------------------------------------------------------------------------------------------------
			typeSejour: inscription.typeSejour,
			//typeSejour: 'Couple avec enfant(s)',
			// fin page 2 -----------------------------------------------------------------------------------------------------------------------------

			// datas page 3 --------------------------------------------------------------------------------------------------------------------------
			nom: inscription.nom,
			prenom: inscription.prenom,
			dateNaissance: inscription.dateNaissance,
			lieuNaissance: inscription.lieuNaissance,
			email: inscription.email,
			adressePostale: inscription.adressePostale,
			ville: inscription.ville,
			pays: inscription.pays,
			codePostal: inscription.codePostal,
			telFixe: inscription.telFixe,
			telMobile: inscription.telMobile,
			nomJeuneFille: inscription.nomJeuneFille,
			// fin page 3 -----------------------------------------------------------------------------------------------------------------------------

			// datas page 4 --------------------------------------------------------------------------------------------------------------------------
			nomConjoint: inscription.nomConjoint,
			prenomConjoint: inscription.prenomConjoint,
			dateNaissanceConjoint: inscription.dateNaissanceConjoint,
			lieuNaissanceConjoint: inscription.lieuNaissanceConjoint,
			emailConjoint: inscription.emailConjoint,
			telMobileConjoint: inscription.telMobileConjoint,
			// fin page 4 -----------------------------------------------------------------------------------------------------------------------------

			// datas page 5 --------------------------------------------------------------------------------------------------------------------------
			listeEnfants: inscription.listeEnfants,
			listeAdultes: inscription.listeAdultes,
			// fin page 5 -----------------------------------------------------------------------------------------------------------------------------

			// datas page 6 --------------------------------------------------------------------------------------------------------------------------
			// laMissionDoitMeLoger: inscription.laMissionDoitMeLoger,
			// precisionLieuLogementExterieur: inscription.precisionLieuLogementExterieur,
			// jaccepteLogerSousTente: inscription.jaccepteLogerSousTente,
			// japporteUneTente: inscription.japporteUneTente,
			// accepteLogerChezHabitant: inscription.accepteLogerChezHabitant,
			// raisonPasChezHabitant: inscription.raisonPasChezHabitant,
			// desireCouchage: inscription.desireCouchage,
			commentaire: inscription.commentaire,
			jaccepteCGU: inscription.jaccepteCGU
			// fin page 6 -----------------------------------------------------------------------------------------------------------------------------
		});
	};

	//******************************************************************************************************************** */

	getInscriptionJsonObject = () => {
		let inscription = {
			lieu: this.state.lieu,
			// datas page 1 --------------------------------------------------------------------------------------------------------------------------
			choixTypeCamp: this.state.choixTypeCamp, // choix du type de camp
			//**************************** */
			//VOUS PARTICIPEZ À UN CAMP DU PROGRAMME
			choixDuCampDuProgramme: this.state.choixDuCampDuProgramme, // id du camp choisi
			// VOUS PARTICIPEZ À UN CAMP SPÉCIAL
			choixCampSpecial: this.state.choixCampSpecial, // camp de travail ou week miss ou sem formation
			sejourHorsProgrammeDateDebut: this.state.sejourHorsProgrammeDateDebut,
			sejourHorsProgrammeDateFin: this.state.sejourHorsProgrammeDateFin,
			//
			jourArrive: this.state.jourArrive,
			heureArrive: this.state.heureArrive,
			jourDepart: this.state.jourDepart,
			heureDepart: this.state.heureDepart,
			//MOYEN DE TRANSPORT
			moyenTransport: this.state.moyenTransport,
			disposeVoiturePendantSejour: this.state.disposeVoiturePendantSejour,
			// fin page 1 -----------------------------------------------------------------------------------------------------------------------------

			// datas page 2 --------------------------------------------------------------------------------------------------------------------------
			typeSejour: this.state.typeSejour,
			// fin page 2 -----------------------------------------------------------------------------------------------------------------------------

			// datas page 3 --------------------------------------------------------------------------------------------------------------------------
			nom: this.state.nom,
			prenom: this.state.prenom,
			dateNaissance: this.state.dateNaissance,
			lieuNaissance: this.state.lieuNaissance,
			email: this.state.email,
			adressePostale: this.state.adressePostale,
			ville: this.state.ville,
			pays: this.state.pays,
			codePostal: this.state.codePostal,
			telFixe: this.state.telFixe,
			telMobile: this.state.telMobile,
			nomJeuneFille: this.state.nomJeuneFille,
			// fin page 3 -----------------------------------------------------------------------------------------------------------------------------

			// datas page 4 --------------------------------------------------------------------------------------------------------------------------
			nomConjoint: this.state.nomConjoint,
			prenomConjoint: this.state.prenomConjoint,
			dateNaissanceConjoint: this.state.dateNaissanceConjoint,
			lieuNaissanceConjoint: this.state.lieuNaissanceConjoint,
			emailConjoint: this.state.emailConjoint,
			telMobileConjoint: this.state.telMobileConjoint,
			// fin page 4 -----------------------------------------------------------------------------------------------------------------------------

			// datas page 5 --------------------------------------------------------------------------------------------------------------------------
			listeEnfants: this.state.listeEnfants,
			listeAdultes: this.state.listeAdultes,
			// fin page 5 -----------------------------------------------------------------------------------------------------------------------------

			// datas page 6 --------------------------------------------------------------------------------------------------------------------------
			laMissionDoitMeLoger: this.state.laMissionDoitMeLoger,
			precisionLieuLogementExterieur: this.state.precisionLieuLogementExterieur,
			jaccepteLogerSousTente: this.state.jaccepteLogerSousTente,
			japporteUneTente: this.state.japporteUneTente,
			accepteLogerChezHabitant: this.state.accepteLogerChezHabitant,
			raisonPasChezHabitant: this.state.raisonPasChezHabitant,
			desireCouchage: this.state.desireCouchage,
			commentaire: this.state.commentaire,
			jaccepteCGU: this.state.jaccepteCGU
			// fin page 6 -----------------------------------------------------------------------------------------------------------------------------
		};
		return JSON.stringify(inscription);
	};
	showDatasForDebug = () => {
		const inscription = this.getInscriptionJsonObject();
		localStorage.setItem('inscription', inscription);

		if (this.state.debug) {
			console.log(inscription);
			console.table([
				['PAGE 1', '-------------------------'],
				['choixTypeCamp', this.state.choixTypeCamp],
				['choixDuCampDuProgramme', this.state.choixDuCampDuProgramme],
				['choixCampSpecial', this.state.choixCampSpecial],
				[
					'sejourHorsProgrammeDateDebut',
					this.state.sejourHorsProgrammeDateDebut
				],
				['sejourHorsProgrammeDateFin', this.state.sejourHorsProgrammeDateFin],
				['jourArrive', this.state.jourArrive],
				['heureArrive', this.state.heureArrive],
				['jourDepart', this.state.jourDepart],
				['heureDepart', this.state.heureDepart],
				['moyenTransport', this.state.moyenTransport],
				['disposeVoiturePendantSejour', this.state.disposeVoiturePendantSejour],
				['PAGE 2', '-------------------------'],
				['typeSejour', this.state.typeSejour],
				['PAGE 3', '-------------------------'],
				['nom', this.state.nom],
				['prenom', this.state.prenom],
				['dateNaissance', this.state.dateNaissance],
				['lieuNaissance', this.state.lieuNaissance],
				['email', this.state.email],
				['adressePostale', this.state.adressePostale],
				['ville', this.state.ville],
				['pays', this.state.pays],
				['codePostal', this.state.codePostal],
				['telFixe', this.state.telFixe],
				['telMobile', this.state.telMobile],
				['nomJeuneFille', this.state.nomJeuneFille],
				['PAGE 4', '-------------------------'],
				['nomConjoint', this.state.nomConjoint],
				['prenomConjoint', this.state.prenomConjoint],
				['dateNaissanceConjoint', this.state.dateNaissanceConjoint],
				['lieuNaissanceConjoint', this.state.lieuNaissanceConjoint],
				['emailConjoint', this.state.emailConjoint],
				['telMobileConjoint', this.state.telMobileConjoint],
				['PAGE 5', '-------------------------'],
				['listeEnfants', JSON.stringify(this.state.listeEnfants)],
				['listeAdultes', JSON.stringify(this.state.listeAdultes)],
				['PAGE 6', '-------------------------'],
				['laMissionDoitMeLoger', this.state.laMissionDoitMeLoger],
				[
					'precisionLieuLogementExterieur',
					this.state.precisionLieuLogementExterieur
				],
				['jaccepteLogerSousTente', this.state.jaccepteLogerSousTente],
				['japporteUneTente', this.state.japporteUneTente],
				['accepteLogerChezHabitant', this.state.accepteLogerChezHabitant],
				['raisonPasChezHabitant', this.state.raisonPasChezHabitant],
				['desireCouchage', this.state.desireCouchage],
				['commentaire', this.state.commentaire],
				['jaccepteCGU', this.state.jaccepteCGU]
			]);
		}
	};

	//******************************************************************************************************************** */
	checkDatas = () => {
		this.showDatasForDebug();
		//****************************************** */
		if (this.state.pageCourante == 1) {
			// Choix du camp

			let pageSuivanteVisible = false;
			if (this.state.choixTypeCamp === 'campProgramme') {
				if (
					this.state.jourArriveValid &&
					this.state.heureArriveValid &&
					this.state.jourDepartValid &&
					this.state.heureDepartValid &&
					this.state.disposeVoiturePendantSejour !== '' &&
					this.state.choixDuCampDuProgramme !== ''
				) {
					pageSuivanteVisible = true;
				} else {
					pageSuivanteVisible = false;
				}
			}

			if (this.state.choixTypeCamp === 'campSpecial') {
				if (
					this.state.sejourHorsProgrammeDateDebutValid &&
					this.state.sejourHorsProgrammeDateFinValid &&
					this.state.jourArriveValid &&
					this.state.heureArriveValid &&
					this.state.jourDepartValid &&
					this.state.heureDepartValid &&
					this.state.disposeVoiturePendantSejour !== ''
				) {
					pageSuivanteVisible = true;
				} else {
					pageSuivanteVisible = false;
				}
			}

			if (this.state.choixTypeCamp === 'horsCamp') {
				if (
					this.state.jourArriveValid &&
					this.state.heureArriveValid &&
					this.state.jourDepartValid &&
					this.state.heureDepartValid &&
					this.state.disposeVoiturePendantSejour !== ''
				) {
					pageSuivanteVisible = true;
				} else {
					pageSuivanteVisible = false;
				}
			}

			this.setState({
				pageSuivanteVisible: pageSuivanteVisible
			});
		}
		//****************************************** */
		if (this.state.pageCourante == 3) {
			// Informations du référent
			if (
				this.state.typeSejour === 'Couple avec enfant(s)' ||
				this.state.typeSejour === 'Couple sans enfants'
			) {
				if (
					this.state.nomValid &&
					this.state.prenomValid &&
					this.state.dateNaissanceValid &&
					this.state.lieuNaissanceValid &&
					this.state.emailValid &&
					this.state.adressePostaleValid &&
					this.state.villeValid &&
					this.state.paysValid &&
					this.state.codePostalValid &&
					this.state.telMobileValid
				) {
					this.setState({
						pageSuivanteVisible: true
					});
				} else {
					this.setState({
						pageSuivanteVisible: false
					});
				}
			}
			if (
				this.state.typeSejour === 'M. avec enfant(s)' ||
				this.state.typeSejour === 'M.'
			) {
				if (
					this.state.nomValid &&
					this.state.prenomValid &&
					this.state.dateNaissanceValid &&
					this.state.lieuNaissanceValid &&
					this.state.emailValid &&
					this.state.adressePostaleValid &&
					this.state.villeValid &&
					this.state.paysValid &&
					this.state.codePostalValid &&
					this.state.telMobileValid
				) {
					this.setState({
						pageSuivanteVisible: true
					});
				} else {
					this.setState({
						pageSuivanteVisible: false
					});
				}
			}
			if (
				this.state.typeSejour === 'Mme avec enfant(s)' ||
				this.state.typeSejour === 'Mme'
			) {
				if (
					this.state.nomValid &&
					this.state.prenomValid &&
					this.state.dateNaissanceValid &&
					this.state.lieuNaissanceValid &&
					this.state.emailValid &&
					this.state.adressePostaleValid &&
					this.state.villeValid &&
					this.state.paysValid &&
					this.state.codePostalValid &&
					this.state.telMobileValid &&
					this.state.nomJeuneFilleValid
				) {
					this.setState({
						pageSuivanteVisible: true
					});
				} else {
					this.setState({
						pageSuivanteVisible: false
					});
				}
			}

			if (this.state.typeSejour === 'Mlle') {
				if (
					this.state.prenomValid &&
					this.state.dateNaissanceValid &&
					this.state.lieuNaissanceValid &&
					this.state.emailValid &&
					this.state.adressePostaleValid &&
					this.state.villeValid &&
					this.state.paysValid &&
					this.state.codePostalValid &&
					this.state.telMobileValid
				) {
					this.setState({
						pageSuivanteVisible: true
					});
				} else {
					this.setState({
						pageSuivanteVisible: false
					});
				}
			}
		}
		//****************************************** */
		if (this.state.pageCourante == 4) {
			// Informations du conjoint
			if (
				this.state.typeSejour === 'Couple avec enfant(s)' ||
				this.state.typeSejour === 'Couple sans enfants'
			) {
				if (
					this.state.nomConjointValid &&
					this.state.nomJeuneFilleValid &&
					this.state.prenomConjointValid &&
					this.state.dateNaissanceConjointValid &&
					this.state.lieuNaissanceConjointValid &&
					this.state.emailConjointValid &&
					this.state.telMobileConjointValid
				) {
					this.setState({
						pageSuivanteVisible: true
					});
				} else {
					this.setState({
						pageSuivanteVisible: false
					});
				}
			}
		}
		//****************************************** */
		if (this.state.pageCourante == 5) {
			// Ajouter des enfants ou des adultes
			let listeEnfants = this.state.listeEnfants;
			let listeAdultes = this.state.listeAdultes;
			const foundEnfants = listeEnfants.find(
				element => element.isValid == false
			); // On récupère un enfant qui n'est pas valide
			const foundAdultes = listeAdultes.find(
				element => element.isValid == false
			); // On récupère un enfant qui n'est pas valide

			let pageSuivanteVisible = true;
			if (typeof foundEnfants === 'object' && this.state.avecEnfants) {
				// Si on récupère des objets c'est que au moins un enfant n'est pas valide
				pageSuivanteVisible = false;
			}
			if (typeof foundAdultes === 'object') {
				// Si on récupère des objets c'est que au moins un adulte n'est pas valide
				pageSuivanteVisible = false;
			}

			this.setState({
				pageSuivanteVisible: pageSuivanteVisible
			});
		}

		//****************************************** */
		if (this.state.pageCourante === 6) {
			// logement et matériel
			if (
				(this.state.laMissionDoitMeLoger === true &&
					this.state.accepteLogerChezHabitant === true &&
					this.state.jaccepteCGU) ||
				(this.state.laMissionDoitMeLoger === false &&
					this.state.precisionLieuLogementExterieurValid === true &&
					this.state.jaccepteCGU) ||
				(this.state.laMissionDoitMeLoger === true &&
					this.state.accepteLogerChezHabitant === false &&
					this.state.raisonPasChezHabitantValid === true &&
					this.state.jaccepteCGU)
			) {
				this.setState({
					boutonEnvoyerVisible: true
				});
			} else {
				this.setState({
					boutonEnvoyerVisible: false
				});
			}
		}
		this.showDatasForDebug();
	};

	loadCamp() {
		if (this.props.lieu === 'anduze') {
			this.setState({ lieu: 'and' });
			api.getCamps('AND', this, () => {});
		}
		if (this.props.lieu === 'riffray') {
			this.setState({ lieu: 'rif' });
			api.getCamps('RIF', this, () => {});
		}
		if (this.props.lieu === 'fouday') {
			this.setState({ lieu: 'foud' });
			api.getCamps('FOUD', this, () => {});
		}
	}
	//******************************************************************************************************************** */

	componentDidUpdate() {
		if (this.props.lieu !== this.state.lieu) {
			this.setState({
				lieu: this.props.lieu,
				pageCourante: 1,
				couleurFondBloc: 'bg-green3'
			});
			if (this.props.lieu === 'anduze') {
				this.setState({ showCovid: false });
				api.getCamps('AND', this, () => {});
			}
			if (this.props.lieu === 'riffray') {
				this.setState({ showCovid: false });
				api.getCamps('RIF', this, () => {});
			}
			if (this.props.lieu === 'fouday') {
				this.setState({ showCovid: false });
				api.getCamps('FOUD', this, () => {});
			}
			window.scrollTo(0, 0);
		}
	}
	//******************************************************************************************************************** */

	handleClose() {
		this.setState({ show: false, boutonEnvoyerVisible: false }, () => {
			this.props.history.push('/');
		});
	}
	//******************************************************************************************************************** */

	handleShow(errorMessage) {
		if (!this.state.success) {
			if (errorMessage) {
				this.setState({ messageRetour: errorMessage });
			} else {
				this.setState({
					messageRetour:
						"Votre inscription n'a pas été validée en raison d'un problème technique, merci de réessayer plus tard ou de nous contacter"
				});
			}
		}
		this.setState({ show: true });
	}

	//******************************************************************************************************************** */

	handleChoixCamp = (event, camp) => {
		//************************************************************ */
		// choix du camp
		if (event.target.name === 'choixDuCampDuProgramme') {
			const jourArrive = camp.dateDebut.replace('T00:00:00.000+00:00', '');
			const jourDepart = camp.dateFin.replace('T00:00:00.000+00:00', '');
			this.setState(
				{
					choixDuCampDuProgramme: camp.idCamp,
					campSelected: camp,
					jourArrive: jourArrive,
					jourDepart: jourDepart
				},
				() => {
					this.checkDatas();
				}
			);
		}

		//************************************************************ */
	};

	//******************************************************************************************************************** */

	handleChange = event => {
		let target = event.target;
		let value = target.value;
		let action = target.dataset.parse;
		let id = target.id;

		//********************************************************* */
		// choix du type de camp
		if (id === 'idcampProgramme') {
			this.setState(
				{
					choixTypeCamp: 'campProgramme',
					choixCampSpecial: '',
					sejourHorsProgrammeDateDebut: '',
					sejourHorsProgrammeDateFin: ''
				},
				() => {
					this.showDatasForDebug();
				}
			);
		}
		if (id === 'idcampSpecial') {
			this.setState(
				{
					choixTypeCamp: 'campSpecial',
					choixDuCampDuProgramme: ''
				},
				() => {
					this.showDatasForDebug();
				}
			);
		}
		if (id === 'idhorsCamp') {
			this.setState(
				{
					choixTypeCamp: 'horsCamp',
					choixDuCampDuProgramme: '',
					choixCampSpecial: ''
				},
				() => {
					this.showDatasForDebug();
				}
			);
		}
		//************************************************************ */
		// choix du camp special
		if (id === 'idcampTravail') {
			this.setState(
				{
					choixCampSpecial: 'Camp de travail'
				},
				() => {
					this.showDatasForDebug();
				}
			);
		}
		if (id === 'idweekMiss') {
			this.setState(
				{
					choixCampSpecial: 'Weekend missionnaire'
				},
				() => {
					this.showDatasForDebug();
				}
			);
		}
		if (id === 'idsemFormation') {
			this.setState(
				{
					choixCampSpecial: 'Semaine de formation'
				},
				() => {
					this.showDatasForDebug();
				}
			);
		}

		//************************************************************ */
		// chargement du jour et départ pour un camp special
		if (this.state.choixTypeCamp === 'campSpecial') {
			if (
				this.state.sejourHorsProgrammeDateDebutValid &&
				this.state.jourArrive === ''
			) {
				this.setState(
					{
						jourArrive: this.state.sejourHorsProgrammeDateDebut
					},
					() => {
						this.showDatasForDebug();
					}
				);
			}
			if (
				this.state.sejourHorsProgrammeDateDebutValid &&
				this.state.jourDepart === ''
			) {
				this.setState(
					{
						jourDepart: this.state.sejourHorsProgrammeDateFin
					},
					() => {
						this.showDatasForDebug();
					}
				);
			}
		}

		//************************************************************ */
		// choix moyen de transport

		if (id === 'idvoyageVoiture') {
			this.setState(
				{
					moyenTransport: 'voiture'
				},
				() => {
					this.showDatasForDebug();
				}
			);
		}
		if (id === 'idvoyageTrain') {
			this.setState(
				{
					moyenTransport: 'train'
				},
				() => {
					this.showDatasForDebug();
				}
			);
		}
		if (id === 'idvoyageAutre') {
			this.setState(
				{
					moyenTransport: 'autre'
				},
				() => {
					this.showDatasForDebug();
				}
			);
		}
		//************************************************************ */
		// voiture ou pas

		if (id === 'idjaiVoiture') {
			this.setState(
				{
					disposeVoiturePendantSejour: true
				},
				() => {
					this.checkDatas();
				}
			);
		}
		if (id === 'idjaiPasVoiture') {
			this.setState(
				{
					disposeVoiturePendantSejour: false
				},
				() => {
					this.checkDatas();
				}
			);
		}

		//****************************************************************** */
		// tente et couchage

		if (id === 'idpasTente') {
			this.setState(
				{
					japporteUneTente: false
				},
				() => {
					this.showDatasForDebug();
				}
			);
		}
		if (id === 'idjaporteTente') {
			this.setState(
				{
					japporteUneTente: true
				},
				() => {
					this.showDatasForDebug();
				}
			);
		}

		if (id === 'idcouchage') {
			this.setState(
				{
					desireCouchage: true
				},
				() => {
					this.showDatasForDebug();
				}
			);
		}
		if (id === 'idpasCouchage') {
			this.setState(
				{
					desireCouchage: false
				},
				() => {
					this.showDatasForDebug();
				}
			);
		}

		//****************************************************************** */
		// La mission doit me loger oui ou non
		if (id === 'idlaMissionDoitMeLoger') {
			this.setState(
				{ laMissionDoitMeLoger: true, precisionLieuLogementExterieur: '' },
				() => {
					this.checkDatas();
				}
			);
		}
		if (id === 'iddejaPrevuLogement') {
			this.setState({ laMissionDoitMeLoger: false }, () => {
				this.checkDatas();
			});
		}
		//******************************************************************* */
		if (id === 'idjaccepteChezHabitant') {
			this.setState(
				{ accepteLogerChezHabitant: true, raisonPasChezHabitant: '' },
				() => {
					this.checkDatas();
				}
			);
		}
		if (id === 'idjacceptePasChezHabitant') {
			this.setState({ accepteLogerChezHabitant: false }, () => {
				this.checkDatas();
			});
		}
		//%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
		if (id === 'idjaccepteTente') {
			this.setState({ jaccepteLogerSousTente: true }, () => {
				this.showDatasForDebug();
			});
		}
		if (id === 'idjeNacceptePasSousTente') {
			this.setState(
				{ jaccepteLogerSousTente: false, japporteUneTente: false },
				() => {
					this.showDatasForDebug();
				}
			);
		}
		//%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
		// CGU
		if (id === 'idAcceptationCGU') {
			console.log(event.target);
			this.setState({ jaccepteCGU: event.target.checked }, () => {
				this.checkDatas();
			});
		}
		//%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
	};
	//******************************************************************************************************************** */

	handleSubmit = event => {
		event.preventDefault();

		//this.setState({ boutonEnvoyerVisible: false });

		// var message = {};
		// console.log(data.entries());
		// for (var entry of data.entries()) {
		// 	message[entry[0]] = entry[1];
		// 	console.log(entry[0] + ' => ' + entry[1]);
		// }
		// message = { ...message, lieu: this.state.lieu };
		// message = JSON.stringify(message);
		// console.log(message);
		this.sendInscription(this.getInscriptionJsonObject());
	};

	sendInscription = message => {
		fetch(`${api.timgestUrl}/public/createInscriptionV2`, {
			//fetch(`http://127.0.0.1:8080/timgest/public/createInscriptionV2`, {
			method: 'POST',
			body: message,
			headers: api.inscriptionHeaders
		})
			.then(response => {
				if (response.status !== 200) {
					console.log('Error: ' + response.status);
					return;
				}
				response.json().then(data => {
					this.setState({ success: data.success });
					this.handleShow(data.errorMessage);
				});
			})
			.catch(err => {
				// this.setState({ loading: false, error: true });
				console.log(err);
			});
	};
	//******************************************************************************************************************** */

	//******************************************************************************************************************** */

	//@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
	// @@@ AFFICHAGE CAMP

	listeCamps() {
		return (
			<>
				<div className="mb-3">
					{this.state.choixDuCampDuProgramme === '' && (
						<div className="mb-3 text-center">
							<span
								className="text-white pl-2 pr-2"
								style={{ backgroundColor: '#b01125' }}
							>
								<strong>Vous devez sélectionner un camp !</strong>
							</span>
						</div>
					)}
					{this.state.camps.map(camp => (
						<AfficheUnCamp camp={camp} key={camp.idCamp} self={this} />
					))}
				</div>
			</>
		);
	}

	//@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@

	// ***************************************************************************************************************************
	// ******* ENFANTS ********************************************************************************************************************
	addEnfant() {
		let numEnfant = this.state.listeEnfants.length; // Récupère le nombre d'enfants dans le tableau
		let myArray = this.state.listeEnfants;
		let accompagnant = {
			num: numEnfant + 1,
			civilite: 'M',
			nom: '',
			prenom: '',
			dateNaissance: '',
			lieuNaissance: '',
			isValid: false
		};
		myArray.push(accompagnant); // On ajoute l'accompagnant au tableau
		this.setState(
			{
				listeEnfants: myArray
			},
			() => {}
		);
	}

	listeEnfants() {
		return (
			<>
				<h2 className="pb-3 pt-3">ENFANTS</h2>
				{this.state.listeEnfants.length > 0 &&
					this.state.listeEnfants.map(elem => (
						<Accompagnant
							key={elem.num}
							liste="listeEnfants"
							self={this}
							numAccompagnant={elem.num}
						/>
					))}
				<button
					type="button"
					onClick={this.addEnfant.bind(this)}
					className="btn btn-secondary center-block"
				>
					Ajouter un enfant
				</button>
			</>
		);
	}

	// ***************************************************************************************************************************
	// ******* ADULTES ********************************************************************************************************************

	addAdulte() {
		let numAccompagnant = this.state.listeAdultes.length; // Récupère le nombre d'enfants dans le tableau
		let myArray = this.state.listeAdultes;
		let accompagnant = {
			num: numAccompagnant + 1,
			civilite: 'M',
			nom: '',
			prenom: '',
			dateNaissance: '',
			lieuNaissance: '',
			isValid: false
		};
		myArray.push(accompagnant); // On ajoute l'accompagnant au tableau
		this.setState(
			{
				listeAdultes: myArray
			},
			() => {}
		);
	}

	listeAdultes() {
		return (
			<>
				<h2 className="pb-3 pt-3 text-uppercase">Adultes</h2>
				{this.state.listeAdultes.length > 0 &&
					this.state.listeAdultes.map(elem => (
						<Accompagnant
							key={'adulte' + elem.num}
							liste="listeAdultes"
							self={this}
							numAccompagnant={elem.num}
						/>
					))}
				<button
					type="button"
					onClick={this.addAdulte.bind(this)}
					className="btn btn-secondary center-block"
				>
					Ajouter un adulte
				</button>
			</>
		);
	}

	// ***************************************************************************************************************************

	pasEnVoiture() {
		if (
			this.state.moyenTransport !== 'voiture' &&
			this.props.lieu === 'anduze'
		) {
			return (
				<div className="form-group col-md-6">
					<h5 className="text-grey2 mb-3">
						La Mission assurera uniquement les trajets entre Alès et Anduze de
						9h à 21h
					</h5>
				</div>
			);
		}
	}

	isAffichageJourArriveEtDepart = () => {
		if (
			this.state.choixTypeCamp === 'campSpecial' ||
			this.state.choixTypeCamp === 'horsCamp' ||
			(this.state.choixTypeCamp === 'campProgramme' &&
				this.state.choixDuCampDuProgramme !== '')
		) {
			return true;
		} else {
			return false;
		}
	};
	//#########################################################################################################################################
	//#########################################################################################################################################
	//#########################################################################################################################################
	//#########################################################################################################################################
	//#########################################################################################################################################
	page1 = () => {
		// Choix du camp
		return (
			<>
				<h2 className="pb-3 pt-5 text-white">CAMPS</h2>
				<div className="mb-3">
					<Checkbox
						message={
							this.state.camps.length === 0 &&
							"Il n'y a pas d'inscriptions disponibles pour le moment"
						}
						disabled={this.state.camps.length > 0 ? false : true}
						nomGroupe="typeCamp"
						nom="campProgramme"
						self={this}
						required={true}
						defaultChecked={true}
						label="Je participe à un camp du programme"
					/>
					<Checkbox
						nomGroupe="typeCamp"
						nom="campSpecial"
						self={this}
						required={true}
						defaultChecked={false}
						label="Je participe à un camp spécial"
					/>
					<Checkbox
						nomGroupe="typeCamp"
						nom="horsCamp"
						self={this}
						required={true}
						defaultChecked={false}
						label="Je viens hors camp"
					/>
				</div>

				{this.state.choixTypeCamp === 'campProgramme' &&
					this.state.camps.length > 0 && (
						<>
							<h3 className="pb-3 pt-3 text-grey2">
								VOUS PARTICIPEZ À UN CAMP DU PROGRAMME
							</h3>
							{this.listeCamps()}
						</>
					)}
				{this.state.choixTypeCamp === 'campSpecial' && (
					<>
						<h3 className="pb-3 pt-3 text-grey2">
							VOUS PARTICIPEZ À UN CAMP SPÉCIAL
						</h3>
						<div className="mb-3">
							<Checkbox
								nomGroupe="choixCampSpecial"
								nom="campTravail"
								self={this}
								required={true}
								defaultChecked={true}
								label="Camp de travail"
							/>
							<Checkbox
								nomGroupe="choixCampSpecial"
								nom="weekMiss"
								self={this}
								required={true}
								defaultChecked={false}
								label="Weekend missionnaire"
							/>
							<Checkbox
								nomGroupe="choixCampSpecial"
								nom="semFormation"
								self={this}
								required={true}
								defaultChecked={false}
								label="Semaine de formation"
							/>
						</div>
					</>
				)}
				{this.state.choixTypeCamp === 'campSpecial' && (
					<div className="form-row">
						<InputText
							min={Moment().format('YYYY-MM-DD')}
							nom="sejourHorsProgrammeDateDebut"
							self={this}
							required={true}
							valCol="6"
							type="date"
							mask="date"
							label="du"
							placeholder="AAAA-MM-JJ"
							couleurFond="bg-grey2"
						/>
						<InputText
							min={Moment().format('YYYY-MM-DD')}
							nom="sejourHorsProgrammeDateFin"
							self={this}
							required={true}
							valCol="6"
							type="date"
							mask="date"
							label="au"
							placeholder="AAAA-MM-JJ"
							couleurFond="bg-grey2"
						/>
					</div>
				)}
				{this.isAffichageJourArriveEtDepart() && (
					<>
						<h3 className="pb-3 pt-3 text-grey2">
							JOUR D'ARRIVÉE ET DÉPART DU SÉJOUR
						</h3>
						<div className="form-row">
							<InputText
								min={Moment().format('YYYY-MM-DD')}
								nom="jourArrive"
								self={this}
								required={true}
								valCol="6"
								type="date"
								mask="date"
								label="Jour d'arrivée"
								placeholder="AAAA-MM-JJ"
								couleurFond="bg-grey2"
							/>
							<InputText
								nom="heureArrive"
								self={this}
								required={true}
								valCol="6"
								type="time"
								mask="time"
								label="Heure"
								placeholder="hh:mm"
								couleurFond="bg-grey2"
							/>
						</div>
						<div className="form-row">
							<InputText
								min={Moment(
									this.state.jourArrive !== '' ? this.state.jourArrive : ''
								).format('YYYY-MM-DD')}
								nom="jourDepart"
								self={this}
								required={true}
								valCol="6"
								type="date"
								mask="date"
								label="Jour du départ"
								placeholder="AAAA-MM-JJ"
								couleurFond="bg-grey2"
							/>
							<InputText
								nom="heureDepart"
								self={this}
								required={true}
								valCol="6"
								type="time"
								mask="time"
								label="Heure"
								placeholder="hh:mm"
								couleurFond="bg-grey2"
							/>
						</div>
					</>
				)}

				<h3 className="pb-3 pt-3 text-grey2">MOYEN DE TRANSPORT</h3>
				<div className="form-row">
					<div className="form-group col-md-6">
						<Checkbox
							nomGroupe="moyenTransport"
							nom="voyageVoiture"
							self={this}
							required={true}
							defaultChecked={true}
							label="Voiture"
						/>
						<Checkbox
							nomGroupe="moyenTransport"
							nom="voyageTrain"
							self={this}
							required={true}
							defaultChecked={false}
							label="Train"
						/>
						<Checkbox
							nomGroupe="moyenTransport"
							nom="voyageAutre"
							self={this}
							required={true}
							defaultChecked={false}
							label="Autre moyen de transport"
						/>
					</div>
					{this.pasEnVoiture()}
				</div>
				{this.state.disposeVoiturePendantSejour === '' && (
					<div className="mb-3 text-center">
						<span
							className="text-white pl-2 pr-2"
							style={{ backgroundColor: '#b01125' }}
						>
							<strong>Vous devez cocher une option !</strong>
						</span>
					</div>
				)}
				<div className="form-row">
					<div className="form-group col-md-6">
						<Checkbox
							nomGroupe="disposeVoiture"
							nom="jaiVoiture"
							self={this}
							required={true}
							defaultChecked={false}
							label="Je dispose d'une voiture personnelle pendant mon séjour"
						/>
						<Checkbox
							nomGroupe="disposeVoiture"
							nom="jaiPasVoiture"
							self={this}
							required={true}
							defaultChecked={false}
							label="Je ne dispose pas d'une voiture personnelle pendant mon séjour"
						/>
					</div>
				</div>
			</>
		);
	};
	//%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%

	page2 = () => {
		//  choix de la composition du séjour
		return (
			<>
				<h2 className="pb-3 pt-5">INFORMATIONS</h2>

				<div className="form-row">
					<div className="form-group col-md-4">
						<CompositionSejour self={this} />
					</div>
				</div>
			</>
		);
	};

	informationsReferent = () => {
		switch (this.state.typeSejour) {
			case 'Couple avec enfant(s)':
				return 'Informations du père';
			case 'Couple sans enfants':
				return 'Informations du mari';
			case 'Mme avec enfant(s)':
				return 'Informations de la référente';
			case 'M. avec enfant(s)':
				return 'Informations du référent';
			case 'M.':
				return 'Informations du référent';
			case 'Mme':
				return 'Informations de la référente';
			case 'Mlle':
				return 'Informations de la référente';
		}
	};

	//%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
	page3 = () => {
		// Informations du référent
		return (
			<>
				<h2 className="pb-3 pt-3 text-uppercase">
					{this.informationsReferent()}
				</h2>
				{this.state.mmeReferente && (
					<div className="form-row">
						<InputText
							nom="nomJeuneFille"
							self={this}
							required={true}
							valCol="6"
							mask="uppercase"
							type="text"
							label="Nom de jeune fille"
						/>
					</div>
				)}
				<div className="form-row">
					{!this.state.mlle && (
						<InputText
							nom="nom"
							self={this}
							required={true}
							mask="uppercase"
							valCol="6"
							type="text"
							label="Nom"
						/>
					)}
					<InputText
						nom="prenom"
						self={this}
						required={true}
						valCol="6"
						type="text"
						label="Prénom"
					/>
				</div>
				<div className="form-row">
					<InputText
						min={'1912-01-01'}
						max={Moment()
							.subtract(13, 'years')
							.format('YYYY-MM-DD')}
						nom="dateNaissance"
						self={this}
						required={true}
						valCol="6"
						type="date"
						label={this.state.mmeReferente ? 'Née le' : 'Né le'}
					/>
					<InputText
						nom="lieuNaissance"
						self={this}
						required={true}
						valCol="6"
						type="text"
						mask="uppercase"
						label="à"
						placeholder="Lieu de naissance"
					/>
				</div>
				<div className="form-row">
					<InputText
						nom="email"
						self={this}
						required={true}
						valCol="6"
						type="email"
						mask="email"
						label="Email"
						placeholder="Adresse email"
					/>
					<InputText
						nom="adressePostale"
						self={this}
						required={true}
						valCol="6"
						type="text"
						label="Adresse"
						placeholder="1234 Rue..."
					/>
				</div>
				<div className="form-row">
					<InputText
						nom="ville"
						self={this}
						required={true}
						valCol="6"
						type="text"
						label="Ville"
						mask="uppercase"
						placeholder="Ville"
					/>
					<InputText
						nom="pays"
						self={this}
						required={true}
						valCol="4"
						type="text"
						mask="uppercase"
						label="Pays"
						placeholder="Pays"
					/>
					<InputText
						nom="codePostal"
						self={this}
						required={true}
						valCol="2"
						type="text"
						mask="uppercase"
						label="Code postal"
						placeholder="Code postal"
					/>
				</div>
				<div className="form-row">
					<InputText
						nom="telFixe"
						self={this}
						required={false}
						valCol="6"
						type="text"
						mask="tel"
						label="Fixe"
						placeholder="Tél fixe"
					/>
					<InputText
						nom="telMobile"
						self={this}
						required={true}
						valCol="6"
						type="text"
						mask="tel"
						label="Mobile"
						placeholder="Tél mobile"
					/>
				</div>
			</>
		);
	};

	//%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
	page4 = () => {
		// Informations du conjoint
		return (
			<>
				{this.state.conjoint && (
					<>
						<h2 className="pb-3 pt-3">CONJOINT</h2>

						<div className="form-row">
							<InputText
								nom="nomConjoint"
								self={this}
								required={true}
								valCol="6"
								type="text"
								mask="uppercase"
								label="Nom"
								placeholder="Nom"
							/>
							<InputText
								nom="prenomConjoint"
								self={this}
								required={true}
								valCol="6"
								type="text"
								mask="text"
								label="Prénom"
								placeholder="Prénom"
							/>
						</div>
						<div className="form-row">
							<InputText
								nom="nomJeuneFille"
								self={this}
								required={true}
								valCol="6"
								type="text"
								mask="uppercase"
								label="Nom de jeune fille"
								placeholder="Nom de jeune fille"
							/>
						</div>
						<div className="form-row">
							<InputText
								min={'1912-01-01'}
								max={Moment()
									.subtract(13, 'years')
									.format('YYYY-MM-DD')}
								nom="dateNaissanceConjoint"
								self={this}
								required={true}
								valCol="6"
								type="date"
								label={'Née le'}
							/>
							<InputText
								nom="lieuNaissanceConjoint"
								self={this}
								required={true}
								valCol="6"
								type="text"
								mask="uppercase"
								label="à"
								placeholder="Lieu de naissance"
							/>
						</div>
						<div className="form-row">
							<InputText
								nom="emailConjoint"
								self={this}
								required={true}
								valCol="6"
								type="email"
								mask="email"
								label="Email"
								placeholder="Adresse email"
							/>
							<InputText
								nom="telMobileConjoint"
								self={this}
								required={true}
								valCol="6"
								type="text"
								mask="tel"
								label="Mobile"
								placeholder="Tél mobile"
							/>
						</div>
					</>
				)}
			</>
		);
	};
	//%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%

	page5 = () => {
		// Ajouts d'enfants et d'adultes
		return (
			<>
				{this.state.avecEnfants && this.listeEnfants()}
				{this.listeAdultes()}
			</>
		);
	};

	//%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
	page6 = () => {
		// logement et materiel
		return (
			<>
				<h2 className="pb-3 pt-5 text-white">LOGEMENT ET MATÉRIEL</h2>
				<h3 className="pb-3">LOGEMENT</h3>
				<div className="mb-3">
					<Checkbox
						nomGroupe="missionLogeOuPas"
						nom="laMissionDoitMeLoger"
						self={this}
						required={true}
						defaultChecked={true}
						label="Je demande à la Mission de me loger"
					/>
					<Checkbox
						nomGroupe="missionLogeOuPas"
						nom="dejaPrevuLogement"
						self={this}
						required={true}
						defaultChecked={false}
						label="J'ai déjà prévu un logement"
					/>
				</div>
				{!this.state.laMissionDoitMeLoger && (
					<>
						<div className="form-row mb-3">
							<InputText
								nom="precisionLieuLogementExterieur"
								self={this}
								required={true}
								valCol="6"
								type="text"
								mask="text"
								label="Précision lieu"
								placeholder="Précision"
							/>
						</div>
					</>
				)}

				{this.state.laMissionDoitMeLoger && (
					<>
						<div className="mb-3">
							<Checkbox
								nomGroupe="logeSousTenteOuPas"
								nom="jaccepteTente"
								self={this}
								required={true}
								defaultChecked={true}
								label="J'accepte d'être logé sous tente"
							/>
							<Checkbox
								nomGroupe="logeSousTenteOuPas"
								nom="jeNacceptePasSousTente"
								self={this}
								required={true}
								defaultChecked={false}
								label="Je n'accepte pas d'être logé sous tente"
							/>
						</div>
						<div className="mb-3">
							<Checkbox
								nomGroupe="logeChezHabitantOuiNon"
								nom="jaccepteChezHabitant"
								self={this}
								required={true}
								defaultChecked={true}
								label="J'accepte d'être logé chez l'habitant"
							/>
							<Checkbox
								nomGroupe="logeChezHabitantOuiNon"
								nom="jacceptePasChezHabitant"
								self={this}
								required={true}
								defaultChecked={false}
								label="Je n'accepte pas d'être logé chez l'habitant"
							/>
						</div>

						{!this.state.accepteLogerChezHabitant && (
							<div className="form-row">
								<InputText
									nom="raisonPasChezHabitant"
									self={this}
									required={true}
									valCol="6"
									type="text"
									mask="text"
									label="Raison"
									placeholder="Raison"
								/>
							</div>
						)}
						<h3 className="pb-3 pt-3">MATÉRIEL</h3>
						{this.state.jaccepteLogerSousTente && (
							<>
								<div className="mb-3">
									<Checkbox
										nomGroupe="apporteUneTente"
										nom="pasTente"
										self={this}
										required={true}
										defaultChecked={true}
										label="Je n'ai pas de tente"
									/>
									<Checkbox
										nomGroupe="apporteUneTente"
										nom="japorteTente"
										self={this}
										required={true}
										defaultChecked={false}
										label="J'apporterai ma tente"
									/>
								</div>
							</>
						)}
						<div className="mb-3">
							<Checkbox
								nomGroupe="prevoirUnCouchage"
								nom="couchage"
								self={this}
								required={true}
								defaultChecked={true}
								label="Je désire un matériel de couchage"
							/>
							<Checkbox
								nomGroupe="prevoirUnCouchage"
								nom="pasCouchage"
								self={this}
								required={true}
								defaultChecked={false}
								label="J'apporterai mon matériel de couchage"
							/>
						</div>
					</>
				)}
				<TextArea label="Commentaires" nom="commentaire" self={this} />

				<div className="form-check pt-2">
					<input
						checked={this.state.jaccepteCGU}
						type="checkbox"
						className="form-check-input"
						id="idAcceptationCGU"
						onChange={this.handleChange}
					/>
					<label
						className="form-check-label text-white label-cgu"
						htmlFor="idAcceptationCGU"
					>
						En validant votre inscription, vous acceptez les conditions
						générales d'utilisation et la déclaration sur la protection des
						donnés de
						<a
							target="_blank"
							href="https://www.dropbox.com/s/w5bnz37qeegapsm/0dc08e7979479f52b1632b394968b8f832bdcb53.pdf?raw=1"
						>
							{' '}
							Timothée Accueil et Jeunesse{' '}
						</a>
						ainsi que ses associations partenaires :
						<a
							target="_blank"
							href="https://www.dropbox.com/s/1i2amm1awgle16k/01d64ed3ed13c7e282c676ca62f34b7fc71a9c7d.pdf?raw=1"
						>
							{' '}
							association Retim,{' '}
						</a>
						<a
							target="_blank"
							href="https://www.dropbox.com/s/ced8oqmrb8v0j1k/61eed2083cc8953085cc1e4714258f2220ba5bc3.pdf?raw=1"
						>
							{' '}
							les ateliers de Pelissou{' '}
						</a>
						et
						<a
							target="_blank"
							href="https://www.dropbox.com/s/52bdsfnl6v485de/639f2f52cfa58f2f5e56e82801bf31419993efea.pdf?raw=1"
						>
							{' '}
							Teamétud{' '}
						</a>
					</label>
				</div>
			</>
		);
	};
	//$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$
	// Envoyer le formulaire

	//$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$

	navigateur = () => {
		return (
			<>
				<div className="pt-5 pb-5">
					<div className="row">
						<div className="col-md-5 text-left pb-3">
							{this.state.pageCourante > 1 && (
								<button
									onClick={event => {
										event.preventDefault();
										let page = this.state.pageCourante;
										page--;
										if (
											(this.state.typeSejour === 'Mme' ||
												this.state.typeSejour === 'M.' ||
												this.state.typeSejour === 'Mlle' ||
												this.state.typeSejour === 'Mme avec enfant(s)' ||
												this.state.typeSejour === 'M. avec enfant(s)') &&
											page === 4
										) {
											page--;
										}
										let pageSuivanteVisible = true;
										if (page == 2) {
											pageSuivanteVisible = true;
										} else {
											pageSuivanteVisible = false;
										}
										if (page == 1) {
											this.setState({
												choixDuCampDuProgramme: ''
											});
										}
										this.setState(
											{
												couleurFondBloc: getCouleur(page),
												pageCourante: page,
												pageSuivanteVisible: pageSuivanteVisible
											},
											() => {
												window.scrollTo(0, 450);
												this.checkDatas();
											}
										);
									}}
									className="btn btn-primary center-block pl-5 pr-5"
								>
									Précédent
								</button>
							)}
						</div>

						<div className="col-md-2"></div>

						<div className="col-md-5 text-right">
							{this.state.pageCourante < 6 && (
								<button
									type="submit"
									onClick={event => {
										event.preventDefault();
										let page = this.state.pageCourante;
										page++;
										if (
											(this.state.typeSejour === 'Mme' ||
												this.state.typeSejour === 'M.' ||
												this.state.typeSejour === 'Mlle' ||
												this.state.typeSejour === 'Mme avec enfant(s)' ||
												this.state.typeSejour === 'M. avec enfant(s)') &&
											page === 4
										) {
											page++;
										}

										let pageSuivanteVisible = true;
										if (page === 5 || page === 1) {
											pageSuivanteVisible = false;
										}
										if (page === 2) {
											pageSuivanteVisible = true;
										}
										if (page === 6) {
											this.setState({
												boutonEnvoyerVisible: false
											});
										}
										this.setState(
											{
												couleurFondBloc: getCouleur(page),
												pageCourante: page,
												pageSuivanteVisible: pageSuivanteVisible
											},
											() => {
												window.scrollTo(0, 450);
												this.checkDatas();
												this.showDatasForDebug();
											}
										);
									}}
									className="btn btn-primary text-right pl-5 pr-5"
									hidden={!this.state.pageSuivanteVisible}
								>
									Suivant
								</button>
							)}

							{this.state.pageCourante == 6 && (
								<button
									onClick={this.handleSubmit.bind()}
									hidden={!this.state.boutonEnvoyerVisible}
									className="btn btn-primary text-right pl-5 pr-5"
								>
									{this.state.envoiEnCours && (
										<span
											class="spinner-border spinner-border-sm mr-2"
											role="status"
											aria-hidden="true"
										></span>
									)}
									Envoyer la demande d'inscription
								</button>
							)}
						</div>
					</div>
				</div>
			</>
		);
	};

	//#########################################################################################################################################
	//#########################################################################################################################################
	//#########################################################################################################################################
	//#########################################################################################################################################
	render() {
		const { t, titre } = this.props;
		const BoiteDialogueCovid = () => (
			<Modal
				show={this.state.showCovid}
				onHide={() => this.setState({ showCovid: false })}
			>
				<Modal.Body>
					<button
						type="button"
						class="close"
						data-dismiss="modal"
						aria-label="Close"
						onClick={() => this.setState({ showCovid: false })}
					>
						<span aria-hidden="true">&times;</span>
					</button>
					<h3 className="text-center">
						En raison du contexte sanitaire, nous recevrons aux repas seulement
						les personnes logeant sur place.
						<br />
						Les réunions restent ouvertes aux personnes hébergées dans la
						région.
					</h3>
				</Modal.Body>
			</Modal>
		);
		return (
			<>
				<div className="container">
					<BoiteDialogueCovid />
				</div>
				<div className="container-fluid bg-gris1">
					<div className="" style={{ marginBottom: '-10px' }}>
						{tools.titreSousTitre('DEMANDE D’INSCRIPTION', titre)}
					</div>
					<p className="text-center pb-5">
						Pour une compatibilité garantie, veuillez utiliser Google Chrome
						pour remplir ce formulaire.
						<br />
						Merci de votre compréhension.
					</p>
				</div>
				<div
					className={'container-fluid pb-5 ' + this.state.couleurFondBloc} //
					style={{ minHeight: '480px', marginTop: '-30px' }}
				>
					<div className="container">
						<form className="needs-validation">
							{this.state.pageCourante == 1 && this.page1()} {/* bloc camps */}
							{this.state.pageCourante == 2 && this.page2()}{' '}
							{/* configuration sejour */}
							{this.state.pageCourante == 3 && this.page3()}{' '}
							{/* information personne */}
							{this.state.pageCourante == 4 && this.page4()}{' '}
							{/* informations conjoint */}
							{this.state.pageCourante == 5 && this.page5()}{' '}
							{/* enfants et adultes */}
							{this.state.pageCourante == 6 && this.page6()}{' '}
							{/* bloc logement et materiel + validation */}
							{this.navigateur()}
						</form>
					</div>
				</div>
				{/* </form> */}

				<Modal show={this.state.show} onHide={this.handleClose}>
					<Modal.Header closeButton>
						<Modal.Title>Demande d'inscription au camp</Modal.Title>
					</Modal.Header>
					<Modal.Body>{this.state.messageRetour}</Modal.Body>
					<Modal.Footer>
						<Button variant="primary" onClick={this.handleClose}>
							Fermer
						</Button>
					</Modal.Footer>
				</Modal>
			</>
		);
	}
}

export default InscriptionCamp;

const AfficheUnCamp = props => {
	return (
		<>
			<div className="form-check">
				<input
					className="form-check-input"
					type="radio"
					onChange={e => props.self.handleChoixCamp(e, props.camp)}
					name="choixDuCampDuProgramme"
					id={props.camp.idCamp}
					defaultValue={props.camp.idCamp}
					required
					disabled={props.camp.complet == 1 && 'true'}
				/>
				<label
					className="form-check-label text-white"
					htmlFor={props.camp.idCamp}
				>
					<AfficheLabelCamp camp={props.camp} />
				</label>
			</div>
		</>
	);
};

const AfficheLabelCamp = props => {
	return (
		<>
			{props.camp.fkCodeType.libelle} du{' '}
			{Moment(props.camp.dateDebut).format('Do MMMM YYYY')} au{' '}
			{Moment(props.camp.dateFin).format('Do MMMM YYYY')}{' '}
			{props.camp.complet == 1 && (
				<span
					className="text-white pl-2 pr-2"
					style={{ backgroundColor: '#b01125' }}
				>
					<strong> Ce camp est complet </strong>
				</span>
			)}
			{props.camp.delaiDepasse == 1 && (
				<span
					className="text-white pl-2 pr-2 ml-2"
					style={{ backgroundColor: 'rgb(218, 157, 76)' }}
				>
					<strong>
						délais dépassé - les nouvelles inscriptions seront sur liste
						d'attente
					</strong>
				</span>
			)}
		</>
	);
};

const getCouleur = page => {
	switch (page) {
		case 1:
			return 'bg-green3';
		case 2:
			return 'bg-gris1';
		case 3:
			return 'bg-gris1';
		case 4:
			return 'bg-gris1';
		case 5:
			return 'bg-gris1';
		case 6:
			return 'bg-blue-fonce';
		default:
			return 'bg-gris1';
	}
};
