// Libraries
import React from 'react';
import {
	BrowserRouter as Router,
	Switch,
	Route,
	Redirect
} from 'react-router-dom';
import $ from 'jquery';
import Footer from './Footer/Footer';
import BarrePolitique from './PolitiqueConfidentialite/BarrePolitique';
import HeaderAndRoute from './HeaderAndRoute';
import Home from './Home';
import Login from './Login';
import Contact from './Contact';
import PolitiqueConfidentialite from './PolitiqueConfidentialite/PolitiqueConfidentialite';
import InscriptionCamp from './InscriptionCamp';
import ProgrammeColos from './ProgrammeColos';
import PrincipeColos from './PrincipeColos';
import PrincipeCamps from './PrincipeCamps';
import InscriptionEquipeColos from './InscriptionEquipeColos';
import PresentationAccueil from './PresentationAccueil/PresentationAccueil';
import CentreAccueilAnduze from './CentreAccueil/CentreAccueilAnduze';
import CentreAccueilBulgarie from './CentreAccueil/CentreAccueilBulgarie';
import CentreAccueilFouday from './CentreAccueil/CentreAccueilFouday';
import Orphelinat from './CentreAccueil/Orphelinat';
import SoutienScolaire from './SoutienScolaire';
import Handicap from './Handicap';
import CentreAccueilMadagascar from './CentreAccueil/CentreAccueilMadagascar';
import CentreAccueilRiffray from './CentreAccueil/CentreAccueilRiffray';
import CentreAccueilMarseille from './CentreAccueil/CentreAccueilMarseille';
import InscriptionColo from './InscriptionColo';
import '@mitim/react-mitim/dist/styles.css';

// CSS
import '../styles/App.scss';
import '../styles/Font.scss';

$(document).ready(function() {
	/* ---------------------------------------------- /*
		* Scroll top
		/* ---------------------------------------------- */

	$(window).scroll(function() {
		if ($(this).scrollTop() > 100) {
			$('.scroll-up').fadeIn();
		} else {
			$('.scroll-up').fadeOut();
		}
	});

	$('a[href="#totop"]').click(function() {
		$('html, body').animate(
			{
				scrollTop: 0
			},
			'slow'
		);
		return false;
	});
});

const SLIDE = [
	{
		num: 0,
		nom: 'Page accueil',
		template: 1,
		image: 'Accueil-site.jpg',
		icone: 'icone_maison.svg',
		h1: "Page d'accueil",
		h2: 'Œuvre protestante',
		h3: 'Fédération entraide protestante',
		descriptionMeta:
			"L’association Timothée Accueil et Jeunesse a pour but d'apporter, selon le principe de charité chrétienne, une aide morale ou matérielle à ceux qui en ont besoin. Elle organise également des séjours pour les familles ainsi que des activités de jeunesse : sorties, camps et colonies."
	},
	{
		num: 1,
		nom: 'Maison accueil Marseille',
		template: 2,
		image: 'Maison-acc-marseille.jpg',
		icone: 'icone_maison.svg',
		h1: "Maison d'accueil",
		h2: "Maison d'accueil en France",
		h3: 'Marseille - Sud',
		descriptionMeta:
			"Le Foyer « le Phare » a pour but de permettre aux jeunes étudiants de trouver une famille spirituelle. C'est un lieu d'accueil pour les croyants et pour tous ceux qui cherchent Dieu."
	}
];

export default class App extends React.Component {
	render() {
		return (
			<Router>
				<div className="App">
					<Switch>
						{/* DIVERS *************************************************** */}
						{/* cookies */}
						<HeaderAndRoute
							path="/cookies"
							component={PolitiqueConfidentialite}
							slide={{
								template: 2,
								image: 'Contact.jpg',
								icone: 'icone_contactlettres.svg',
								h1: 'Politique de confidentialité'
							}}
						/>

						{/* ******************************************************************* */}

						{/* MAISONS D'ACCUEILS ***************************************************************** */}

						{/* Presentation Maisons d'accueil */}
						<HeaderAndRoute
							path="/presentation-maisons-d-accueil"
							component={PresentationAccueil}
							slide={{
								template: 2,
								image: 'Nos-maisons-d-accueil-fce-etranger.jpg',
								icone: 'icone_maison.svg',
								h1: "Nos maisons d'accueil",
								h2: 'France et étranger',
								h3: 'France, Bulgarie, Afrique',
								descriptionMeta:
									'Les maisons accueillent toute personne en difficulté spirituelle, morale ou matérielle. Des réunions quotidiennes permettent de découvrir la Bible et l’expression de la foi par des cantiques d’assemblée.'
							}}
						/>
						{/* Anduze */}
						<HeaderAndRoute
							path="/centre-accueil-anduze"
							component={CentreAccueilAnduze}
							metaTagKeyword="metatag.keywords-home"
							slide={{
								template: 2,
								image: 'Maison-accueil-anduze.jpg',
								icone: 'icone_maison.svg',
								h1: "Maison d'accueil",
								h2: "Maison d'accueil en France",
								h3: 'Anduze - Sud',
								descriptionMeta:
									"La maison d'Anduze organise des camps de familles et de jeunes, et accueille toute l’année des personnes en difficulté spirituelle, morale ou matérielle. C'est également un centre de formation biblique."
							}}
						/>
						{/* Marseille */}
						<HeaderAndRoute
							path="/centre-accueil-marseille"
							component={CentreAccueilMarseille}
							metaTagKeyword="metatag.keywords-home"
							slide={SLIDE[1]}
						/>
						{/* Riffray */}
						<HeaderAndRoute
							path="/centre-accueil-riffray"
							component={CentreAccueilRiffray}
							metaTagKeyword="metatag.keywords-home"
							slide={{
								template: 2,
								image: 'Maison-acc-riffray.jpg',
								icone: 'icone_maison.svg',
								h1: "Maison d'accueil",
								h2: "Maison d'accueil en France",
								h3: 'Riffray - Ouest',
								descriptionMeta:
									"La maison du Riffray accueille toute l'année des personnes aux besoins divers. Elle organise des camps de familles plusieurs fois par an et est aussi le lieu de rassemblement d’Eglises locales."
							}}
						/>
						{/* Fouday */}
						<HeaderAndRoute
							path="/centre-accueil-fouday"
							component={CentreAccueilFouday}
							metaTagKeyword="metatag.keywords-home"
							slide={{
								template: 2,
								image: 'Maison-acc-fouday.jpg',
								icone: 'icone_maison.svg',
								h1: "Maison d'accueil",
								h2: "Maison d'accueil en France",
								h3: 'Fouday - Est',
								descriptionMeta:
									"En Alsace, la maison d'accueil de Fouday pratique l'hospitalité dans un cadre familial. La Bible y est méditée quotidiennement."
							}}
						/>
						{/* Bulgarie */}
						<HeaderAndRoute
							path="/centre-accueil-bulgarie"
							component={CentreAccueilBulgarie}
							titre="MAISON D'ACCUEIL"
							metaTagKeyword="metatag.keywords-home"
							slide={{
								template: 2,
								image: 'Maison-acc-bulgarie.jpg',
								icone: 'icone_maison.svg',
								h1: "Maison d'accueil",
								h2: "Maison d'accueil à l'étranger",
								h3: 'Slivovic - Bulgarie',
								descriptionMeta:
									'La maison de Slivovic, en Bulgarie, accueille des personnes en situation de détresse, notamment des ressortissants de prison ayant besoin d’un soutien en vue d’une réinsertion.'
							}}
						/>
						{/* madagascar */}
						<HeaderAndRoute
							path="/centre-accueil-madagascar"
							component={CentreAccueilMadagascar}
							metaTagKeyword="metatag.keywords-home"
							slide={{
								template: 2,
								image: 'Maison-acc-mada.jpg',
								icone: 'icone_maison.svg',
								h1: "Maison d'accueil",
								h2: "Maison d'accueil à l'étranger",
								h3: 'Le Cep - Madagascar',
								descriptionMeta:
									'A Tananarive, le CEP (Centre d’Ecoute et de Partage) accueille depuis 2009 des personnes en difficulté morale ou matérielle, dans une perspective chrétienne.'
							}}
						/>

						{/* ************************************************************************************ */}

						{/* CAMPS ************************************************************************************ */}

						{/* Principes camps */}
						<HeaderAndRoute
							path="/principes-camps"
							component={PrincipeCamps}
							metaTagKeyword="metatag.keywords-home"
							slide={{
								template: 2,
								image: 'Camps-bibliques.jpg',
								icone: 'icone_maison.svg',
								h1: 'Camps Bibliques',
								h2: '',
								h3: 'Séjours de vacances en maison chrétienne',
								descriptionMeta:
									'Nos camps proposent un temps de repos dans une ambiance chrétienne, durant les périodes de congés scolaires. Des rencontres autour de la Bible sont organisées et une large part est réservée au chant.'
							}}
						/>

						{/* inscription Anduze */}
						<HeaderAndRoute
							path="/inscription-camp/anduze"
							render={props => (
								<InscriptionCamp
									{...props}
									titre="Formulaire pour Anduze"
									lieu="anduze"
								/>
							)}
							metaTagKeyword="metatag.keywords-home"
							slide={{
								template: 2,
								image: 'inscription-camp-anduze2.jpg',
								icone: 'icone_maison.svg',
								h1: 'Inscription',
								h2: 'camp à Anduze',
								h3: 'Languedoc (30)',
								descriptionMeta:
									"Demande d'inscription pour effectuer un camp dans la maison d'Anduze."
							}}
						/>

						{/* inscription Riffray */}
						<HeaderAndRoute
							path="/inscription-camp/riffray"
							render={props => (
								<InscriptionCamp
									{...props}
									titre="Formulaire pour le Riffray"
									lieu="riffray"
								/>
							)}
							metaTagKeyword="metatag.keywords-home"
							slide={{
								template: 2,
								image: 'inscription-camp-riffray.jpg',
								icone: 'icone_maison.svg',
								h1: 'Inscription',
								h2: 'camp au Riffray',
								h3: 'Bretagne (35)',
								descriptionMeta:
									"Demande d'inscription pour effectuer un camp dans la maison du Riffray."
							}}
						/>

						{/* inscription Fouday */}
						<HeaderAndRoute
							path="/inscription-camp/fouday"
							render={props => (
								<InscriptionCamp
									{...props}
									titre="Formulaire pour Fouday"
									lieu="fouday"
								/>
							)}
							metaTagKeyword="metatag.keywords-home"
							slide={{
								template: 2,
								image: 'fouday.jpg',
								icone: 'icone_maison.svg',
								h1: 'Inscription',
								h2: 'Camp à Fouday',
								h3: 'Grand Est (67)',
								descriptionMeta:
									"Demande d'inscription pour effectuer un camp dans la maison de Fouday."
							}}
						/>

						{/* *************************************************************************************************** */}
						{/* COLONIES ********************************************/}

						{/* PRINCIPES COLOS */}
						<HeaderAndRoute
							path="/principes-camps-ados-colos"
							//slide={SLIDE[0]}
							component={PrincipeColos}
							titre="COLONIES & CAMPS D'ADOS"
							metaTagKeyword="metatag.keywords-home"
							slide={{
								template: 3,
								slideBackgroud: 'video',
								image: 'Camps-colonies2.jpg',
								icone: 'icone_tente.svg',
								h1: 'Colonies et camps d’ados',
								h2: 'Cévennes & Auvergne',
								h3: 'Principes des colonies',
								descriptionMeta:
									'L’association Timothée Accueil et Jeunesse organise des camps et colonies en Auvergne et en Cévennes pendant tout l’été.'
							}}
						/>
						{/* INSCRIPTION COLOS */}
						<HeaderAndRoute
							path="/inscription-camps-ados-colos"
							//slide={SLIDE[0]}
							component={InscriptionColo}
							titre="COLONIES & CAMPS D'ADOS"
							metaTagKeyword="metatag.keywords-home"
							slide={{
								template: 3,
								slideBackgroud: 'video',
								image: 'Camps-colonies2.jpg',
								icone: 'icone_tente.svg',
								h1: 'Colonies et camps d’ados',
								h2: 'Cévennes & Auvergne',
								h3: 'Principes des colonies',
								descriptionMeta:
									'L’association Timothée Accueil et Jeunesse organise des camps et colonies en Auvergne et en Cévennes pendant tout l’été.'
							}}
						/>
						{/* programme COLOS */}
						{/* <HeaderAndRoute
							path="/programmes-camps-ados-colos"
							component={ProgrammeColos}
							titre="COLONIES & CAMPS D'ADOS"
							metaTagKeyword="metatag.keywords-home"
							slide={{
								template: 2,
								image: 'Camps-colonies2.jpg',
								icone: 'icone_tente.svg',
								h1: 'Colonies et camps d’ados',
								h2: 'Cévennes | Auvergne',
								h3: 'Principes des colonies',
								descriptionMeta: ''
							}}
						/> */}

						{/* inscription equipe */}
						<HeaderAndRoute
							path="/inscription-ados-colos-equipe"
							//slide={SLIDE[0]}
							component={InscriptionEquipeColos}
							titre="COLONIES & CAMPS D'ADOS"
							metaTagKeyword="metatag.keywords-home"
							slide={{
								template: 2,
								image: 'faire-partie-de-lequipe-encadrante.jpg',
								icone: 'icone_tente.svg',
								h1: 'Faire partie de l’équipe',
								h2: 'Cévennes | Auvergne',
								h3: 'Informations & formulaire',
								descriptionMeta:
									'Ceux qui désirent intégrer les équipes d’encadrement sont les bienvenus. Ils doivent au préalable lire le projet pédagogique et pouvoir témoigner d’un engagement chrétien.'
							}}
						/>

						{/* ************************************************************ */}

						{/* SOUTIENT SCOLAIRE */}
						<HeaderAndRoute
							path="/soutien-scolaire"
							component={SoutienScolaire}
							titre="SOUTIEN SCOLAIRE"
							metaTagKeyword="metatag.keywords-home"
							slide={{
								template: 2,
								image: 'Soutien-scolaire.jpg',
								icone: 'icone_soutienscolaire.svg',
								h1: 'Soutien Scolaire',
								h2: 'Décrochage & handicap',
								h3: 'Aide personnalisée',
								descriptionMeta:
									'Depuis la rentrée 2018, l’association a fondé une école privée spécialisée, à but non lucratif et à destination des enfants en décrochage scolaire pour des raisons sociales, pour cause de handicap mental ou de troubles « DYS ».'
							}}
						/>
						{/* HANDAP */}
						<HeaderAndRoute
							path="/handicap"
							component={Handicap}
							metaTagKeyword="metatag.keywords-home"
							slide={{
								template: 2,
								image: 'Handicap-ferme-pelissou.jpg',
								icone: 'icone_handicap.svg',
								h1: 'Handicap',
								h2: '',
								h3: 'Accueil et séjours',
								descriptionMeta:
									'L’association Timothée Accueil et Jeunesse développe un accueil personnalisé au service des personnes en situation de handicap et de leur famille.'
							}}
						/>
						{/* ORPHELAT */}
						<HeaderAndRoute
							path="/orphelinat"
							slide={{
								num: 2,
								nom: 'Orphelinat',
								template: 2,
								image: 'orphelinat.jpg',
								icone: 'icone_orphelinat.svg',
								h1: 'Orphelinat',
								h2: 'CENTRE ESAÏE 58',
								h3: 'Guinée Conakry',
								descriptionMeta:
									"Timothée Accueil et Jeunesse soutient le travail de l'association AAEG (Aide Aux Enfants de Guinée), créée en 2005 par le pasteur Daniel Tolno dans le but de venir en aide et d’annoncer l’Evangile aux enfants délaissés des rues de son pays."
							}}
							component={Orphelinat}
							metaTagKeyword="metatag.keywords-home"
						/>
						{/* CONTACT */}
						<HeaderAndRoute
							path="/contact"
							component={Contact}
							slide={{
								template: 2,
								image: 'Contact.jpg',
								icone: 'icone_contactlettres.svg',
								h1: 'Contact',
								descriptionMeta:
									'Pour nous contacter, veuillez renseigner le formulaire ci-dessous.'
							}}
						/>
						{/* HOME */}
						<HeaderAndRoute path="/" slide={SLIDE[0]} component={Home} />
					</Switch>
					<Footer />
					<BarrePolitique />
					<div className="scroll-up">
						<a href="#totop">
							<i className="fa fa-angle-double-up" />
						</a>
					</div>
				</div>
			</Router>
		);
	}
}
