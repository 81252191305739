import React, { Component } from 'react';

export class CiviliteAccompagnant extends Component {
	state = {
		val: ''
	};

	componentDidMount() {}

	handleChange = event => {
		let target = event.target;
		let value = target.value;
		this.props.self.setState(
			{
				[this.props.nomJson]: value
			},
			() => {
				this.props.self.updateDatas();
			}
		);
	};

	render() {
		return (
			<div className={'form-group col-md-' + this.props.numCol}>
				{this.props.self.props.numAccompagnant === 1 && (
					<label
						htmlFor="inputTitre"
						className="col-form-label pl-3 pr-3 mb-2 text-white bg-green text-uppercase"
					>
						{this.props.label}
					</label>
				)}
				<select
					onChange={this.handleChange}
					className="form-control"
					value={this.props.self.state[this.props.nomJson] || ''}
					id={'id' + this.props.nom}
				>
					<option defaultValue>M</option>
					<option>Mme</option>
					<option>Mlle</option>
				</select>
			</div>
		);
	}
}
