import React from 'react';
import ContactForm from './ContactForm';
import $ from 'jquery';
import ModuleMaisonAccueil from '../ModuleMaisonAccueil/ModuleMaisonAccueil';
import { CONTACTS } from '../CentreAccueil/Commun.js';

export default class Contact extends React.Component {
	constructor(props) {
		super(props);
		this.state = { lienMap: '' };
		// $('html,body').scrollTop(0);
		$('html, body').animate(
			{
				scrollTop: 0
			},
			'slow'
		);
	}

	contenu() {
		return (
			<div className="container">
				<div className="">
					<ContactForm />
				</div>
			</div>
		);
	}

	render() {
		return (
			<>
				<ModuleMaisonAccueil
					titre="Nous contacter"
					sousTitre=""
					contact={CONTACTS[7]}
					contenu={this.contenu()}
				/>
			</>
		);
	}
}
