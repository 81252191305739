import React from 'react';
import { withTranslation } from 'react-i18next';
import Page from '../Page';
import ProgrammeCamps from '../ProgrammeCamps/ProgrammeCamps';
import {
	ModuleEncartList,
	tools,
	ModuleBlocGaucheDroite
} from '@mitim/react-mitim';
import iconeCarteFrance from '../../resources/icons/carte_france_3jaunes.svg';

const ELEMENTS_ENCARTS = [
	{
		titre: 'INSCRIPTIONS ANDUZE',
		imgSrc: 'Terrasse.jpg',
		linkTo: '/inscription-camp/anduze',
		lireLaSuiteHidden: true
	},
	{
		titre: 'INSCRIPTIONS RIFFRAY',
		imgSrc: 'Inscription-Riffray-cuisine.jpg',
		linkTo: '/inscription-camp/riffray',
		lireLaSuiteHidden: true
	},
	{
		titre: 'INSCRIPTIONS FOUDAY',
		imgSrc: 'inscription-photo-fouday.jpg',
		linkTo: '/inscription-camp/fouday',
		lireLaSuiteHidden: true
	}
];

class PrincipeCamps extends Page {
	constructor(props) {
		super(props);
	}

	render() {
		const { t } = this.props;
		return (
			<>
				<div className="container p-lg-5 p-2">
					<div className="text-center">
						<video
							controls
							autoplay="true"
							muted
							style={{ marginLeft: 'auto', marginRight: 'auto', width: '100%' }}
						>
							<source
								src="https://www.dropbox.com/s/d0pb96pev2ii7lk/d872ebb78721e965132cac27f4510cb39d84b243.mp4?raw=1"
								type="video/mp4"
							/>
							Sorry, your browser doesn't support embedded videos.
						</video>
					</div>
					<div className="p-lg-0 pl-3 pr-3">
						{tools.titreSousTitre(
							'PRINCIPES DES CAMPS',
							'Repos et enseignement'
						)}
						<p>
							Nos camps ont pour but d’offrir à tous un temps de repos et de
							ressourcement dans une ambiance chrétienne. Ils ont lieu durant
							les périodes de congés scolaires. Des rencontres autour de la
							Bible sont organisées le matin et le soir, une large part est
							réservée au chant. Les après-midis sont libres, parfois des
							activités sont proposées (balades, visites, baignades, jeux,
							sports, etc.)
						</p>
						<p>
							Les logements étant souvent limités sur place, des familles
							habitant aux alentours proposent d’héberger des campeurs pour la
							durée du camp. Au moment de l’inscription, il est possible de
							préciser ses préférences au sujet du logement et l’équipe
							s’efforcera d’y répondre au mieux des possibilités.
						</p>
						<p>
							Pour permettre l’accès au plus grand nombre, nous n’avons pas fixé
							de tarif réglementaire. Chacun participe aux frais de séjour en
							toute liberté, selon ses possibilités et sa conscience. Nous
							croyons dans ce principe d’égalité qui se trouve dans la Bible : «
							le surplus des uns pourvoira aux besoins des autres » (2 Cor 8 :
							13-14). Un tronc, placé dans la salle à manger, recueillera les
							dons de chaque participant. Au moment de l’inscription, le
							règlement intérieur de la maison d’accueil devra être consulté et
							approuvé.
						</p>
					</div>
				</div>
				<div className="container-fluid bg-gris1 pt-5 pb-5">
					<ModuleBlocGaucheDroite
						data={{
							num: 0,
							textPosition: 'gauche',
							imgSrc: 'Lieu des camps.jpg',
							iconSrc: iconeCarteFrance,
							titre: 'LES LIEUX DE CAMPS',
							sousTitre: 'pour en savoir plus',
							boutons: [
								{
									nom: "Maison d'Anduze",
									lien: '/centre-accueil-anduze'
								},
								{
									nom: 'Maison du Riffray',
									lien: '/centre-accueil-riffray'
								},
								{
									nom: 'Maison de Fouday',
									lien: '/centre-accueil-fouday'
								}
							]
						}}
					/>
				</div>
				<ProgrammeCamps />
				<div className="container-fluid bg-gris2 pt-5">
					<div className="container">
						<ModuleEncartList data={ELEMENTS_ENCARTS} />
					</div>
				</div>
			</>
		);
	}
}

export default withTranslation()(PrincipeCamps);
