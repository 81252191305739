import React, { Component } from 'react';
import Moment from 'moment';

export class InputText extends Component {
	componentDidMount() {
		this.check(this.props.self.state[this.props.nom]);
	}

	componentDidUpdate() {
		if (
			!this.props.self.state[this.props.nom + 'Valid'] &&
			this.props.self.state[this.props.nom].length > 1 &&
			this.props.mask !== 'tel' &&
			this.props.mask !== 'email'
		) {
			this.setState({ updated: true }, () => {
				this.check(this.props.self.state[this.props.nom]);
			});
		}
	}

	state = {
		isValid: false,
		messageInvalid: 'Vous devez remplir ce champ',
		messageValid: 'Cela semble bon!',
		updated: false
	};

	handleChange = event => {
		let val = event.target.value;
		this.check(val);
	};

	check = val => {
		//val = val.trim();
		val = val.trimStart();
		const mask = this.props.mask || '';
		let type = this.props.type;
		if (mask == 'tel') {
			var regex = /^\+?[0-9 +]+$|^$/;
			val = maskTel(val);
			val = regex.test(val) ? val : '';
			if (val.length > 9 && this.props.required) {
				this.props.self.setState({ [this.props.nom + 'Valid']: true });
			} else {
				this.props.self.setState({ [this.props.nom + 'Valid']: false });
			}
		}
		if (mask == 'uppercase') {
			val = val.toUpperCase();
		}
		if (mask == 'email' || mask == 'lowercase') {
			val = val.toLowerCase();
		}
		if (type == 'text' && mask != 'tel') {
			if (val.length > 1) {
				this.props.self.setState({ [this.props.nom + 'Valid']: true });
			} else {
				this.props.self.setState({ [this.props.nom + 'Valid']: false });
			}
		}
		if (type == 'date') {
			if (val.length > 7) {
				this.props.self.setState({ [this.props.nom + 'Valid']: true });
			} else {
				this.props.self.setState({ [this.props.nom + 'Valid']: false });
			}
		}
		if (type == 'time') {
			if (/^(2[0-3]|[0-1]?[\d]):[0-5][\d]$/.test(val)) {
				this.props.self.setState({ [this.props.nom + 'Valid']: true });
			} else {
				this.props.self.setState({ [this.props.nom + 'Valid']: false });
			}
		}
		if (type == 'email') {
			if (val.length > 1) {
				if (val.includes('@') && val.includes('.') && checkEmail(val)) {
					this.setState({
						isValid: true,
						messageValid: 'votre e-mail semble correct'
					});
					this.props.self.setState({ [this.props.nom + 'Valid']: true });
				} else {
					this.props.self.setState({ [this.props.nom + 'Valid']: false });
					this.setState({
						messageInvalid: "l'e-mail ne semble pas correct !",
						isValid: false
					});
				}
			} else {
				this.props.self.setState({ [this.props.nom + 'Valid']: false });
			}
		}
		this.props.self.setState(
			{
				[this.props.nom]: val,
				updated: false
			},
			() => {
				this.props.self.checkDatas();
			}
		);
	};

	isValidForClass = () => {
		if (this.props.required) {
			if (this.props.self.state[this.props.nom + 'Valid']) {
				return 'is-valid';
			} else {
				return 'is-invalid';
			}
		} else {
			return '';
		}
	};

	render() {
		return (
			<>
				<div className={'form-group col-md-' + this.props.valCol || '6'}>
					<label
						htmlFor={'id' + this.props.nom}
						className={
							'col-form-label pl-3 pr-3 mb-2 text-white text-uppercase ' +
							(this.props.couleurFond || 'bg-green')
						}
					>
						{this.props.label || this.props.nom}
					</label>
					<input
						type={this.props.type || 'text'}
						className={'form-control ' + this.isValidForClass()}
						id={'id' + this.props.nom}
						placeholder={
							(this.props.placeholder || this.props.label) +
							(this.props.required ? '*' : '')
						}
						onChange={this.handleChange}
						required={this.props.required || false}
						value={this.props.self.state[this.props.nom]}
						min={this.props.min || ''}
						max={this.props.max || ''}
						onKeyPress={e => {
							if (window.event.keyCode === 13) {
								// Si on appuie sur la touche entrée on ignore
								e.preventDefault();
							}
						}}
					/>
					{this.props.self[this.props.nom + 'Valid'] &&
						(this.props.required || false) && (
							<div className="valid-feedback">{this.state.messageValid}</div>
						)}
					{!this.props.self[this.props.nom + 'Valid'] &&
						(this.props.required || false) && (
							<div className="invalid-feedback">
								{this.state.messageInvalid}
							</div>
						)}
				</div>
			</>
		);
	}
}

const checkEmail = emailAddress => {
	var sQtext = '[^\\x0d\\x22\\x5c\\x80-\\xff]';
	var sDtext = '[^\\x0d\\x5b-\\x5d\\x80-\\xff]';
	var sAtom =
		'[^\\x00-\\x20\\x22\\x28\\x29\\x2c\\x2e\\x3a-\\x3c\\x3e\\x40\\x5b-\\x5d\\x7f-\\xff]+';
	var sQuotedPair = '\\x5c[\\x00-\\x7f]';
	var sDomainLiteral = '\\x5b(' + sDtext + '|' + sQuotedPair + ')*\\x5d';
	var sQuotedString = '\\x22(' + sQtext + '|' + sQuotedPair + ')*\\x22';
	var sDomain_ref = sAtom;
	var sSubDomain = '(' + sDomain_ref + '|' + sDomainLiteral + ')';
	var sWord = '(' + sAtom + '|' + sQuotedString + ')';
	var sDomain = sSubDomain + '(\\x2e' + sSubDomain + ')*';
	var sLocalPart = sWord + '(\\x2e' + sWord + ')*';
	var sAddrSpec = sLocalPart + '\\x40' + sDomain; // complete RFC822 email address spec
	var sValidEmail = '^' + sAddrSpec + '$'; // as whole string

	var reValidEmail = new RegExp(sValidEmail);

	return reValidEmail.test(emailAddress);
};

const maskTel = val => {
	if (val === null || val.length <= 0) {
		return val;
	}

	var result = val.split(' ').join('');
	var length = result.length;
	var positions = [];
	if (length === 10) {
		positions = [2, 4, 6, 8];
	}
	var index = 0;
	if (result[0] === '+' || result.slice(0, 2) === '00') {
		positions = [];
		if (result[0] !== '+') {
			index = 1;
			positions.push(2);
		}
		var val_1_3 = result.slice(index + 1, index + 3);
		var val_1_4 = result.slice(index + 1, index + 4);
		if (val_1_3 === '33') {
			// France +33 C CC CC CC CC
			positions.push(index + 10, index + 8, index + 6, index + 4, index + 3);
		} else if (val_1_3 === '32') {
			// Belgique +32 ZZ CC CC CC
			var c2 = result.slice(index + 3, index + 5);
			positions.push(index + 9, index + 7, index + 5, index + 3);
			if (!isNaN(c2)) {
				if (c2 >= 45 && c2 <= 49) {
					// Mobile : +32 45C CC CC CC à +32 49C CC CC CC
					positions = positions.slice(1, positions.length);
					positions.push(index + 10, index + 8, index + 6, index + 3);
				}
			}
		} else if (val_1_4 === '352') {
			// Luxembourg +352 CC CCCC ou +352 CCCC CCCC ou +352 C CCCC / mobile: +352 6X1 CCC CCC
			if (result[index + 4] === '6') {
				positions.push(index + 4, index + 7, index + 10);
			} else if (val.length === index + 9) {
				positions.push(index + 4, index + 5);
			} else if (val.length === index + 10) {
				positions.push(index + 4, index + 6);
			} else if (val.length === index + 12) {
				positions.push(index + 4, index + 8);
			}
		} else if (
			val_1_3 === '41' ||
			val_1_3 === '31' ||
			val_1_3 === '48' ||
			val_1_3 === '27'
		) {
			// Suisse +41 CC CCC CC CC et Pays-Bas : +31 et Pologne : +48 et Afrique du Sud +27
			positions.push(index + 10, index + 8, index + 5, index + 3);
		} else if (val_1_4 === '377') {
			// Monaco +377 CC CC CC CC
			positions.push(index + 10, index + 8, index + 5, index + 3);
		} else if (val_1_4 === '47') {
			// Norvège +47 CC CC CC CC
			positions.push(index + 9, index + 7, index + 5, index + 3);
		} else if (val_1_4 === '351') {
			// Portugal +351 CCC CCC CCC
			positions.push(index + 9, index + 6, index + 3);
		} else if (result[index + 1] === '1') {
			// Canada / Etats-Unis +1 (CCC) CCC-CCCC
			positions.push(index + 8, index + 5, index + 2);
		} else if (val_1_4 === '359') {
			// Bulgarie +359 2 CCC CCCC
			positions.push(index + 10, index + 7, index + 4);
		} else if (val_1_3 === '49') {
			// Allemagne
			positions.push(index + 3);
		}
	}
	for (var i in positions.sort(function(a, b) {
		return b - a;
	})) {
		var pos = positions[i];
		if (pos > length) {
			continue;
		}
		result = [result.slice(0, pos), ' ', result.slice(pos)].join('');
	}
	return result;
};
