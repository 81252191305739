import React, { Component } from 'react';

export class TextArea extends Component {
	componentDidMount() {}

	handleChange = event => {
		let val = event.target.value;
		val = val.trimStart();
		this.props.self.setState(
			{
				[this.props.nom]: val
			},
			() => {
				this.props.self.checkDatas();
			}
		);
	};

	render() {
		return (
			<div className={'form-group'}>
				<label
					htmlFor={'id' + this.props.nom}
					className={
						'col-form-label pl-3 pr-3 mb-2 text-white ' +
						(this.props.couleurFond || 'bg-green')
					}
				>
					{this.props.label || this.props.nom}
				</label>
				<textarea
					className={'form-control'}
					id={'id' + this.props.nom}
					onChange={this.handleChange}
					value={this.props.self.state[this.props.nom]}
					rows={3}
				/>
			</div>
		);
	}
}
