import React from 'react';
import { NavLink } from 'react-router-dom';

class DropdownSubItem extends React.Component {
	state = {
		open: false
	};

	toggleOpen = () => {
		if (!this.state.open) {
			this.props.openMenu(this);
		}
		this.setState(prevState => ({ open: !prevState.open }));
	};

	close = () => {
		this.setState({ open: false });
	};

	render() {
		return (
			<>
				<span className="container-fluid" onClick={this.toggleOpen}>
					{this.props.item.name}
				</span>
				<ul
					className={
						'flex-column align-items-begin container-fluid mt-2' +
						(this.state.open ? ' open' : '')
					}
				>
					<li className="arrow-down" />
					{this.props.item.children.map(child => (
						<li key={child.rank}>
							{child.link.startsWith('https://') ? (
								<a href={child.link}>{child.name}</a>
							) : (
								<NavLink to={child.link} onClick={this.props.click}>
									{child.name}
								</NavLink>
							)}
						</li>
					))}
				</ul>
			</>
		);
	}
}

export default DropdownSubItem;
