import React from 'react';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { api } from '@mitim/react-mitim';

class ProgrammeColos extends React.Component {
	state = {
		camp: '',
		typeCamp: ''
	};

	componentDidMount() {
		this.loadDatas();
	}

	loadDatas() {
		fetch(`${api.coloUrl}/paramsSiteTaj`, {
			method: 'GET'
		}).then(response => {
			if (response.status !== 200) {
				console.error('Error: ' + response.status);
				return;
			}
			response.json().then(json => {
				this.setState({
					data: json,
					swho: json.modal.show
				});
			});
		});
	}

	programme(lieuSaison) {
		var camp1 = [];
		var camp2 = [];
		var camp3 = [];
		var typeCamp = '';
		var typeCamp2 = '';
		var typeCamp3 = '';
		var i1 = 0;
		var i2 = 0;
		var i3 = 0;
		this.state.data &&
			this.state.data.programme.forEach(function(element) {
				// DATA.forEach(function(element) {
				if (lieuSaison === element.programme) {
					var typeCampCourant = element.type;
					if (typeCampCourant === "CAMP D'ADOLESCENTS (mixte)") {
						typeCamp = "CAMP D'ADOLESCENTS (mixte)";
						camp1.push({
							dates: element.dates,
							desc: element.desc
						});
						i1++;
					}
					if (typeCampCourant === 'COLONIE SOUS TENTE (mixte)') {
						typeCamp2 = 'COLONIE SOUS TENTE (mixte)';
						camp2.push({
							dates: element.dates,
							desc: element.desc
						});
						i2++;
					}
					if (typeCampCourant === 'COLONIE (mixte)') {
						typeCamp3 = 'COLONIE (mixte)';
						camp3.push({
							dates: element.dates,
							desc: element.desc
						});
						i3++;
					}
				}
			});

		if (i1 === 0 && i2 === 0 && i3 === 0) {
			return <td className="pasDeCamps colos"></td>;
		} else {
			return (
				<td className="camps colos">
					{i1 > 0 && (
						<>
							<div className="typeCamp pl-3 pt-2 pb-2 mb-2">
								{typeCamp}
								<br />
							</div>
							{camp1.map(item => (
								<>{this.tdOfTable(item)}</>
							))}
							<br />
						</>
					)}
					{i2 > 0 && (
						<>
							<div className="typeCamp pl-3 pt-2 pb-2 mb-2">
								{typeCamp2}
								<br />
							</div>
							{camp2.map(item => (
								<>{this.tdOfTable(item)}</>
							))}
							<br />
						</>
					)}
					{i3 > 0 && (
						<>
							<div className="typeCamp pl-3 pt-2 pb-2 mb-2">
								{typeCamp3}
								<br />
							</div>
							{camp3.map(item => (
								<>{this.tdOfTable(item)}</>
							))}
							<br />
						</>
					)}
				</td>
			);
		}
	}

	tdOfTable(item) {
		return (
			<div key={item} className="pl-3 pr-3 text-justify">
				<div dangerouslySetInnerHTML={{ __html: item.dates }} />
				<div style={{ fontSize: '0.8rem' }}>
					<i>{item.desc}</i>
				</div>
				<br />
				<br />
			</div>
		);
	}

	tableauLarge() {
		return (
			<>
				<div className="tableau-programme">
					<table
						className="pt-5 pb-5 d-none d-md-block pt-5 pb-5"
						style={{ width: '90%', marginLeft: 'auto', marginRight: 'auto' }}
					>
						<thead>
							<tr>
								<th className="ml-2 mf-2 ligne" style={{ width: '4%' }}></th>
								<th
									className="text-center lieu text-white"
									style={{ width: '48%' }}
								>
									AUVERGNE
								</th>
								<th
									className="text-center lieu text-white"
									style={{ width: '48%' }}
								>
									CEVENNES
								</th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<th className="bg-green hiver ligne">
									<div className="saisons text-center">HIVER</div>
								</th>
								{this.programme('auvergne-hiver')}
								{this.programme('cevennes-hiver')}
							</tr>
							<tr>
								<th className="bg-jaune ete ligne">
									<div className="saisons text-center">ÉTÉ</div>
								</th>
								{this.programme('auvergne-ete')}
								{this.programme('cevennes-ete')}
							</tr>
						</tbody>
					</table>
				</div>
			</>
		);
	}
	tableau() {
		return (
			<>
				<div
					className="tableau-programme"
					style={{ marginLeft: 'auto', marginRight: 'auto' }}
				>
					<table className="pt-3 pb-3 d-block sm-block d-md-none">
						<thead>
							<tr>
								<th className="bg-withe ligneResponsive"></th>
								<th className="text-center lieu text-white">AUVERGNE</th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<th className="bg-green hiver ligneResponsive">
									<div className="saisonsMobile text-center">HIVER</div>
								</th>
								{this.programme('auvergne-hiver')}
							</tr>
							<tr>
								<th className="bg-jaune ete ligneResponsive">
									<div className="saisonsMobile text-center">ÉTÉ</div>
								</th>
								{this.programme('auvergne-ete')}
							</tr>
						</tbody>
					</table>
					<table className="pt-3 pb-3 d-block sm-block d-md-none">
						<thead>
							<tr>
								<th className="bg-withe ligneResponsive"></th>
								<th className="text-center lieu text-white">CEVENNES</th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<th className="bg-green hiver ligneResponsive">
									<div className="saisonsMobile text-center">HIVER</div>
								</th>
								{this.programme('cevennes-hiver')}
							</tr>
							<tr>
								<th className="bg-jaune ete ligneResponsive">
									<div className="saisonsMobile text-center">ÉTÉ</div>
								</th>
								{this.programme('cevennes-ete')}
							</tr>
						</tbody>
					</table>
				</div>
			</>
		);
	}

	render() {
		const { t } = this.props;
		return (
			<>
				<div className="container-fluid bg-gris1 pt-5 no-gutters">
					<h1
						className="text-center contenu-h1 pt-lg-5"
						style={{ marginBottom: '-10px' }}
					>
						CALENDRIERS DES CAMPS D’ADOS ET COLONIES
					</h1>
					<div className="container">{this.tableauLarge()}</div>
				</div>
				<div className="container-fluid bg-gris1 pb-5 no-gutters no-gutters">
					<div className="container pt-5">{this.tableau()}</div>
				</div>
			</>
		);
	}
}

export default withTranslation()(ProgrammeColos);
