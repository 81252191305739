// Librairies
import React from 'react';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import {
	FormControl,
	Input,
	InputLabel,
	FormHelperText
} from '@material-ui/core';
import MaskedInput from 'react-text-mask';
import createAutoCorrectedDatePipe from 'text-mask-addons/dist/createAutoCorrectedDatePipe';
import { withTranslation } from 'react-i18next';

const CustomInput = props => {
	const { errorMessage, value } = props.state;
	const { t, required, id, label, ...rest } = props;
	return (
		<div
			className={
				'row ' + (props.center ? 'justify-content-center' : 'mx-0 my-2')
			}
		>
			<FormControl
				className="col-xl-4 col-lg-5 col-md-6 col-sm-8 col-xs-12"
				error={errorMessage ? true : false}
				required={required}
				fullWidth
			>
				<InputLabel htmlFor={id}>{label}</InputLabel>
				<Input
					id={id}
					name={id}
					value={value}
					type={props.type || 'text'}
					{...rest}
					inputProps={{
						pattern: props.pattern,
						'data-parse': props.dataparse,
						ref: props.reference
					}}
				/>
				{!props.children && (
					<FormHelperText>
						{errorMessage && errorMessage.startsWith('inscription.')
							? t(errorMessage)
							: errorMessage}
					</FormHelperText>
				)}
				{props.children}
			</FormControl>
		</div>
	);
};

const HelperTextWithIcon = props => (
	<FormHelperText error={props.error}>
		{props.error ? <CloseIcon /> : <CheckIcon />}
		{props.children}
	</FormHelperText>
);

const autoCorrectedDatePipe = createAutoCorrectedDatePipe('dd/mm/yyyy', {
	minYear: 1900,
	maxYear: 2100
});
function DateMask(props) {
	const { inputRef, ...other } = props;

	return (
		<MaskedInput
			{...other}
			ref={ref => {
				inputRef(ref ? ref.inputElement : null);
			}}
			mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
			// placeholderChar={'\u2000'}
			// showMask
			keepCharPositions
			pipe={autoCorrectedDatePipe}
		/>
	);
}

export default withTranslation()(CustomInput);
export { HelperTextWithIcon, DateMask };
