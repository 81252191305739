import React from 'react';
import { withTranslation } from 'react-i18next';
import { ModuleContact, tools } from '@mitim/react-mitim';
import ModuleGallerie from '../ModuleGallerie/ModuleGallerie';

class ModuleMaisonAccueil extends React.Component {
	render() {
		const data = this.props.data;
		const { t } = this.props;

		return (
			<>
				<div className="container-fluid">
					<div className="row">
						<ModuleContact data={this.props.contact} />
						<div className="col-xl-1"></div>
						<div className="col-xl-7">
							<div className="text-center pt-5">
								<img
									src={require(`../../resources/icons/information.svg`)}
									className="img-fluid"
									style={{ height: '90px' }}
								/>
							</div>
							{tools.titreSousTitre(this.props.titre, this.props.sousTitre)}
							<div className="ml-3 mr-3 pb-5" style={{}}>
								{this.props.contenu}
							</div>
						</div>
						<div className="col-xl-1"></div>
					</div>
				</div>

				{this.props.idGallerie && (
					<ModuleGallerie idGallerie={this.props.idGallerie} />
				)}
			</>
		);
	}
}
export default withTranslation()(ModuleMaisonAccueil);
