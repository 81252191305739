import React from 'react';
import { withTranslation } from 'react-i18next';
import Page from '../Page';
import ModuleMaisonAccueil from '../ModuleMaisonAccueil/ModuleMaisonAccueil';
import { CONTACTS } from './Commun';

class CentreAccueilBulgarie extends Page {
	constructor(props) {
		super(props);
	}

	componentDidMount() {
		super.componentDidMount();
	}

	contenu() {
		return (
			<>
				<p className="mt-4">
					Depuis 2007 l’association Timothée Accueil et Jeunesse apporte son
					concours à un travail missionnaire dans le nord ouest de la Bulgarie.
					Pour soutenir Joël et Kamelia Chiron déjà en place depuis 1993, nous
					leur avons envoyé un couple de collaborateurs. Leu apport permettra de
					démarrer un travail d'accueil dans le village de Slivovic, en faveur
					de personnes en situation de détresse, notamment des anciens détenus
					ayant besoin d'un soutien en vue de leur réinsertion.
				</p>
				<p>
					Pour ce faire, l’association{' '}
					<a className="lien" href="https://retim.org/bulgarie" target="_blank">
						RETIM
					</a>{' '}
					s’est investie pour mettre en place des activités pouvant aider ces
					personnes à avoir une source de revenu. Un atelier de couture a été
					mis en place,ainsi qu'une formation aux travaux de rénovation dans le
					bâtiment, ou encore la récolte et le conditionnement de noix pour la
					fabrication d’huile. Les conditions d’accueil sont les mêmes que dans
					nos maisons en France. Un soutien scolaire et des cours
					d’alphabétisation sont également proposés.
					<br />
					L’association Timothée Accueil et Jeunesse supervise et soutient tout
					ce travail par des aides financières et des visites régulières, afin
					de maintenir la vision de l'œuvre.
				</p>
				<h3 className="contenu-h3 pt-5 mb-5">L'équipe de Bulgarie</h3>
			</>
		);
	}
	render() {
		const { t } = this.props;

		return (
			<>
				<ModuleMaisonAccueil
					titre="Maison d'accueil"
					sousTitre="Slivovic"
					contact={CONTACTS[2]}
					idGallerie="1425"
					contenu={this.contenu()}
				/>
			</>
		);
	}
}
export default withTranslation()(CentreAccueilBulgarie);
