// Libraries
import React, { useState } from 'react';
import { withTranslation } from 'react-i18next';
import { BrowserRouter as Router, Link, Route, Switch } from 'react-router-dom';
// Component
import Page from '../Page';
import './InscriptionColo.scss';
import InscriptionPart from '@mitim/colo-react-inscription';

export default () => {
	const [debugEnable, setDebugEnable] = useState(false);
	return (
		<>
			<div className="text-center bg-grey-light">
				Ce formulaire n'est pas compatible avec Safari, <br />
				pour une compatibilité garantie vous devez utiliser Google Chrome pour
				remplir ce formulaire. Sur ipad ou iphone, vous pouvez installer google
				chrome{' '}
				<a
					target="_blank"
					href="https://apps.apple.com/fr/app/google-chrome/id535886823"
				>
					ICI
				</a>
				.
				<br />
				Merci de votre compréhension.
			</div>
			<InscriptionPart
				debugMode={debugEnable}
				rootUrl="/inscription-camps-ados-colos"
			/>
		</>
	);
};
