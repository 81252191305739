import React from 'react';
// import * as api from '../../api/api';
import { api } from '@mitim/react-mitim';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

export default class ContactForm extends React.Component {
	constructor() {
		super();
		this.handleSubmit = this.handleSubmit.bind(this);
		this.handleShow = this.handleShow.bind(this);
		this.handleClose = this.handleClose.bind(this);
	}

	state = {
		show: false,
		success: false,
		disableButton: false
	};

	handleClose = () => {
		this.setState({ success: false });
	};

	handleShow() {
		this.setState({ show: true });
	}

	resetFields() {
		document.getElementById('inputName').value = '';
		document.getElementById('inputEmail').value = '';
		document.getElementById('inputMessage').value = '';
	}

	handleSubmit(event) {
		event.preventDefault();
		const disableButton = true;
		this.setState({ disableButton });
		const data = new FormData(event.target);
		var message = {};
		for (var entry of data.entries()) {
			message[entry[0]] = entry[1];
		}
		message = JSON.stringify(message);
		api.sendMessage(message, this);
	}

	render() {
		return (
			<>
				<div className="pt-lg-5">
					<div className="formulaire">
						<form
							onSubmit={this.handleSubmit}
							name="contactform"
							method="post"
							action
							className="form-horizontal"
						>
							<div className="form-group">
								<input
									type="text"
									className="form-control"
									id="inputName"
									name="nom"
									placeholder="Votre nom*"
									required
								/>
							</div>
							<div className="form-group">
								<input
									type="email"
									className="form-control"
									id="inputEmail"
									name="adresseEmail"
									placeholder="Votre adresse email*"
									required
								/>
							</div>
							<div className="form-group">
								<textarea
									className="form-control"
									rows={4}
									id="inputMessage"
									name="message"
									placeholder="Votre message*"
									required
									defaultValue={''}
								/>
							</div>
							{/* <div
							className="g-recaptcha"
							data-sitekey="6LfqmHIUAAAAAKcNAK0KH_hUO1AzWNlh5sBTEBQe"
						/> */}
							<br />

							<button
								type="submit"
								className="btn btn-secondary"
								disabled={this.state.disableButton}
							>
								Envoyer le message
							</button>
						</form>
					</div>
				</div>

				<Modal show={this.state.success} onHide={this.handleClose}>
					<Modal.Header closeButton>
						<Modal.Title>Envoi d'un message</Modal.Title>
					</Modal.Header>
					<Modal.Body>Votre message a bien été envoyé</Modal.Body>
					<Modal.Footer>
						<Button variant="primary" onClick={this.handleClose}>
							Fermer
						</Button>
					</Modal.Footer>
				</Modal>
			</>
		);
	}
}
