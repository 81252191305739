// Libraries
import React from 'react';
import { withTranslation } from 'react-i18next';
import Page from '../Page';
import { CONTACTS } from './Commun';
import ModuleMaisonAccueil from '../ModuleMaisonAccueil/ModuleMaisonAccueil';

class CentreAccueilMarseille extends Page {
	constructor(props) {
		super(props);
	}

	componentDidMount() {
		super.componentDidMount();
	}

	contenu() {
		return (
			<>
				<p className="mt-4 text-justify">
					Le Foyer « le Phare » a été fondé à Marseille en 1946 dans le but de
					recevoir des jeunes et de leur permettre d’y trouver une famille
					spirituelle. Ce foyer devait également être un moyen d’évangélisation
					dans le quartier et plus largement sur Marseille. Le travail s’est
					développé en étant :
				</p>
				<ul className="liste mb-4">
					<li>
						un lieu d’hébergement pour les étudiants et les jeunes travailleurs
					</li>
					<li>
						un point de chute pour les missionnaires de passage et pour les
						militaires rentrant des « colonies »
					</li>
					<li>
						un lieu d’accueil pour les croyants et pour tous ceux qui cherchent
						Dieu.
					</li>
				</ul>
				<p className="text-justify">
					Ces dernières années, l’essentiel du travail d’accueil s’est recentré
					autour des étudiants, le reste étant devenu difficile faute de
					soutiens et de forces vives.
				</p>
				<p className="text-justify">
					A l’occasion du départ à la retraite du directeur du Phare, les
					membres du conseil d’administration ont sollicité l’association
					Timothée Accueil et Jeunesse, dans le cadre d’une réflexion sur la
					suite du travail. L’une des raisons principales était la volonté de
					pouvoir revenir à l’orientation initiale du Phare à destination des
					personnes en détresse.
				</p>
				<p className="text-justify">
					Nous avons été très sensibles à cette demande, certains ayant été très
					marqués dès leur jeunesse par la vocation du Phare, et d’autres ayant
					été au bénéfice de son service.
				</p>
				<p className="text-justify">
					Différentes rencontres de travail ont été organisées en 2018, puis
					l’assemblée générale du Phare a décidé le 19 janvier 2019 de confirmer
					sa volonté d’établir une collaboration entre nos deux associations.
					Elle l’a concrétisée en faisant entrer dans son assemblée générale et
					son conseil d’administration plusieurs de nos membres engagés.
				</p>
				<p className="text-justify">
					L’administration et l’animation de la maison d’accueil nous ayant été
					confiées, le travail de la nouvelle équipe a commencé lors de la
					rentrée scolaire 2019- 2020, autour d’un couple missionnaire qui en
					assure la direction dans une ambiance chaleureuse et familiale. La vie
					de la maison est marquée par le repas en commun du soir, conclu par
					une méditation biblique.
				</p>
				<ul className="liste mb-4">
					<li>
						Une dizaine de chambres restent consacrées à l’accueil des étudiants
					</li>
					<li>Une partie à l’accueil de personnes en difficulté</li>
					<li>Une partie pour les visiteurs des hôpitaux</li>
					<li>
						Des chambres sont aussi disponibles pour des pasteurs ou chrétiens
						de passage{' '}
					</li>
				</ul>

				{/* <p className="mb-5 text-justify">
					La vie de la maison s’articule autour du couple de missionnaire qui en
					assure la direction dans une ambiance chaleureuse et familiale. Elle
					est marquée par le repas en commun du soir, conclue par une méditation
					biblique.
				</p> */}
				<h3 className="contenu-h3 pt-5">L’équipe du Phare</h3>
			</>
		);
	}

	render() {
		const { t } = this.props;

		return (
			<>
				<ModuleMaisonAccueil
					titre="Maison d'accueil"
					sousTitre="Le Phare"
					contact={CONTACTS[0]}
					idGallerie="1430"
					contenu={this.contenu()}
				/>
			</>
		);
	}
}

export default withTranslation()(CentreAccueilMarseille);
