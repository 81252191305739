import React from 'react';
import Gallery from 'react-photo-gallery';
// import { getGallery } from '../../api/api';
import { api } from '@mitim/react-mitim';
import Carousel, { Modal, ModalGateway } from 'react-images';

export default class ModuleGallerie extends React.Component {
	state = {
		gallery: [],
		currentImage: 0,
		viewerIsOpen: false
	};

	openLightbox(self, event) {
		this.setState({ currentImage: event.index });
		this.setState({ viewerIsOpen: true });
	}

	closeLightbox = () => {
		this.setState({ currentImage: 0 });
		this.setState({ viewerIsOpen: false });
	};

	componentDidMount() {
		api.getGallery(this.props.idGallerie, this, () => {});
	}

	render() {
		return (
			<div className="container-fluid bg-gris1">
				<div className="container">
					<h2 className="pt-5 mb-5 text-grey2 text-center">GALERIE PHOTOS</h2>
					<Gallery
						photos={this.state.gallery}
						onClick={this.openLightbox.bind(this)}
					/>
					<ModalGateway>
						{this.state.viewerIsOpen && (
							<Modal onClose={this.closeLightbox.bind()}>
								<Carousel
									currentIndex={this.state.currentImage}
									views={this.state.gallery.map(x => ({
										...x
									}))}
								/>
							</Modal>
						)}
					</ModalGateway>
				</div>
			</div>
		);
	}
}
