// Librairies
import React from 'react';
// import $ from 'jquery';

export default class Page extends React.Component {
	componentDidMount() {
		// window.addEventListener('scroll', this.handleScroll);
		// $('html, body').animate(
		// 	{
		// 		scrollTop: 0
		// 	}
		// 	// 'slow'
		// );
		window.scrollTo(0, 0);
	}

	componentWillUnmount() {
		// window.removeEventListener('scroll', this.handleScroll);
	}

	// handleScroll(event) {}
}
