import React, { Component } from 'react';
import { CiviliteAccompagnant } from './CiviliteAccompagnant';
import { InputTextAccompagnant } from './InputTextAccompagnant';
import Moment from 'moment';

export class Accompagnant extends Component {
	componentDidMount() {}

	componentWillMount() {
		this.initDatas();
	}

	state = {
		civilite: 'M',
		nom: '',
		prenom: '',
		dateNaissance: '',
		lieuNaissance: '',
		isValid: false,
		//
		nomValid: false,
		prenomValid: false,
		dateNaissanceValid: false,
		lieuNaissanceValid: false
	};

	isEnfantOuAccompagnant = () => {
		// On détermine si l'accompagnant est un enfant ou un adulte
		if (this.props.liste === 'listeEnfants') {
			return 'enfant ' + this.props.numAccompagnant;
		}
		if (this.props.liste === 'listeAdultes') {
			return 'adulte ' + this.props.numAccompagnant;
		}
	};

	initDatas = () => {
		// On charge les States à partir du tableau (cette fonction est utilisé pour Charger les données une fois qu'on revient sur la page)
		let myArray = this.props.self.state[this.props.liste];
		const num = this.props.numAccompagnant;
		const found = myArray.find(element => element.num == num);
		if (typeof found === 'object') {
			this.setState({
				civilite: found.civilite,
				prenom: found.prenom,
				nom: found.nom,
				dateNaissance: found.dateNaissance,
				lieuNaissance: found.lieuNaissance,
				isValid: found.isValid
			});
		}
	};

	updateDatas = () => {
		// On met à jour les steaks de l'accompagnant dans le tableau
		let myArray = this.props.self.state[this.props.liste];
		const num = this.props.numAccompagnant;
		const found = myArray.find(element => element.num == num); // On récupère l'accompagnant dans le tableau

		let isValid = false;
		if (
			this.state.nomValid &&
			this.state.prenomValid &&
			this.state.dateNaissanceValid &&
			this.state.lieuNaissanceValid
		) {
			isValid = true;
			this.setState({ isValid: true });
		} else {
		}

		if (typeof found === 'object') {
			// Si l'accompagnant existe
			const index = myArray.findIndex(object => {
				// On cherche son index dans le tableau
				return object.num === num;
			});
			found.civilite = this.state.civilite;
			found.prenom = this.state.prenom;
			found.nom = this.state.nom;
			found.dateNaissance = this.state.dateNaissance;
			found.lieuNaissance = this.state.lieuNaissance;
			found.isValid = isValid;
			myArray[index] = found;
		}

		this.props.self.setState(
			{
				[this.props.liste]: myArray
			},
			() => {
				this.props.self.checkDatas(); // On appelle la fonction pour l'affichage au nom du bouton suivant
			}
		);
	};

	removeAccompagnant = () => {
		//
		let myArray = this.props.self.state[this.props.liste];
		let num = this.props.numAccompagnant;
		const index = myArray.findIndex(object => {
			return object.num === num;
		});
		myArray.splice(index, 1);
		this.props.self.setState(
			{
				[this.props.liste]: myArray
			},
			() => {}
		);
	};

	isLastAccompagnant = () => {
		// Détermine si on affiche ou non la corbeille
		let myArray = this.props.self.state[this.props.liste];
		let num = this.props.numAccompagnant;
		const index = myArray.findIndex(object => {
			return object.num === num;
		});
		if (myArray.length === index + 1) {
			if (index > 0 && this.props.liste === 'listeEnfants') {
				return true;
			} else if (this.props.liste === 'listeAdultes') {
				return true;
			} else {
				return false;
			}
		} else {
			return false;
		}
	};

	render() {
		return (
			<>
				<div className="form-row">
					<CiviliteAccompagnant
						numCol="1"
						label="Civilité"
						nomJson="civilite"
						nom={
							'civilite_acompagnant_' +
							this.props.numAccompagnant +
							'_liste_' +
							this.props.liste
						}
						self={this}
					/>
					<InputTextAccompagnant
						initVal={this.state.prenom}
						numCol="3"
						label="Prénom"
						nomJson="prenom"
						nom={
							'prenom_acompagnant_' +
							this.props.numAccompagnant +
							'_liste_' +
							this.props.liste
						}
						self={this}
						required={true}
						type="text"
						mask="text"
						placeholder={'Prénom ' + this.isEnfantOuAccompagnant()}
					/>
					<InputTextAccompagnant
						initVal={this.state.nom}
						numCol="3"
						label="Nom"
						nomJson="nom"
						nom={
							'nom_acompagnant_' +
							this.props.numAccompagnant +
							'_liste_' +
							this.props.liste
						}
						self={this}
						required={true}
						type="text"
						mask="uppercase"
						placeholder={'Nom ' + this.isEnfantOuAccompagnant()}
					/>
					<InputTextAccompagnant
						min={'1912-01-01'}
						max={Moment().format('YYYY-MM-DD')}
						initVal={this.state.dateNaissance}
						numCol="2"
						label="Né(e) le"
						nomJson="dateNaissance"
						nom={
							'dateNaissance_acompagnant_' +
							this.props.numAccompagnant +
							'_liste_' +
							this.props.liste
						}
						self={this}
						required={true}
						type="date"
						mask="date"
						placeholder={'Date de naissance ' + this.isEnfantOuAccompagnant()}
					/>
					<InputTextAccompagnant
						initVal={this.state.lieuNaissance}
						numCol="2"
						label="à"
						nomJson="lieuNaissance"
						nom={
							'lieuNaissance_acompagnant_' +
							this.props.numAccompagnant +
							'_liste_' +
							this.props.liste
						}
						self={this}
						required={true}
						type="text"
						mask="uppercase"
						placeholder={'Lieu de naissance ' + this.isEnfantOuAccompagnant()}
					/>

					{/* ***  Affichage de la corbeille *** */}
					{this.isLastAccompagnant() && (
						<div className="form-group col-md-1">
							{this.props.numAccompagnant === 1 && (
								<div style={{ height: '47px' }} />
							)}
							<div
								style={{ cursor: 'pointer' }}
								className="pt-1"
								onClick={this.removeAccompagnant.bind()}
							>
								<IconTrash couleur="rgb(188,60,60)" />
							</div>
						</div>
					)}
					{/* ****************************** */}
				</div>
			</>
		);
	}
}

const IconTrash = props => (
	<svg width={20} height={20} viewBox="0 0 256 256" xmlSpace="preserve">
		<defs></defs>
		<g
			style={{
				stroke: 'none',
				strokeWidth: 0,
				strokeDasharray: 'none',
				strokeLinecap: 'butt',
				strokeLinejoin: 'miter',
				strokeMiterlimit: 10,
				fill: 'none',
				fillRule: 'nonzero',
				opacity: 1
			}}
			transform="translate(1.4065934065934016 1.4065934065934016) scale(2.81 2.81)"
		>
			<path
				d="M 68.842 90 H 21.158 c -4.251 0 -7.696 -3.446 -7.696 -7.696 v -52.09 h 63.077 v 52.09 C 76.538 86.554 73.092 90 68.842 90 z"
				style={{
					stroke: 'none',
					strokeWidth: 1,
					strokeDasharray: 'none',
					strokeLinecap: 'butt',
					strokeLinejoin: 'miter',
					strokeMiterlimit: 10,
					fill: 'rgb(188,60,60)',
					fillRule: 'nonzero',
					opacity: 1
				}}
				transform=" matrix(1 0 0 1 0 0) "
				strokeLinecap="round"
			/>
			<path
				d="M 78.321 22.213 H 11.679 c -2.209 0 -4 -1.791 -4 -4 s 1.791 -4 4 -4 h 66.643 c 2.209 0 4 1.791 4 4 S 80.53 22.213 78.321 22.213 z"
				style={{
					stroke: 'none',
					strokeWidth: 1,
					strokeDasharray: 'none',
					strokeLinecap: 'butt',
					strokeLinejoin: 'miter',
					strokeMiterlimit: 10,
					fill: 'rgb(188,60,60)',
					fillRule: 'nonzero',
					opacity: 1
				}}
				transform=" matrix(1 0 0 1 0 0) "
				strokeLinecap="round"
			/>
			<path
				d="M 57.815 22.213 h -25.63 c -2.209 0 -4 -1.791 -4 -4 V 7.696 C 28.185 3.453 31.637 0 35.881 0 h 18.238 c 4.244 0 7.696 3.453 7.696 7.696 v 10.517 C 61.815 20.422 60.024 22.213 57.815 22.213 z M 36.185 14.213 h 17.63 V 8 h -17.63 V 14.213 z"
				style={{
					stroke: 'none',
					strokeWidth: 1,
					strokeDasharray: 'none',
					strokeLinecap: 'butt',
					strokeLinejoin: 'miter',
					strokeMiterlimit: 10,
					fill: props.couleur,
					fillRule: 'nonzero',
					opacity: 1
				}}
				transform=" matrix(1 0 0 1 0 0) "
				strokeLinecap="round"
			/>
			<path
				d="M 54.784 78.235 c -2.209 0 -4 -1.791 -4 -4 V 44.976 c 0 -2.209 1.791 -4 4 -4 s 4 1.791 4 4 v 29.259 C 58.784 76.444 56.993 78.235 54.784 78.235 z"
				style={{
					stroke: 'none',
					strokeWidth: 1,
					strokeDasharray: 'none',
					strokeLinecap: 'butt',
					strokeLinejoin: 'miter',
					strokeMiterlimit: 10,
					fill: 'rgb(255,255,255)',
					fillRule: 'nonzero',
					opacity: 1
				}}
				transform=" matrix(1 0 0 1 0 0) "
				strokeLinecap="round"
			/>
			<path
				d="M 35.216 78.235 c -2.209 0 -4 -1.791 -4 -4 V 44.976 c 0 -2.209 1.791 -4 4 -4 s 4 1.791 4 4 v 29.259 C 39.216 76.444 37.425 78.235 35.216 78.235 z"
				style={{
					stroke: 'none',
					strokeWidth: 1,
					strokeDasharray: 'none',
					strokeLinecap: 'butt',
					strokeLinejoin: 'miter',
					strokeMiterlimit: 10,
					fill: 'rgb(255,255,255)',
					fillRule: 'nonzero',
					opacity: 1
				}}
				transform=" matrix(1 0 0 1 0 0) "
				strokeLinecap="round"
			/>
		</g>
	</svg>
);
