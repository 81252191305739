import React from 'react';
import { withTranslation } from 'react-i18next';
import Page from './Page';
import ModuleGallerie from './ModuleGallerie/ModuleGallerie';
import { ModuleBlocGaucheDroite2 } from '@mitim/react-mitim';

class SoutienScolaire extends Page {
	render() {
		return (
			<>
				<div className="container-fluid bg-gris1 no-gutters">
					<ModuleBlocGaucheDroite2
						data={{
							textPosition: 'droite',
							imgSrc: 'team-etud.jpg',
							titre: 'ÉCOLE PRIVÉE TEAM’ETUD',
							sousTitre: 'Parcours individualisés',
							contenu: (
								<p>
									De nombreux enseignants, issus de l’Education Nationale et
									pour la plupart bénévoles, ont mis en place depuis plusieurs
									années une aide personnalisée aux élèves rencontrant des
									difficultés lors de leur parcours scolaire. Depuis la rentrée
									2018, l’association a fondé une école privée spécialisée, à
									but non lucratif et à destination des enfants en décrochage
									scolaire pour des raisons sociales, pour cause de handicap
									mental ou de troubles « DYS ». L’équipe pédagogique, portée
									par les valeurs de la pédagogie active, propose des parcours
									individualisés, en s’appuyant en partie sur les supports du
									CNED. L’objectif est d’autonomiser l’enfant dans ses
									apprentissages pour favoriser son retour dans le milieu
									scolaire ordinaire, ou de l’accompagner sur le chemin du
									projet professionnel qui lui correspond le mieux.
									<br />
									<br />
									Pour plus de renseignements, consulter le site de TeamEtud'.
								</p>
							),
							boutons: [
								{
									nom: 'TEAM’ETUD',
									lien: 'http://teametud.fr'
								}
							]
						}}
					/>
					<div className="container pt-5 pb-5">
						<div className="text-center">
							<div class="embed-responsive embed-responsive-16by9">
								<iframe
									class="embed-responsive-item"
									src={
										'https://www.youtube.com/embed/' + 'qeWTdMwfGHQ' + '?rel=0'
									}
									frameBorder={0}
									allowFullScreen
									modestbranding={1}
									controls={0}
									rel={0}
									info={0}
									allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
								/>
							</div>
						</div>
					</div>
				</div>
				<ModuleGallerie idGallerie={1433} />
			</>
		);
	}
}

export default SoutienScolaire;
