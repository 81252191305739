import React from 'react';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';

import iconeMaison from '../../resources/icons/icone_maison.svg';
// import anduze1 from '../../resources/photos/anduze-1.jpg';
// import anduze2 from '../../resources/photos/anduze-2.jpg';
import { tools } from '@mitim/react-mitim';

class QuiSommesNous extends React.Component {
	textContenu() {
		return (
			<>
				L’association Timothée Accueil et Jeunesse a pour but d'apporter, selon
				le principe de charité chrétienne, une aide morale ou matérielle à ceux
				qui en ont besoin. Depuis 1972, elle assure principalement la gestion de
				maisons d'accueil en France et à l’étranger, pour recevoir et prendre
				soin de ces personnes en situation de détresse (morale, matérielle et
				dépendances diverses). Cette aide peut être ponctuelle, pour un court
				séjour de repos, ou alors s’étendre à un suivi personnalisé dans le
				temps et un accompagnement à la réinsertion. L’association organise
				également des séjours pour les familles ainsi que des activités de
				jeunesse : sorties, camps et colonies.
			</>
		);
	}

	grandEcran() {
		return (
			<div className="d-none d-lg-block">
				<div
					className="mb-5"
					style={{ paddingTop: '140px', display: 'flex', paddingRight: '20%' }}
				>
					<div
						style={{
							width: 500,
							height: 400,
							flex: '0.6',
							position: 'relative',
							zIndex: '1'
						}}
					>
						<div
							className="p-2"
							style={{
								borderRadius: '20px',
								marginTop: '-60px',
								marginLeft: '30px',
								backgroundColor: '#333',
								height: '120px',
								width: '120px',
								position: 'absolute'
							}}
						>
							<img src={iconeMaison} className="img-fluid" />
						</div>
						<img
							// src={anduze1}
							src={`${tools.lienPhotos}/anduze-1.jpg`}
							className="img-fluid"
							style={{ maxHeight: '600px' }}
						/>
					</div>
					<div style={{ flex: '0.4', zIndex: '2' }}>
						<span
							className="ft-parisienne text-center"
							style={{
								paddingLeft: '20%',
								fontSize: '300%',
								color: 'rgb(173, 173, 172)'
							}}
						>
							Qui sommes-nous ?
						</span>

						<div
							style={{
								background: 'white',
								padding: '25px 50px 60px 50px',
								margin: '10px -20px 0 -20px',
								textAlign: 'justify'
							}}
							className="blocHome"
						>
							<div
								style={{
									marginLeft: '-110px',
									backgroundColor: '#f2c718',
									width: '120px',
									height: '3px'
								}}
							></div>
							{/* <h3 className="ft-lato-regular text-center pt-4">
								OBJET DE L'ASSOCIATION
							</h3>
							<h3 className="ft-parisienne pt-2 text-center">
								Œuvre protestante en Cévennes
							</h3> */}

							{tools.titreSousTitre(
								"OBJET DE L'ASSOCIATION",
								'Œuvre protestante en Cévennes'
							)}
							<p className="text-justify">{this.textContenu()}</p>
						</div>
						<div
							className="text-center text-white ft-roboto-mono"
							style={{
								background: '#f2c718',
								margin: '-50px 50px 0 50px',
								padding: 30,
								transform: 'rotate(-5deg)'
							}}
						>
							Timothée Accueil &amp; Jeunesse
							<br />
							est reconnue d'intérêt général
							<br />
							depuis 2007 et membre de
							<br />
							la Fédération d'Entraide Protestante de France
						</div>
					</div>
				</div>
			</div>
		);
	}

	mobile() {
		return (
			<>
				<div className="d-block d-lg-none">
					<div className="pt-5">
						{/* *** LE LOGO *** */}
						<div
							className="p-2"
							style={{
								borderRadius: '20px',
								backgroundColor: '#333',
								height: '120px',
								width: '120px',
								margin: '0 auto'
							}}
						>
							<img src={iconeMaison} className="img-fluid" />
						</div>

						{/* ****** */}

						{/* *** QUI SOMMES NOUS ? *** */}
						<div
							className="ft-parisienne text-center"
							style={{
								// paddingLeft: '20%',
								fontSize: '300%',
								color: 'rgb(173, 173, 172)'
							}}
						>
							Qui sommes-nous?
						</div>
						{/* ****** */}

						{/* *** IMAGE *** */}
						<img
							// src={anduze1}
							src={`${tools.lienPhotos}/anduze-1.jpg`}
							className="img-fluid"
							style={{ maxHeight: '600px' }}
						/>
						{/* ****** */}

						<div
							style={{
								background: 'white',
								padding: '25px 50px 60px 50px',
								textAlign: 'justify'
							}}
							className="blocHome"
						>
							<div
								style={{
									marginLeft: '-110px',
									backgroundColor: '#f2c718',
									width: '120px',
									height: '3px'
								}}
							></div>
							{/* <h3 className="ft-lato-regular text-center pt-4">
								OBJET DE L'ASSOCIATION
							</h3>
							<h3 className="ft-parisienne pt-2 text-center">
								Œuvre protestante en Cévennes
							</h3> */}
							{tools.titreSousTitre(
								"OBJET DE L'ASSOCIATION",
								'Œuvre protestante en Cévennes'
							)}
							<p className="text-justify pt-3">{this.textContenu()}</p>
						</div>
					</div>
				</div>
			</>
		);
	}

	render() {
		const { t } = this.props;

		return (
			<>
				{this.grandEcran()}
				{this.mobile()}
			</>
		);
	}
}

export default withTranslation()(QuiSommesNous);
