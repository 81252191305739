import React from 'react';
import { withTranslation } from 'react-i18next';
import Page from '../Page';
import { ModuleEncartList, api } from '@mitim/react-mitim';
import QuiSommesNous from './QuiSommesNous';
import './Home.scss';

import iconeMaison from '../../resources/icons/icone_maison.svg';
import iconeBible from '../../resources/icons/icone_bible.svg';
import iconeTente from '../../resources/icons/icone_tente.svg';
import iconeHandicap from '../../resources/icons/icone_handicap.svg';
import iconeAfrique from '../../resources/icons/icone_orphelinat.svg';
import iconeSoutien from '../../resources/icons/icone_soutienscolaire.svg';

const ELEMENTS_ENCARTS_1 = [
	{
		titre: 'MAISONS D’ACCUEIL',
		sousTitre: 'Hospitalité et accompagnement',
		iconSrc: iconeMaison,
		imgSrc: 'SYM_1628.jpg',
		linkTo: '/presentation-maisons-d-accueil',
		contenu:
			'Ouvertes à tous, nos maisons accueillent à l’année toute personne en difficulté, et organisent des camps pendant les vacances scolaires.'
	},
	{
		titre: 'CAMPS BIBLIQUES',
		sousTitre: 'Repos et enseignement',
		iconSrc: iconeBible,
		imgSrc: 'SYM_1605.jpg',
		linkTo: '/principes-camps',
		contenu:
			'Nos camps ont pour but d’offrir à tous un temps de repos et de ressourcement dans une ambiance chrétienne.'
	},
	{
		titre: 'COLONIES',
		sousTitre: 'Enfants et adolescents',
		iconSrc: iconeTente,
		imgSrc: 'DSC_0205.jpg',
		linkTo: '/principes-camps-ados-colos',
		contenu:
			'L’association Timothée Accueil et Jeunesse organise des camps et colonies en Auvergne et en Cévennes pendant tout l’été.'
	}
];

const ELEMENTS_ENCARTS_2 = [
	{
		titre: 'SOUTIEN SCOLAIRE',
		sousTitre: 'Aide personnalisée - École',
		iconSrc: iconeSoutien,
		imgSrc: 'element5-digital-jCIMcOpFHig-unsplash.jpg',
		linkTo: '/soutien-scolaire',
		contenu:
			'L’association Team Etud’ apporte une aide personnalisée aux élèves rencontrant des difficultés lors de leur parcours scolaire.'
	},
	{
		titre: 'HANDICAP',
		sousTitre: 'Les Ateliers de Pelissou',
		iconSrc: iconeHandicap,
		imgSrc: 'img_2117.jpg',
		linkTo: '/handicap',
		contenu:
			'Les ateliers de Pelissou proposent un accompagnement et un parcours d’insertion à de jeunes adultes, porteurs de divers handicaps.'
	},
	{
		titre: 'ORPHELINAT',
		sousTitre: 'Centre Ésaïe 58',
		iconSrc: iconeAfrique,
		imgSrc: 'enfnts pensifs.jpg',
		linkTo: '/orphelinat',
		contenu:
			'Depuis 2015, le centre Esaïe 58 a ouvert ses portes en Guinée Conakry, pour accueillir des orphelins, des délaissés et autres victimes du virus Ebola'
	}
];

class Home extends Page {
	render() {
		const { t } = this.props;

		return (
			<>
				<div
					className="container-fluid pb-lg-5 no-gutters"
					style={{ minHeight: '480px', backgroundColor: '#e2e3e4' }}
				>
					<div className="container home pt-lg-5">
						<ModuleEncartList data={ELEMENTS_ENCARTS_1} />
					</div>
				</div>
				<div
					className="container-fluid pb-lg-5 no-gutters"
					style={{ minHeight: '480px', backgroundColor: '#c9cacc' }}
				>
					<div className="text-center pt-4" style={{}}>
						<img
							src={require(`../../resources/icons/verset.svg`)}
							className="img-fluid"
							style={{ width: '40%' }}
						/>
					</div>
					<div className="container home pt-5">
						<ModuleEncartList data={ELEMENTS_ENCARTS_2} />
					</div>
				</div>
				<div
					className="container-fluid pb-5 no-gutters bg-gris1"
					style={{ minHeight: '80px' }}
				>
					<QuiSommesNous />
				</div>
			</>
		);
	}
}

export default withTranslation()(Home);
