import React from 'react';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import Page from '../Page';
import {
	ModuleBlocImagesCroise,
	ModuleBlocGaucheDroite2,
	api
} from '@mitim/react-mitim';
import ProgrammeColos from '../ProgrammeColos';
import Modal from 'react-bootstrap/Modal';

class PrincipeColos extends Page {
	constructor(props) {
		super(props);
	}

	componentDidMount() {
		super.componentDidMount();
		this.loadDatas();
	}

	loadDatas() {
		fetch(`${api.coloUrl}/paramsSiteTaj`, {
			method: 'GET'
		}).then(response => {
			if (response.status !== 200) {
				console.error('Error: ' + response.status);
				return;
			}
			response.json().then(json => {
				this.setState({
					data: json,
					show: json.modal.show
				});
			});
		});
	}

	state = {
		// show: [],
		// data:[]
	};

	render() {
		const { t } = this.props;

		const BoiteDialogue = () => (
			<Modal
				show={this.state.show}
				onHide={() => this.setState({ show: false })}
			>
				<Modal.Body>
					<button
						type="button"
						class="close"
						data-dismiss="modal"
						aria-label="Close"
						onClick={() => this.setState({ show: false })}
					>
						<span aria-hidden="true">&times;</span>
					</button>
					<h3 className="text-center">
						{this.state.data && this.state.data.modal.contenu}
					</h3>
				</Modal.Body>
			</Modal>
		);

		return (
			<>
				<div className="container-fluid no-gutters">
					<BoiteDialogue />
					<ModuleBlocImagesCroise
						data={{
							textPosition: 'droite',
							imgSrc: 'colos-1.jpg',
							titreImg: '',
							titre: 'PRINCIPES GÉNÉRAUX',
							sousTitre: '',
							contenu: (
								<>
									<p>
										L’association Timothée Accueil et Jeunesse organise des
										camps et colonies en Auvergne et en Cévennes pendant tout
										l’été. Ces séjours sont ouverts à tous les enfants et jeunes
										de 9 à 17 ans. Ils ont pour vocation de contribuer à
										l’épanouissement de la jeunesse et à son développement vers
										la maturité, à travers une vie en collectivité de qualité,
										dans un centre de vacances situé en pleine nature, et selon
										une orientation de mise en pratique des valeurs chrétiennes
										que sont le respect de l’autre et de l’environnement,
										l’amitié, l’entraide, la générosité, la tolérance.
									</p>
									<p>
										Il peut nous arriver d’accueillir un ou deux enfants avec un
										faible handicap, en fonction des compétences et des
										qualifications spécifiques du personnel encadrant la colonie
										ou le camp. Cet accueil doit au préalable être entendu avec
										le directeur du séjour.
									</p>
									<ul className="liste" style={{}}>
										<li className="">
											Nos structures sont agréées par le Ministère de la
											Cohésion Sociale, les bons de vacances sont donc acceptés.
										</li>
									</ul>
								</>
							)
						}}
					/>

					<ModuleBlocImagesCroise
						data={{
							textPosition: 'gauche',
							imgSrc: 'colos-2.jpg',
							titreImg: 'Les gorges du Tarn',
							titre: 'CAMPS & COLONIES À GRIZAC',
							sousTitre: '',
							contenu: (
								<>
									<p>
										Le centre de vacances « le gai soleil » est situé en Lozère,
										dans le petit hameau de Grizac, sur le territoire traversé
										par le chemin de Stevenson.
									</p>
									<p>
										Il offre un cadre exceptionnel à 1050m d’altitude dans le
										sud du mont Lozère à quelques kilomètre du Pont-de-Montvert
										et à 1h30 d’Anduze.
									</p>
									<p>
										Les locaux, constitués d’une ancienne ferme typique de la
										région, sont équipés pour accueillir 60 enfants. Le centre
										est entouré de nombreux terrains de jeux.
									</p>
								</>
							)
						}}
					/>
					{/* <ModuleBlocImagesCroise
						data={{
							textPosition: 'gauche',
							imgSrc: 'colos-2.jpg',
							titreImg: 'Les gorges du Tarn',
							titre: 'CAMPS & COLONIES À CAMPRIEU',
							sousTitre: '',
							contenu: (
								<>
									<p>
										L’accueil se fait dans le cadre d’un centre de loisirs avec
										hébergement, situé dans le village de St Sauveur - Camprieu
										(département du Gard).
									</p>
									<p>
										Le séjour est organisé dans un grand chalet en location
										nommé « Les Martinets ». Il dispose d’une grande salle à
										manger, d’une vaste salle d’activités, de six dortoirs,
										quatre chambres, sept douches, six W.C et de pièces
										techniques telles qu’une cuisine agréée, un bureau, une
										infirmerie, une buanderie.
									</p>
									<p>
										Le centre dispose d’un grand terrain plat de plusieurs
										hectares comprenant bois, terrains de sport et espaces
										dégagés pour les jeux et les loisirs. L’équipe d’encadrement
										est logée sous tente ou en caravane, les enfants à
										l'intérieur du bâtiment et les garçons les plus âgés sous
										tente de type « marabout ».
									</p>
									<p>
										La Mairie met à notre disposition le terrain de tennis et le
										stade. De nombreuses visites et activités agrémentent le
										séjour (visites de plusieurs grottes, observation de
										vautours, baignade à la piscine municipale, grands jeux,
										travaux manuels...)
									</p>
								</>
							)
						}}
					/> */}
					<ModuleBlocImagesCroise
						data={{
							textPosition: 'droite',
							imgSrc: 'colo-auvergne.jpg',
							titreImg: 'Le Puy de Dôme',
							titre: 'CAMPS & COLONIES À SAY',
							sousTitre: '',
							contenu: (
								<>
									<p>
										Les camps se déroulent à Say, un petit hameau auvergnat de
										60 habitants, dans un corps de ferme traditionnel avec une
										grange aménagée, de grandes tentes dortoirs sur les terrains
										voisins et une ancienne bergerie rénovée pour l’accueil. Le
										site est entouré de prairies, de collines, de volcans, de
										lacs et de forêt, invitant à la randonnée, la détente, la
										baignade...
									</p>
								</>
							)
						}}
					/>
				</div>

				<ProgrammeColos />

				<div className="container-fluid pb-5 bg-gris1 no-gutters">
					<div className="container pb-5">
						<div className="text-center pb-5">
							<video
								controls
								autoplay="true"
								muted
								loop
								preload="autobuffer"
								style={{
									marginLeft: 'auto',
									marginRight: 'auto',
									width: '100%'
								}}
							>
								<source
									src="https://www.dropbox.com/s/ipdhm7cuyhj565u/db03fe69874558c43b5b148d6d8e0d08b2d4223a.mov?raw=1"
									type="video/mp4"
								/>
								Sorry, your browser doesn't support embedded videos.
							</video>
						</div>
					</div>

					<ModuleBlocGaucheDroite2
						data={{
							textPosition: 'droite',
							imgSrc: 'photo-scindee-en-2.jpg',
							titre: 'INSCRIPTIONS',
							sousTitre: 'Notez bien',
							contenu: (
								<>
									<p>
										{this.state.data && this.state.data.inscription.contenu}
										{this.state.data &&
											this.state.data.inscription
												.afficheBoutonInscriptionCandidature && (
												<Link
													className="lien"
													to="/inscription-ados-colos-equipe"
												>
													{' '}
													ici
												</Link>
											)}
										.
									</p>
									<p className="text-center pt-3">
										<strong>Contact administratif :</strong>
										<br />
										Adresse Email SAY : colossay@gmail.com
										<br />
										Adresse Email CAMPRIEU/GRIZAC : coloscamprieu@gmail.com
										<br />
										Tél : 06 65 25 16 03
									</p>
								</>
							),

							boutons:
								(this.state.data &&
									this.state.data.inscription.boutonsInscription) ||
								[]
						}}
					/>
				</div>
			</>
		);
	}
}

export default withTranslation()(PrincipeColos);
