// Libraries
import React from 'react';
import { withTranslation } from 'react-i18next';
// Component
import Page from '../Page';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
// import { sendInscriptionEquipe, getCamps } from '../../api/api';

// @ts-ignore
import { api } from '@mitim/react-mitim';

// @ts-ignore
import { tools } from '@mitim/react-mitim';
import './InscriptionEquipeColos.scss'

class InscriptionEquipeColos extends Page {

	constructor(props: any) {
		super(props);
		this.handleShow = this.handleShow.bind(this);
	}

	state = {
		elements: {
			"checkedMoniteur": false,
			"checkedAide": false,
			"checkedAssistantSanitaire": false,
			"checkedCuisiniere": false,
			"checkedAideCamprieu": false,
			"checkedAideSay": false,
			"checkedAideIndifferent": false,
			"checkedStagiereBafa": false,
			"checkedTitulaireBafa": false,
			"checkedSecourisme": false,
			"checkedBrevetSurveillantBaignade": false,
			"checkedRienParticulier": false,
			"checkedAutre": false,
			//
			"civilite": "M.",
			"nom": "",
			"prenom": "",
			"nomJeuneFille": "",
			"dateNaissance": "",
			"lieuNaissance": "",
			"adressePostale": "",
			"ville": "",
			"pays": "",
			"codePostal": "",
			"telFixe": "",
			"telMobile": "",
			"email": "",
			"commentaire": "",
			"motivations": "",
			"contactResponsable": "",
			"precisionDiplome": "",
			"camps": []
		},
		show: false,
		mmeReferente: false,
		mlle: false,
		showPrecisionDiplome: false,
		showModal: false,
		data: null,
		envoiEnCours: false
	};

	componentDidMount() {
		super.componentDidMount();
		this.loadDatas();
		if (this.props.match.params.codeLieu === "anduze") {
			this.setState({ lieuCamp: "À ANDUZE" });
			api.getCamps("AND", this, () => { });
		}
		if (this.props.match.params.codeLieu === "riffray") {
			this.setState({ lieuCamp: "AU RIFFRAY" });
			api.getCamps("RIF", this, () => { });
		}
		if (this.props.match.params.codeLieu === "fouday") {
			this.setState({ lieuCamp: "A FOUDAY" });
			api.getCamps("FOUD", this, () => { });
		}
	}


	loadDatas(){
		fetch(`${api.coloUrl}/paramsSiteTaj`, {
			method: 'GET'
		}).then(response => {
			if (response.status !== 200) {
				console.error('Error: ' + response.status);
				return;
			}
			response.json().then(json => {
				this.setState(
					{
						data: json,
						showModal: json.modalEquipe.show
					}
				);
			});
		});
	}


	handleShow() {
		this.setState({ 
			show: true,
			envoiEnCours: false
		});
	}

	handleChange = (event: { target: { checked: any; value: any; id: any }; }) => {
		console.log(event);
		let target = event.target;
		let value = target.value;
		let id = target.id;
		console.log(event.target.checked);
		console.log(value);
		console.log(id);
		if (id === 'civilite' && value === "M.") {
			this.setState({ avecEnfants: false, conjoint: false, mmeReferente: false, mlle: false, elements: { ...this.state.elements, "civilite": value } });
		}
		if (id === 'civilite' && value === "Mme") {
			this.setState({ avecEnfants: false, conjoint: false, mmeReferente: true, mlle: false, elements: { ...this.state.elements, "civilite": value } });
		}
		if (id === 'civilite' && value === "Mlle") {
			this.setState({ avecEnfants: false, conjoint: false, mmeReferente: true, mlle: true, elements: { ...this.state.elements, "civilite": value } });
		}
	};

	handleSubmit = (event: { preventDefault: () => void; target: HTMLFormElement | undefined; }) => {
		event.preventDefault();
		this.setState({envoiEnCours: true});
		const data = this.state.elements;
		var message = {};
		console.table(data);
		message = JSON.stringify(data);
		console.log(message);
		api.sendInscriptionEquipe(message, this);
	};

	listeCamps(lieu: string) {
		return (
			<>
				<div className="mb-3">
					{/* 
					// @ts-ignore */}
					{this.state.data && this.state.data.listeCampsInscriptionEquipe.map(camp => (
						lieu === camp.lieu && (
							<>
								{this.checkBox(camp.titre + (camp.equipeComplete ? " (équipe complète)" : (camp.message === "" ? "" : (" (" + camp.message + ")"))), camp.lieu + " - " + camp.titre)}
							</>
						)
					))}
				</div>
			</>
		)
	}

	checkBox(label: React.ReactNode, name: string) {
		return (
			<div className="form-check text-white">
				{/* 
				// @ts-ignore */}
				<input type="checkbox" onChange={this.handleChangeCheckbox(name)} defaultChecked={this.state.elements[name]} className="form-check-input" id={"Id" + name} />
				<label className="form-check-label text-white" htmlFor={"Id" + name}>{label}</label>
			</div>
		)
	}

	inputText(label: string, name: string, type: string | undefined, nbrCol: string, required:boolean = true) {
		return (
			<div className={"form-group col-md-" + nbrCol}>
				<label htmlFor="nomJeuneFille" className="col-form-label pl-3 pr-3 mb-2 text-white bg-green text-uppercase">{label}</label>
				{/* 
				// @ts-ignore */}
				<input type={type} value={this.state.elements[name]} onChange={this.handleChangeText(name)} className="form-control" id={"Id" + name} placeholder={label + (required ? "*" : "")} name={name + "Name"} required={required} />
			</div >
		)
	}

	inputTextarea(label: string, name: string) {
		return (
			<div className="form-group pt-3">
				<label htmlFor={"Id" + name} className="col-form-label pl-3 pr-3 mb-2 text-white bg-green">{label}</label>
				<textarea
					className="form-control"
					id={"Id" + name}
					name={name + "Name"}
					rows={3}
					// @ts-ignore
					value={this.state.elements[name]}
					onChange={this.handleChangeText(name)}
				/>
			</div>
		)
	}


	handleChangeCheckbox = (name: string) => (event: { target: { checked: any; }; }) => {
		let elements: any = this.state.elements;
		if (name.indexOf("Camp d") > -1 || name.indexOf("Colonie du") > -1) { // si 
			if(event.target.checked){
				elements["camps"].push(name);
			} else{
				for(var i in elements["camps"]){
					if(elements["camps"][i]===name){
						elements["camps"].splice(i,1);
						break;
					}
				}
			}
			
		} else {
			elements[name] = event.target.checked;
		}
		this.setState({ elements: elements });
		if (name === "checkedAutre") {
			console.log("checkedAutre on est dedans !!!");
			this.setState({ showPrecisionDiplome: event.target.checked });
		}
		console.log(this.state.elements)
		console.log("Valeur de : " + name + " -> " + (event.target.checked ? 'true' : 'false'));
	};

	handleChangeText = (name: string) => (event: { target: { type: string; value: string; }; }) => {
		this.setState({ elements: { ...this.state.elements, [name]: event.target.value } }, () => console.log(this.state.elements));
		console.log("Valeur de : " + name + " -> " + event.target.value);
	};

	render() {
		const BoiteDialogue = () => (
			<Modal
				show={this.state.showModal}
				onHide={() => this.setState({ showModal: false })}
			>
				<Modal.Body>
					<button
						type="button"
						className="close"
						data-dismiss="modal"
						aria-label="Close"
						onClick={() => this.setState({ showModal: false })}
					>
						<span aria-hidden="true">&times;</span>
					</button>
					<h3 className="text-center">
				{/* 
				// @ts-ignore */}
						{this.state.data && this.state.data.modalEquipe.contenu}
					</h3>
				</Modal.Body>
			</Modal>
		);
		return (
			<>
				<div className="container home pb-5 pt-5 pl-4 pr-4 pl-lg-0 pr-lg-0">
					<BoiteDialogue />
					{tools.titreSousTitre("COMMENT FAIRE PARTIE DE L’ÉQUIPE ENCADRANTE ?", "Formulaire de demande d’inscription")}

					<p className="">
						Ceux qui désirent intégrer nos équipes sont les bienvenus. Ils doivent au préalable lire le projet pédagogique et pouvoir témoigner d’un engagement chrétien.
					</p>

					<p className="text-justify">
						Nous œuvrons sur la base d’un engagement bénévole :
					</p>
					<ul className="liste mb-5">
						<li>
							Est bénévole une personne qui s’engage librement pour mener une action non salariée en direction d’autrui, en dehors de son temps professionnel. Cela implique une participation aux frais d’alimentation et le logement. Le bénévolat est donc un don de soi librement consenti et gratuit.
						</li>
						<li>
							Les candidats au bénévolat reçoivent une réponse en avril, bien que les inscriptions restent ouvertes jusqu’à fin juin.
						</li>
					</ul>
				</div>


				{/* <pre>
					{JSON.stringify(this.state.elements, null, 2)}
				</pre> */}
				<form
					// @ts-ignore
					onSubmit={this.handleSubmit}
					// noValidate
					method="POST"
				>
					<div
						className="container-fluid bg-grey-light pb-5"
						style={{ minHeight: '480px' }}
					>
						<div className="container home">
							<p className="text-center pt-5">
								Ce formulaire n'est pas compatible avec Safari, pour une compatibilité garantie vous devez utiliser Google Chrome pour
								remplir ce formulaire.
								<br />
								Merci de votre compréhension.
							</p>
							<div className="">
								<h2 className="pb-3 pt-5">INFORMATIONS</h2>
								<div className="form-row">
									<div className="form-group col-md-2">
										<label htmlFor="civilite" className="col-form-label pl-3 pr-3 mb-2 text-white bg-green text-uppercase">Civilité</label>
										{/* 
										// @ts-ignore */}
										<select onChange={this.handleChange} className="form-control" name="civilite" id="civilite">
											<option>M.</option>
											<option>Mme</option>
											<option>Mlle</option>
										</select>
									</div>
									{!this.state.mlle && (
										<>{this.inputText("Nom", "nom", "text", "5")}</>
									)}
									{this.inputText("Prénom", "prenom", "text", "5")}
								</div>
								{this.state.mmeReferente && (
									<div className="form-row">
										{this.inputText("Nom de jeune fille", "nomJeuneFille", "text", "6")}
									</div>
								)}
								<div className="form-row">
									{this.inputText("Né(e) le", "dateNaissance", "date", "6")}
									{this.inputText("à", "lieuNaissance", "text", "6")}
								</div>
								<div className="form-row">
									{this.inputText("Email", "email", "email", "6")}
									{this.inputText("Adresse", "adressePostale", "text", "6")}
								</div>
								<div className="form-row">
									{this.inputText("Ville", "ville", "text", "6")}
									{this.inputText("Pays", "pays", "text", "4")}
									{this.inputText("Code postal", "codePostal", "text", "2")}
								</div>
								<div className="form-row">
									{this.inputText("Fixe", "telFixe", "number", "6", false)}
									{this.inputText("Mobile", "telMobile", "number", "6")}
								</div>
							</div>
						</div>
					</div>

					<div
						className="container-fluid bg-green3 pb-5"
						style={{ minHeight: '480px' }}
					>
						<div className="container home">
							<div className="">
								<h3 className="pt-5 text-white text-uppercase">Je suis disponible pour les camps suivants</h3>
								<p className="text-white"><i>(les moniteurs sont attendus 2 jours avant pour préparer le camp)</i></p>
								<div className="mb-3">
									<h4 className="text-grey2 text-uppercase pt-4 pb-4">En Cévennes (Camprieu/Grizac)</h4>
									{this.listeCamps("Camprieu")}
									{this.listeCamps("Grizac")}
								</div>
								<h4 className="text-grey2 text-uppercase pt-4 pb-4">En Auvergne (Say)</h4>
								{this.listeCamps("Say")}
							</div>
						</div>
					</div>
					<div
						className="container-fluid bg-blue-fonce pb-5"
						style={{ minHeight: '480px' }}
					>
						<div className="container home">
							<div className="">
								{/* <h2 className="pb-3 pt-5 text-white">LOGEMENT ET MATÉRIEL</h2> */}
								<h3 className="pb-3 pt-5">JE CANDIDATE POUR ÊTRE</h3>
								<div className="mb-3">
									{this.checkBox("Moniteur", "checkedMoniteur")}
									{this.checkBox("Aide", "checkedAide")}
									{this.checkBox("Assistant sanitaire (Infirmerie)", "checkedAssistantSanitaire")}
									{this.checkBox(this.state.elements["civilite"] === "M." ? "Cuisinier" : "Cuisinière", "checkedCuisiniere")}
								</div>
								<h3 className="pb-3 pt-3">JE PRÉFÉRERAIS AIDER</h3>
								<div className="mb-3">
									{this.checkBox("à Camprieu", "checkedAideCamprieu")}
									{this.checkBox("à Say", "checkedAideSay")}
									{this.checkBox("indifférent", "checkedAideIndifferent")}
								</div>
								<h3 className="pb-3 pt-3">QUELS DIPLÔMES AVEZ-VOUS ?</h3>
								<div className="mb-3">
									{this.checkBox("Stagiaire bafa", "checkedStagiereBafa")}
									{this.checkBox("Titulaire bafa (ou équivalent : professeur des écoles, CAP petite enfance, Licence Sciences et éducation, STAPS)", "checkedTitulaireBafa")}
									{this.checkBox("Secourisme (PSC1)", "checkedSecourisme")}
									{this.checkBox("Brevet de surveillant baignade", "checkedBrevetSurveillantBaignade")}
									{this.checkBox("Rien de particulier", "checkedRienParticulier")}
									{this.checkBox("Autre", "checkedAutre")}
								</div>

								{this.state.showPrecisionDiplome && (
									<div className="form-row mt-3">
										{this.inputText("Précisez", "precisionDiplome", "text", "6")}
									</div>
								)}
								<div className="form-row mt-3">
									{this.inputText("Quel responsable d’assemblée pouvons-nous contacter ?", "contactResponsable", "text", "6")}
								</div>
								{this.inputTextarea("En quelques mots, quelles sont vos motivations pour cette candidature ?", "motivations")}
								{this.inputTextarea("Remarques éventuelles", "commentaire")}
								<button type="submit" className="my-5 btn btn-primary center-block" disabled={this.state.envoiEnCours}>
								{this.state.envoiEnCours && (
									<span
										className="spinner-border spinner-border-sm mr-2"
										role="status"
										aria-hidden="true"
									></span>
								)}
									Envoyer la demande d'incription
								</button>
							</div>
						</div>
					</div>
				</form>

				<Modal show={this.state.show} 
					onHide={() =>
						this.setState({ show: false }, () =>
							this.props.reloadPage ? null : window.location.reload()
						)
					}
					
				>
					<Modal.Header closeButton>
						<Modal.Title>Demande d'inscription au camp</Modal.Title>
					</Modal.Header>
					<Modal.Body>Votre demande d'inscription a bien été envoyée</Modal.Body>
					<Modal.Footer>
						<Button variant="primary" 
							onClick={() =>
								this.setState({ show: false }, () =>
									this.props.reloadPage ? null : window.location.reload()
								)
							}
						>Fermer</Button>
					</Modal.Footer>
				</Modal>
			</>
		);
	}
}

export default InscriptionEquipeColos;

