import React from 'react';
import { Link } from 'react-router-dom';
import DropdownSubitem from './DropdownSubitem';

const dropdown = props => {
	let toolbarItems = props.items.map(item => (
		<li
			key={item.rank}
			className={
				(item.children ? 'submenu' : '') + ' menuitem align-items-center'
			}
		>
			{item.link &&
				(item.link.startsWith('https://') ? (
					<a href={item.link} className="container-fluid" onClick={props.click}>
						{item.name}
					</a>
				) : (
					<Link
						to={item.link}
						className="container-fluid"
						onClick={props.click}
					>
						{item.name}
					</Link>
				))}
			{item.children && (
				<DropdownSubitem
					openMenu={props.openMenu}
					dropdownOpen={props.open}
					item={item}
					click={props.click}
				/>
			)}
		</li>
	));
	let classe = 'custom-dropdown d-flex d-xl-none flex-column';
	if (props.open) {
		classe += ' open';
	}
	return <ul className={classe}>{toolbarItems}</ul>;
};

export default dropdown;
