export const CONTACTS = [
	{
		nom: 'Marseille',
		titreAdresse: 'Foyer chrétien Le Phare',
		adresse: ['131 - 133 Rue Ferrari', '13005 MARSEILLE'],
		tel: ['04 91 42 57 70'],
		mail: [],
		url: 'https://foyerlephare.com',
		lienMap:
			'https://www.google.com/maps/place/133+Rue+Ferrari,+13005+Marseille/@43.2931227,5.3904154,17z/data=!3m1!4b1!4m5!3m4!1s0x12c9c0a73fed66cb:0xed29a1935229ce57!8m2!3d43.2931227!4d5.3926041'
	},
	{
		nom: 'Anduze',
		titreAdresse: 'Timothée Accueil et Jeunesse',
		adresse: ['1654 chemin de Recoulin', '30140 ANDUZE'],
		tel: ['04 66 61 73 61'],
		mail: ['contact@missiontimothee.fr'],
		url: 'https://www.missiontimothee.fr',
		lienMap:
			'https://www.google.com/maps/place/Mission+Timoth%C3%A9e/@44.0727088,3.9700965,15z/data=!4m5!3m4!1s0x0:0x524de8ac9dc2899!8m2!3d44.0727088!4d3.9700965'
	},

	{
		nom: 'Bulgarie',
		titreAdresse: 'Damien et Marie Neige DUBOIS',
		adresse: ['2 rue Vit', 'BG-3400 MONTANA (Bulgarie)'],
		tel: ['00359 8 79 42 73 51'],
		mail: ['jeravitza@abv.bg', 'damiendubs@gmail.com'],
		url: '',
		lienMap:
			'https://www.google.com/maps/place/Montana,+Bulgarie/@43.4061756,23.2304643,14z/data=!4m5!3m4!1s0x40ab34a4bddcc2e9:0x525e81c15e5a65d4!8m2!3d43.4085161!4d23.2257292'
	},

	{
		nom: 'Fouday',
		titreAdresse: 'Mission Timothée',
		adresse: ['62, rue principale', '67130 FOUDAY'],
		tel: ['03 88 33 94 05', '06 49 89 79 73'],
		mail: ['inscriptionfouday@gmail.com'],
		url: '',
		lienMap:
			'https://www.google.com/maps/place/62+Rue+Principale,+67130+Fouday/@48.4260141,7.1860964,17z/data=!3m1!4b1!4m5!3m4!1s0x4793feba9a07cc61:0xb5648f6ec39c2e80!8m2!3d48.4260141!4d7.1882851'
	},
	{
		nom: 'Madagascar',
		titreAdresse: 'Le Cep',
		adresse: ['Lot 9M Talatamaty', 'AMBOHIDRATRIMO 105', 'MADAGASCAR'],
		tel: ['00 261 34 11 654 22'],
		mail: ['mamyvololona@gmail.com'],
		url: '',
		lienMap:
			"https://www.google.com/maps/place/Le+C.E.P+Centre+d'Ecoute+et+de+Partage+Talatamaty/@-18.840258,47.4517487,17z/data=!3m1!4b1!4m5!3m4!1s0x21f081d9e807775f:0x5df936a8c95d3f37!8m2!3d-18.8402631!4d47.4539427"
	},
	{
		nom: 'Riffray',
		titreAdresse: 'Assemblée du Riffray',
		adresse: ['Le Riffray', '35150 CHANTELOUP'],
		tel: ['02 99 47 19 03'],
		mail: ['timotheeriffray@gmail.com '],
		url: '',
		lienMap:
			'https://www.google.com/maps/place/Mission+Timoth%C3%A9e/@47.9526527,-1.5764201,17z/data=!4m13!1m7!3m6!1s0x480f245f0008f641:0x3b48cfe5d256239a!2sLe+Riffray,+35150+Chanteloup!3b1!8m2!3d47.9529416!4d-1.5768684!3m4!1s0x480f245f045229ab:0x27e984b7907902f!8m2!3d47.9526527!4d-1.5764201'
	},
	{
		nom: 'Orphelinat',
		titreAdresse: 'Centre Esaïe 58',
		adresse: [
			'AAEG (Aide aux enfants de Guinée)',
			'A/N° 5677/MATD/CAB/SEPROMA/2017'
		],
		tel: ['+664 64 30 75', '+ 621 58 40 11'],
		mail: ['aideauxenfantsdeguinee@gmail.com', 'djpelissou@gmail.com'],
		url: '',
		lienMap: ''
	},
	{
		nom: 'Timothée Accueil et Jeunesse',
		titreAdresse: 'Timothée Accueil et Jeunesse',
		adresse: ['1654 chemin de Recoulin', '30140 ANDUZE'],
		tel: ['04 66 61 73 61'],
		mail: [''],
		url: '',
		lienMap:
			'https://www.google.com/maps/place/Mission+Timoth%C3%A9e/@44.0727088,3.9700965,15z/data=!4m5!3m4!1s0x0:0x524de8ac9dc2899!8m2!3d44.0727088!4d3.9700965'
	}
];
