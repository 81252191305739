import React from 'react';
import { withTranslation } from 'react-i18next';
import BackgroundSlider from 'react-background-slider';
import SlideTemplate1 from './SlideTemplate1';
import SlideTemplate2 from './SlideTemplate2';
import { tools } from '@mitim/react-mitim';

class Slide extends React.Component {
	// state = { images: '' };

	video() {
		if (
			this.props.slide.slideBackgroud &&
			this.props.slide.slideBackgroud === 'video'
		) {
			return (
				<>
					<figure
						className="figureReactBackgroundSlider d-none d-lg-block"
						style={{
							filter: 'brightness(40%)',
							overflow: 'hidden',
							maxHeight: '500px',
							marginTop: '50px',
							backgroundSize: 'auto',
							backgroundPositionY: '-200px'
						}}
					>
						<video
							autoplay="true"
							loop
							muted
							preload="autobuffer"
							style={{
								marginLeft: 'auto',
								marginRight: 'auto',
								width: '1920px',
								marginTop: '-180px',
								zIndex: '-100'
							}}
							// poster={require(`../../resources/wallpaper/${this.props.slide.image}`)}
							poster={`${tools.lienWallpaperTaj}/${this.props.slide.image}`}
						>
							<source
								src="https://www.dropbox.com/s/ipdhm7cuyhj565u/db03fe69874558c43b5b148d6d8e0d08b2d4223a.mov?raw=1"
								// src="https://www.dropbox.com/s/qyl5x2xbcjos4gv/5e5ddf95c2301b86bb049d527336648ce421fde1.mov?raw=1"
								type="video/mp4"
							/>
							Sorry, your browser doesn't support embedded videos.
						</video>
					</figure>
					<figure
						className="figureReactBackgroundSlider d-block d-lg-none"
						style={{
							// backgroundImage:
							// 	'url(' +
							// 	require(`../../resources/wallpaper/${this.props.slide.image}`) +
							// 	')',
							backgroundImage:
								'url(' +
								`${tools.lienWallpaperTaj}/${this.props.slide.image}` +
								')',
							filter: 'brightness(60%)',
							maxHeight: '500px',
							marginTop: '50px',
							backgroundSize: 'auto',
							backgroundPositionY: '-50px'
						}}
					/>
				</>
			);
		} else {
			return <></>;
		}
	}

	wallpaper() {
		return (
			<figure
				className="figureReactBackgroundSlider"
				style={{
					// backgroundImage:
					// 	'url(' +
					// 	require(`../../resources/wallpaper/${this.props.slide.image}`) +
					// 	')',
					backgroundImage:
						'url(' +
						`${tools.lienWallpaperTaj}/${this.props.slide.image}` +
						')',
					filter: 'brightness(60%)',
					maxHeight: '500px',
					marginTop: '50px',
					backgroundSize: 'auto',
					backgroundPositionY: '-50px'
				}}
			/>
		);
	}

	render() {
		const data = this.props.slide;

		const images = this.props.images;
		const { t } = this.props;

		// const lienImage = `../../resources/wallpaper/${this.props.slide.image}`;

		return (
			// <section className="slider-background showcase-page-header parallax-bg">
			//     {images.length !== 1 ? (
			//         <BackgroundSlider images={images} transition={0.5} duration={5} />
			//     ) : (
			//             <div id="ReactBackgroundSlider">
			//                 <figure
			//                     className="figureReactBackgroundSlider"
			//                     style={{
			//                         backgroundImage: 'url(' + images + ')',
			//                         filter: 'brightness(50%)'
			//                     }}
			//                 />
			//             </div>
			//         )}

			//     <div className="container">
			//         <div className="row">
			//             <div className="col-md-2"></div>
			//             <div className="align-self-center col-12 col-md-8">
			//                 <h1 className="ml-5 mr-5 mt-5 mb-4 text-center mt-4">
			//                     {this.props.titre}
			//                 </h1>
			//                 <h2 className="ml-3">{this.props.sousTitre}</h2>
			//                 <div className="text-center mb-5">
			//                     <div className="text-center pt-5" style={{}}>
			//                         <img
			//                             src={require(`../../resources/icons/logo_TAJ_blanc.svg`)}
			//                             className="img-fluid"
			//                         />
			//                     </div>
			//                 </div>
			//             </div>
			//             <div className="col-md-2"></div>
			//         </div>
			//     </div>
			// </section>

			<section className="slider-background">
				<div id="ReactBackgroundSlider">
					{this.props.slide.template === 1 && this.wallpaper()}
					{this.props.slide.template === 2 && this.wallpaper()}
					{this.props.slide.template === 3 && this.video()}
				</div>

				<div className="container-fluid">
					<div className="row" style={{ marginTop: '50px' }}>
						{this.props.slide.template === 1 && (
							<SlideTemplate1 slide={this.props.slide} />
						)}
						{this.props.slide.template === 2 && (
							<SlideTemplate2 slide={this.props.slide} />
						)}
						{this.props.slide.template === 3 && (
							<SlideTemplate2 slide={this.props.slide} />
						)}
					</div>
				</div>
			</section>
		);
	}
}
export default withTranslation()(Slide);
